//价格表
import {
    Button,
    Layout,
    Menu,
    Divider,
    Dropdown,
    PageHeader,
    Spin,
    Select,
    Col,
    Row,
    Input,
    Tooltip,
    Card,
    Table,
    Pagination,
    Modal,
    Form, DatePicker
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';

import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { getFullRoute } from 'utils/Tools'
import { PAGE_PATH } from 'apis/Router'
import {
    addPricesheet as _addPricesheet,
    copyPricesheet as _copyPricesheet,
    deletePricesheet as _deletePricesheet,
    editPricesheet as _editPricesheet,
    productType as _productType,
    getPricesheet as _getPricesheet,
    getEnterprise as _getEnterprise,
    setDefault as _setDefault,
} from 'reduxModules/store'
import {
    editProjectsPriceId as _editProjectsPriceId,
} from 'reduxModules/customerPhoto'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './pricesheet.module.less'
import Imgs from 'imgs'
import moment from "moment";
import NewPricesheet from "./NewPricesheet";
import AssignToProject from "./AssignToProject";
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
const {ClientProfoto} = Imgs

const Pricesheet = ({name,infoDetails}) => {
    let history = useHistory()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    // 总数
    const [total, setTotal] = useState(0)
    const [bg, setBg] = useState(false)
    const [deleteVisible, setdeleteVisible] = useState(false)
    const [selectedPricesheet, setSelectedPricesheet] = useState({
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [pricesheetList, setPricesheetList] = useState([])
    const [addVisible, setAddVisible] = useState(false)
    const [supplierList, setSupplierList] = useState([
        {id:0,name:'Infotos'},
        {id:1,name:'自有服务'},
    ])
    // const [isAdd, setIsAdd] = useState(false)
    const [PricesheetInfo, setPricesheetInfo] = useState({})
    const [keys, setKeys] = useState(0)
    const [assignToProjectVisible, setAssignToProjectVisible] = useState(false)
    const [checkAssign, setCheckAssign] = useState({pricesheetName:''});

    const [enterpriseId, setEnterpriseId] = useState('');
    //商品类型
    const [productTypes, setProductTypes] = useState([])
    useEffect(()=>{
        setEnterpriseId(sessionStorage.getItem('user')
          ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
          :null)
        // console.log(sessionStorage.getItem('user')
        //   ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        //   :null)
        getListByEnterprise()
        getListProductType()
        getListByPricesheet(selectedPricesheet)
    },[])

    // 获取列表
    const getListProductType = async () => {
        const res = await _productType({
            storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null,
            pageNum:1,
            pageSize:1000,
        })
        if (res.status=='200'&&res.data.code === '000000') {
            sessionStorage.setItem('productTypes',JSON.stringify(res.data.data))
            setProductTypes(res.data.data)
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    const onBaseClick=(current,pageSize)=>{
        setSelectedPricesheet({
            pageNo:current,
            pageSize:selectedPricesheet.pageSize,
            totalCount:20
        })
        getListByPricesheet({pageNo:current,
            pageSize:selectedPricesheet.pageSize,})
    }
    // 获取价格表列表
    const getListByPricesheet = async (data) => {
        setLoading(true)
        const res = await _getPricesheet({
            storeId:sessionStorage.getItem('user')
              ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
              :null,
            pageNum:data.pageNo,
            pageSize:data.pageSize,
        })
        setLoading(false)
        if ( res.data.code === '000000') {
            setPricesheetList(res.data.list)
            setTotal(res.data.data.total)
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    // 获取供应商列表
    const getListByEnterprise = async (data) => {
        // setLoading(true)
        const res = await _getEnterprise({})
        // setLoading(false)
        if (res.status === 200 && res.data.code === '000000') {
            setSupplierList(res.data.data)
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    const handleItem = async (type, item) => {
        setCheckAssign(item)
        if(type === 'assignToProject'){
            setAssignToProjectVisible(true)
        }else if(type === 'duplicate'){
            const res = await _copyPricesheet({
                id:item.id,
                storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null
            })
            if (res.status === 200 && res.data.code === '000000') {
                myMessage.success('复制成功')
                getListByPricesheet(selectedPricesheet)
            } else {
                myMessage.error(res.data.mesg)
            }
        }else if(type === 'edit'){
            setAddVisible(true)
        }else if(type === 'delete'){
            setdeleteVisible(true)
        }else if(type === 'setDefault'){ //设为默认
            const res = await _setDefault({
                id:item.id,
                storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null
            })
            if (res.status === 200 && res.data.code === '000000') {
                myMessage.success('已设为默认价格表')
                getListByPricesheet(selectedPricesheet)
            } else {
                myMessage.error(res.data.mesg)
            }
        }
    }
    const deleteModal= async ()=>{
        const res = await _deletePricesheet({
            id:checkAssign.id,
            storeId:enterpriseId
        })
        if (res.status === 200 && res.data.code === '000000') {
            getListByPricesheet(selectedPricesheet)
            setdeleteVisible(false)
            myMessage.success('删除成功')
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (_, item) =>  {return (
                <div onClick={() => clickRow(item)} className={styles.nameCell}>
                    {item.name}
                    {item.isDefault=='yes'?(
                      <span className={styles.isDefault}>默认</span>
                    ):null}
                </div>
            )},
        },
        {
            title: '所含商品数量',
            dataIndex: 'productNum',
            key: 'productNum',
        },
        {
            title: '服务提供商',
            dataIndex: 'supplierEnterpriseName',
            key: 'supplierEnterpriseName',
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div className={styles.menuBtn}>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        <Menu.Item><a key='1' onClick={() => handleItem('assignToProject', item)}>应用到项目...</a></Menu.Item>
                                        {item.isDefault=='no'&&
                                        <Menu.Item><a key='5' onClick={() => handleItem('setDefault', item)}>设为默认</a></Menu.Item>}
                                        <Menu.Divider />
                                        <Menu.Item><a key='2' onClick={() => handleItem('duplicate', item)}>复制</a></Menu.Item>
                                        <Menu.Item><a key='3' onClick={() => handleItem('edit', item)}>编辑</a></Menu.Item>
                                        <Menu.Item><a key='4' onClick={() => handleItem('delete', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    const clickRow=(row)=>{
        sessionStorage.setItem('pricesheet',JSON.stringify(row))
        history.push(`/store/products/${row.id}`)
    }
    const onAddPricesheet= async (values)=>{
        values.storeId=enterpriseId
        let res
        if(!checkAssign.id){
            res = await _addPricesheet(values)
        }else{
            checkAssign.name=values.name
            checkAssign.storeId=enterpriseId
            checkAssign.supplier=checkAssign.supplierEnterpriseName
            checkAssign.supplierId=checkAssign.supplierEnterpriseId
            res = await _editPricesheet(checkAssign)
        }
        if (res.status === 200 && res.data.code === '000000') {
            getListByPricesheet(selectedPricesheet)
            setAddVisible(false)
            if(!checkAssign.id) {
                myMessage.success('添加成功')
            }else{
                myMessage.success('修改成功')
            }
        } else {
            myMessage.error(res.data.msg)
        }
    }
    //应用到项目（多个）
    const addForproduct = async (values) => {
        let arr=[]
        values.map(v=>{
            arr.push({
                projectId:v,
                priceId: checkAssign.id
            })
        })
        const res = await _editProjectsPriceId(arr)
        if (res.status === 200 &&res.data.code === 0) {
            myMessage.success('应用成功')
            getListByPricesheet(selectedPricesheet)
            setAssignToProjectVisible(false)
        } else {
            myMessage.error(res.data.mesg)
        }
    }
    // 添加价格表权限
    const isAddProduct=()=>{
        setCheckAssign({pricesheetName: ''})
        setAddVisible(true)
    }
    return (
        <Layout>
            <PageHeader
                ghost={false}
                className={styles.titleBar}
                title={
                    <div id="priceList">
                        商品价格表
                    </div>
                }
                extra={[
                    <Button type="primary" onClick={()=>{
                        isAddProduct()
                    }}>添加价格表</Button>
                ]}
            ></PageHeader>
            <Card bordered={false} className={styles.pricesheetList}>
                <Spin tip="数据加载中" spinning={loading}>
                    {/*{pricesheetList.length > 0||selectedPricesheet.sourceEnum!=''||selectedPricesheet.searchValue!=''?(*/}
                    {pricesheetList.length > 0 && !loading?(
                        <Row className={styles.controlBar}>
                            <Col span={24} className={styles.toptexts}>
                                <span className={styles.ptCount}>{total}条价格表</span>
                                {/*<Divider type="vertical"/>*/}
                                {/* <span style={{color:'#4a4a4a',fontSize:'12px',marginLeft:'10px'}}>说明：定价单是用来管理设置输出商品的选项，您可将不同的价格应用在不同的销售场景中。</span> */}
                            </Col>
                        </Row>):null
                    }
                    {pricesheetList.length > 0 && (
                        <Table
                            // className={styles.tablesbody}
                            style={{marginBottom: '30px'}}
                            dataSource={pricesheetList}
                            columns={columns}
                            pagination={{
                                simple:false,
                                current:selectedPricesheet.pageNo,
                                total:total,
                                hideOnSinglePage: true,
                                howSizeChanger: false,
                                position:["bottomCenter"],
                                pageSize:20,
                                onChange:(current,pageSize)=>{
                                    onBaseClick(current,pageSize)
                                }
                            }}
                        />
                    )}
                    {pricesheetList.length <= 0 && !loading && (
                        <div>
                            <InfoEmptyData description="暂未创建商品价格表" />
                            <span className={styles.remarkTip}>价格表是用来管理设置商品的选项，您可将不同的价格应用在不同的销售场景中。</span>
                        </div>
                    )}
                </Spin>
            </Card>
            {addVisible &&
            <NewPricesheet
              visible={addVisible}
              onCreate={onAddPricesheet}
              onCancel={() => {
                  setAddVisible(false)
              }}
              supplier={supplierList}
              checkAssign={checkAssign}
            />
            }
            {assignToProjectVisible &&
            <AssignToProject
              visible={assignToProjectVisible}
              onCreate={addForproduct}
              onCancel={() => {
                  setAssignToProjectVisible(false)
              }}
              checkAssign={checkAssign}
              pricesheetList={pricesheetList}
            />
            }
            <Modal
              title="删除价格表"
              visible={deleteVisible}
              onOk={deleteModal}
              onCancel={()=>setdeleteVisible(false)}
              okText="确认"
              cancelText="取消"
              width={600}
              closable={false}
              centered
              maskClosable={false}
              cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">该价格表相关的绑定将会自动解除，且数据不可恢复，确定删除？</div>
            </Modal>
        </Layout>
    )
}

export default Pricesheet
