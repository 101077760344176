import {Button, Dropdown, Menu, Modal, Spin, Table, Tooltip, Divider, Pagination} from 'antd'
import commonComponent from 'components/index'
import {
  getMyStorage as _getList,
  addCart as _addCart,
  removeMyStorage as _remove,
} from 'reduxModules/order'
import { getRecord as _getRecord } from 'reduxModules/member'
import React, { forwardRef, useState, useEffect } from 'react'
import styles from "./LoginHistory.module.less";
import { getTimeMin } from 'utils/Tools'
const { MyMessage, InfoEmptyData, ImageCardCell } = commonComponent
const LoginHistory = (props, ref) => {
  const [projectData, setProjectData] = useState({ list: [
      {time:'2021/07/0816:50',user:'18602819355',type:'通过网页登录成功',addess:'四川成都青羊区', environment:'计算机系统及版本号，登录所用浏览器及版本号，Macintosh; IntelMac OSX10_15_7，Chrome/104.0.0.0 Safari/537.361'}
    ], pagination: {pageNo:1,pageSize:20,totalCount:0} })
  const [adding, setAdding] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [checkItem, setCheckItem] = useState(null)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [shareVisible, setShareVisible] = useState(false)
  // 是否加载中
  const [loading, setLoading] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState({
    pageNo:1,
    pageSize:20
  })
  const getList = async () => {
    let res = null
    await setLoading(true)
    res = await _getRecord({
      pageNo: selectedOrder.pageNo,
      pageSize: selectedOrder.pageSize,
    })
    await setLoading(false)
    console.log(res,selectedOrder)
    if (res.status==200&&res.data.code === 0) {
      console.log(res.data.data)
      const newPhotosData = {
        pagination: {
          pageNo: Number(res.data.data.pageNo),
          pageSize: Number(res.data.data.pageSize),
          totalCount: Number(res.data.data.totalCount),
        },
        list:res.data.data.list,
      }
      setProjectData(newPhotosData)
    } else {
      MyMessage.error(res.msg)
    }
  }
  useEffect(() => {
    getList({})
  }, [])
  const handleTableChange = (pagination) => {
    // console.log(pagination)
    this.getList({ pagination })
  }
  const onDelete = async () => {
    const { getList, onRemove, pagination } = this.props
    try {
      const resData = await onRemove(this.state.checkItem.id)

      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 派发获取列表数据
        getList({ pagination })
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {}
  }
  const handleItem = async (type, item) => {
    setCheckItem(item)
    if(type === '分享'){
      setShareVisible(true)
    }else if(type === '编辑'){

    }else if(type === '删除'){
      // myMessage.success('修改成功')
      // getListByClient(selectedClient)
      setDeleteVisible(true)
    }
  }
  const onChange = (page, pageSize) => {
    setSelectedOrder({
      pageNo:page,
      pageSize:pageSize,
    })
    getList()
  }
  useEffect(() => {
    getList()
  }, [selectedOrder])
  const onPreview=()=>{}
  const onEdit=()=>{}
  return (
    <Spin tip="数据加载中" spinning={loading}>
      {projectData.list.length > 0 && (
        <div className={styles.myProject}>
          <div className={styles.controlBar}>
            {projectData.pagination.totalCount}条
            <Divider type="vertical"/>
            <span>历史记录仅展示近1个月内的记录，一个自然日内相同IP视为一条记录。</span>
          </div>
          <div>
            {projectData.list.map(v=>{
              return(
                <div className={styles.record}>
                  <div className={styles.recordAcc}>{getTimeMin(v.createTime)} 账号：{v.account}通过{v.type=='Webpage'?'网页':'小程序'}登录成功</div>
                  <div className="iconfont iconico_remind"><span className={styles.recordIp}>{v.ipAddress}{v.address}</span></div>
                  <div className={styles.recordEnv}>使用环境：{v.described}</div>
                </div>
              )
            })}
          </div>
          <Pagination
            className={styles.pagerPositon}
            current={projectData.pagination.pageNo}
            total={projectData.pagination.totalCount}
            pageSize={projectData.pagination.pageSize}
            onChange={onChange}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        </div>
      )}
      {projectData.list.length <= 0 && !loading && (
        <InfoEmptyData description="暂无登录记录" />
      )}
    </Spin>
  )
}

export default forwardRef(LoginHistory)
