import React from 'react'
/**
 * Tools是提供全局公用的方法的类
 */
import {
  flow,
  isArray,
  isNil,
  isNumber,
  map,
  pick,
  sum,
  values,
} from 'lodash/fp'
import moment from 'moment'
import { getImageNameInUrl } from 'utils/Uploader'

/* -------------------------------------*/
/**
 *
 * @param {*} url - 匹配的url
 * @param {*} options - 替换对象数组
 *
 * @example
 * getFullRout('path/:id/:type', {id:1, type: 'show'})
 *
 * out -> 'path/1/show'
 */

export const getFullRoute = (url = '', options) => {
  let fullURL = url
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      fullURL = fullURL.replace(`:${key}`, options[key])
    }
  }
  // 没匹配到的清除处理
  fullURL = fullURL.replace(/\/:\S+/g, '')

  return fullURL
}

/**
 * @param {*} key - 选项中的key值
 * @param {*} feild - 要读取的变量
 * @param {*} option - 选项
 * @param {string} emptyText - 匹配不到时展示
 *
 * @return string - 名称
 *
 * @example:
 *  options = [{key: '1', label: '绿色', color: 'green'}{key: '2', label: '黄色', color: 'yellow'}]
 *  getOption(1, 'color',options)
 *  out -> green
 *  getOption(0, 'color',options, 'no-color')
 *  out -> no-color
 */
export function getOption(key, feild, option, emptyText = '') {
  if (key !== null && key !== undefined) {
    const matched = option.filter((item) => item.key === key || item.id === key)
    return matched[0] ? matched[0][feild] : emptyText
  }
  return ''
}

/**
 * @param {*} key - 选项中的key值
 * @param {*} feild - 要读取的变量
 * @param {*} option - 选项
 * @param {string} emptyText - 匹配不到时展示
 *
 * @return string - 名称
 *
 * @example:
 *  options = [{key: '1', label: '绿色', color: 'green'}{key: '2', label: '黄色', color: 'yellow'}]
 *  getOption(1, 'color',options)
 *  out -> green
 *  getOption(0, 'color',options, 'no-color')
 *  out -> no-color
 */
export function getOptionBykeyName(
  keyName,
  key,
  feild,
  option,
  emptyText = ''
) {
  if (key !== null && key !== undefined) {
    const matched = option.filter((item) => item[keyName] === key)
    return matched[0] ? matched[0][feild] : emptyText
  }
  return ''
}

/**
 *
 * @param {*} keysValue - 匹配键值
 * @param {*} keyName - 匹配键名
 * @param {*} valueName - 获取值名
 * @param {*} option  - 选项
 *
 * @return {sting} array - 获取值数组
 *
 * @example:
 *  options = [{name: 'apple', id: '1', color: 'green'},{name: 'banana', id: '2', color: 'yellow'}]
 *  getValuesByKeys('1,2', 'id', 'name', options)
 *  out -> ['apple', 'banana']
 */
export function getValuesByKeys(keysValue, keyName, valueName, option) {
  const keys = keysValue.split(',')
  let lables = []
  for (var i in option) {
    if (keys.includes(option[i][keyName] + '')) {
      lables.push(option[i][valueName])
    }
  }
  return lables
}

/**
 *
 * @param {*} object
 * @param {*} name
 *
 *
 * @example:
 *  object = {name: 'apple', id: '1'}
 *  initFormFeildValue(object, 'name')
 *  out -> apple
 */
export const initFormFeildValue = (object, name) => {
  return object && !isNil(object[name]) ? object[name] : undefined
}

export const initFormFeildSelectValue = (object, name) => {
  return object && !isNil(object[name])
    ? isNumber(object[name])
      ? object[name]
      : object[name] + ''
    : undefined
}

export const initFormFeildDateValue = (object, name) => {
  return object && !isNil(object[name]) ? moment(object[name]) : undefined
}

export const initFormFeildCascaderLevel3Value = (object, names = []) => {
  return object && isArray(names) && object[names[0]]
    ? flow(pick(names), values)(object)
    : undefined
}

export const dateFormat = (value) => {
  return value.format('YYYY-MM-DD')
}

/**
 *
 * @param {Array[object]} dataSource
 * @param {Array[number]} indexs
 *
 * @example:
 * dataSource = [{name: 'apple'}, {name: 'banana'},{name: 'orange'}]
 * index = [1,2]
 * getDataByIndexs(dataSource, index)
 * out-> = [{name: 'banana'},{name: 'orange'}]
 */
export const getDataByIndexs = (dataSource, indexs) => {
  return dataSource.filter((_, index) => indexs.includes(index + 1))
}

/**
 *
 * @param {Array[object]} dataSource
 * @param {Array[number]} indexs
 *
 * @example:
 * dataSource = [
  { key: 1, label: 'apple' },
  { key: 2, label: 'banana' },
  { key: 3, label: 'orange' },
]
 * indexs = [3,1,2]
 * getDataBySortIndexs(dataSource, indexs)
 * out-> = [{ key: 3, label: 'orange' },
 *          { key: 1, label: 'apple' },
 *          { key: 2, label: 'banana' }]
 */
export const getDataBySortIndexs = (dataSource, indexs) => {
  if (indexs) {
    const newArray = indexs.map((key) => {
      let findIndex = 0
      dataSource.forEach((item, index) => {
        if (item.key === key) {
          findIndex = index
        }
      })
      return { ...dataSource[findIndex] }
    })

    return newArray
  } else return []
}

/**
 *
 * @param {Array[object]} objectArray
 * @param {String} key
 *
 * @example:
 * dataSource = [{id:'1',name: 'banana'},{id: '2',name: 'orange'}]
 * keys = 'id'
 * getDataValueByKey(dataSource, keys)
 * out-> = [1,2]
 */
export const getDataValueByKey = (objectArray, key) => {
  return objectArray.map((item) => item[key])
}

/**
 *
 * @param {*} url 浏览器路径url
 * 例如 /staff-management/staff/list?shopId=1 => { shopId: '1' }
 */
export const getQueryVariableObj = (url) => {
  const index = url.indexOf('?')
  const query = url.slice(index + 1)
  let VariableObj = {}
  if (query.length) {
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      VariableObj[pair[0]] = pair[1]
    }
  }
  return VariableObj
}
/**
 * @param {数字数组} numberArray 默认 []
 * @param {相加结果保留小数位} precision 默认 0
 * getNumberArraySum([1,2,3],2) => 6.00
 */
export const getNumberArraySum = (numberArray = [], precision = 0) =>
  flow(
    map((item) => (item ? Number(item) : 0)),
    sum,
    (i) => i.toFixed(precision)
  )(numberArray)

export const fileSizeBToM = (numberKB) => (numberKB / 1024 / 1024).toFixed(2)

/**
 *
 * @param {时间长度} l
 * @returns 随机字符
 */
export const randomChar = (l)  =>{
  if(l===undefined){
    l = 10
  }
  var  x="0123456789qwertyuioplkjhgfdsazxcvbnm";
  var  tmp="";
  var timestamp = new Date().getTime();
  for(var i=0;i<  l; i++)  {
     tmp  += x.charAt(Math.ceil(Math.random()*100000000)%x.length);
  }
  return  timestamp+tmp
}

/**
 * 生成uuid 并去掉“-”
 * @returns uuid
 */
export const uuid = () =>{
  var temp_url = URL.createObjectURL(new Blob())
  var uuid = temp_url.toString() // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
  URL.revokeObjectURL(temp_url)
  var value = uuid.substr(uuid.lastIndexOf("/") + 1)
  return value.replace(new RegExp( '-' , "g" ),"")
}
//时间格式转换
export const getTime=(dat)=>{
  var date = new Date(dat);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD +" "+hh + mm + ss;
}
//时间格式转换 年月日时分
export const getTimeMin=(dat)=>{
  var date = new Date(dat);
  var YY = date.getFullYear() + '/';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
  return YY + MM + DD +" "+hh + mm;
}
//时间格式转换 年月日
export const getTimeDate=(dat)=>{
  var date = new Date(dat);
  var YY = date.getFullYear() + '/';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  return YY + MM + DD
}
//切割时间
export function forsplitTime(time){
  let t=time.split('.')[0].replace(/-/g,'/').split('T')
  return t.join(' ')
}
