// 订单结算
import {Form, Modal, InputNumber, Input, Space, Row, Col,Popconfirm} from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import Imgs from 'imgs'
import AddressModal from '../AddressModal/index'
import styles from './index.module.less'
import {EmailValidateRule, PhoneRule} from 'utils/ValidateRulesAndMsg'
import {TrimInput, TrimTextArea} from 'components/WithTrim/index'
import {
  CheckOutlined
} from '@ant-design/icons';
import {
  invalidInformation as _invalidInformation,
} from 'reduxModules/order'
import { getEnterpriseInfo as _getInfo } from 'reduxModules/enterprise'
import { _editEnterpriseInfo, _getEnterpriseInfo } from 'servers/enterprise'
import {
  addOrderAdd as _addOrderAdd,
  payOrder as _payOrder,
  addAddress as _addAddress,
  delelteAddress as _delelteAddress,
  editAddress as _editAddress,
  getAddress as _getAddress,
} from 'reduxModules/store'
import {
  cancelShoppingCar as _cancelShoppingCar,
} from 'reduxModules/order'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import ProvinceSelectCascader from "../ProvinceSelectCascader";
import myMessage from "../Message";
import addressdata from "../ProvinceSelectCascader/data";

const {payAlipay,payPaypal,payWechat,imgCoupon} = Imgs
const CheckoutModal = ({ data, visible, onCreate, onCancel, payOrders, setPayCode }) => {
  const [form] = Form.useForm()
  const [pay, setCheckPayWay] = useState({name:'微信支付'})
  const [loading, setLoading] = useState(false)
  // const [checkIds, setCheckIds] = useState([])
  // const [allPrice, setAllPrice] = useState(0)
  const [canEdit, setCanEdit] = useState('')
  const [checkAddress, setCheckAddress] = useState(null)
  const [checkCoupon, setCheckCoupon] = useState(null)
  const [address, setAddress] = useState([
    // {id:0,name:'高元',address:'四川成都市青羊区城区蜀鑫东路88号金沙海棠4-3-1501',phone:'18602819355'},
    // {id:0,name:'张三',address:'四川成都市青羊区城区蜀鑫东路889号-3-1501',phone:'18602819355'},
  ])
  const [payWay, setPayWay] = useState([
    {name:'微信支付',img:''},
    // {name:'支付宝支付',img:''},
    // {name:'PayPal支付',img:''},
  ])
  const [order, setOrder] = useState([
    // {name:'50×100厘米输出作品',des:'项目名称，5x7”，乌木色',img:'',price:'269.00',number:1},
    // {name:'100×100厘米输出作品',des:'项目名称',img:'',price:'269.00',number:1},
  ])
  const [price, setPrice] = useState(0)//总价
  const [freight, setFreight] = useState(0)//运费
  const [couponPrice, setCheckCouponPrice] = useState(0)//优惠
  const [coupon, setCoupon] = useState([
    {name:'首单专享60元优惠券',img:'',des:'2021.05.01-2021.06.01 下单满500元享'},
  ])
  const [hascoupon, setHascoupon] = useState(0) //可用优惠券
  const [discountCode, setCode] = useState('')
  const [addressVisible, setAddressVisible] = useState(false)
  const [isAddress, setIsAddress] = useState(false)
  const [thatAddress, setThatAddress] = useState({})
  const [transport, setTransport] = useState('') //运输时间
  const [forpay, setForpay] = useState(false) //运输时间
  const [user, setUser] = useState({}) //当前账号信息
  const [level, setLevel] = useState({}) //当前账号权限
  const getLevel=async ()=>{
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      setLevel(res.code)
    }
  }
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  // 选项价格设置
  // const setInputValue = (val, e, i, t) => {
  //   let arr = JSON.parse(JSON.stringify(list))
  //   arr[i][t] = Number(e)
  //   setList(arr)
  // }
  // const priceBlur = (val, e, i, t) => {
  //   let arr = JSON.parse(JSON.stringify(list))
  //   let reg = /(^[1-9][0-9]*$)/
  //   if (reg.test(e)) {
  //     arr[i][t] = Number(e)
  //   } else {
  //     arr[i][t] = 1
  //     // myMessage.warning('请输入正确的数量')
  //   }
  //   setList(arr)
  // }
  // 单选
  // const checkList=(id)=>{
  //   let oldIds=JSON.parse(JSON.stringify(checkIds))
  //   checkIds.includes(id)?oldIds.splice(oldIds.indexOf(id),1):oldIds.push(id)
  //   setCheckIds(oldIds)
  // }
  // 全选
  // const checkAll=()=>{
  //   setCheckIds(list.length!==checkIds.length?list.map(v=>v.id):[])
  // }

  // useEffect(() => {
  //   setPrice()
  // }, [checkIds,list])
  //设置总价
  // const setPrice=()=>{
  //   let price=0
  //   list.map(v=>{
  //     if(checkIds.includes(v.id)){
  //       price+=Number(v.number)*Number(v.price)
  //     }
  //   })
  //   setAllPrice(price)
  // }
  const checkAddressId=(id)=>{
    setCheckAddress(id)
  }
  const checkCouponId=(id)=>{
    setCheckCoupon(id)
  }
  const getUser=async()=>{
    const res = await _getInfo()
    if ([0].includes(res.code)) {
      const {
        data: { formattedData },
      } = res
      let address=[{
        id:0,
        name:formattedData.name,
        phone:formattedData.contactNumber,
        address:formattedData.areaString,
      }]
      setUser(formattedData)
    }
  }
  //获取地址
  const getAddress=async(ispay)=>{
    const res = await _getAddress({storeId:JSON.parse(sessionStorage.getItem('user')).userId})
    if ('000000'==res.data.code) {
      if(res.data.data.length>0) { //设置默认选中地址
        let defaultFlag = res.data.data.find(v => v.defaultFlag == 'yes')?.id
        let checkId = defaultFlag?defaultFlag:res.data.data[0].id
        setCheckAddress(checkId)
      }
      setAddress(res.data.data)
      if(ispay){ //如果是没有地址直接添加购买
        submitOrder(res.data.data[0].id)
      }
    }
  }
  useEffect(() => {
    let freights=0,prices=0
    if(payOrders?.length>0) {
      payOrders.map(v => {
        if (!v?.productSpecificationId) {
          v.productSpecificationId = v.specificationId
          delete v.specificationId
        }
        if (v.addPageNum > 0) { //当加页存在时添加加页价格
          prices += (v.salesPrice + v.pageSalesPrice) * v.number
        } else {
          prices += v.salesPrice * v.number
        }
        freights += v.extraFreight
        v.optionName = v.optionName.replace('-', ',')
      })
      setOrder(payOrders)
      setFreight(freights)
      setPrice(prices)
    }
    getAddress()
    getUser()
    getLevel()
  }, [payOrders])
  const onAddProject=()=>{}
  const checkPayWay=(id)=>{
    setCheckPayWay(id)
  }
  const onAddress=async(values,ispay)=>{
    let data={
      province:values.areaArray[0],//省
      city:values.areaArray[1],//市
      region:values.areaArray[2],//地区
      address:values.customerAddress,// 收货地址
      storeId:JSON.parse(sessionStorage.getItem('user')).userId,//商家id
      customerName:values.customerName,//用户名称
      defaultFlag:'no',//默认地址
      district:values.district,//省市区
      email:values.customerEmail,//Email
      nationality:'中国',//国籍
      phoneNum:values.customerPhone,//手机号
    }
    if(!isAddress&&!ispay){data.id=thatAddress.id}
    const res = isAddress||ispay?await _addAddress(data):await _editAddress(data)
    // console.log(res)
    if ('000000'==res.data.code) {
      setAddressVisible(false)
      getAddress(ispay)
    }else{
      myMessage.warning(res.data.mesg);
    }
  }
  const editAddress=async(v)=>{
    setThatAddress(v)
    setIsAddress(false)
    setAddressVisible(true)
  }
  const deleteAddress=async(v)=>{
    const res = await _delelteAddress({id:v.id})
    if ('000000'==res.data.code) {
      getAddress()
    }else{
      myMessage.warning(res.msg);
    }
  }
  const toAddress=()=>{
    setThatAddress({})
    setAddressVisible(true)
    setIsAddress(true)
  }
  const submitOrder = async (addressId) =>{
    //判定是否有收货地址
    if(address.length==0&&!addressId){
      setIsAddress(true)
      setForpay(true)
      form.validateFields()
        .then((values) => {
          if (!values.customerPhone) {
            return
          }
          if(!values.areaArray||values.areaArray.length==0){
            myMessage.warning('必须选择地址')
            return
          }
          const provinceGeoId = values.areaArray[0]
          const cityGeoId = values.areaArray[1]
          const AreaGeoId = values.areaArray[2]
          const provinceData =
            addressdata.find((item) => [provinceGeoId].includes(item.value)) || {}
          // 设置市数据
          const CityOptions = provinceData.children
          const cityData =
            CityOptions.find((item) => [cityGeoId].includes(item.value)) || {}
          // 设置 区 options
          const AreaOptions = cityData.children
          const AreaData=AreaOptions.find((item) => [AreaGeoId].includes(item.value)) || {}
          values.district=provinceData.label+cityData.label+AreaData.label
          form.resetFields()
          onAddress(values,true)
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
      return
    }
    // 不存在收货地址时跳转添加地址页
    if(!checkAddress&&!addressId){
      myMessage.warning('请设置或选择收货信息!')
      return
    }
    let data = {
      // addressId: address[0].address, //收货地址id
      addressId: !!checkAddress?checkAddress:addressId, //收货地址id
      currencyType:'RMB', //货币类型*
      // customerId: Number(JSON.parse(sessionStorage.getItem('user')).userId), //客户id*
      customerName: user.name+'，'+user.contactNumber, //客户名称*
      freight:freight, //运费
      // orderType:wx.getStorageSync("pricesheet").typeId=='4'?'dwnOrder':'printOrder',
      // orderType:payOrders[0].orderType,
      orderType:'printOrder', //订单类型*
      productForms:order, //产品信息 表单*
      productPrice:price, //商品总价
      discountCode:checkCoupon?.discountCode, //优惠券编码
      reliefPrice:couponPrice, //优惠价格
      // reliefPrice:this.data.promotionCode.discountType=='sunMoney'?this.data.promotionCode.discountTypeContent:
      //   this.data.promotionCode.discountType=='percentage'?(this.data.price*this.data.promotionCode.discountTypeContent)/100:
      //     this.data.promotionCode.discountType=='freeMail'?this.data.freight:0, //优惠价格
      sourceItem:'1', //来源项目，1表示B端Web
      remarks:canEdit,//订单备注
      // storeId:Number(order[0].enterpriseId), //商家id
      storeId:sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null, //商家id
      // supplierId:this.data.orderList[0].supplierEnterpriseId?this.data.orderList[0].supplierEnterpriseId:wx.getStorageSync("pricesheet").supplierEnterpriseId,
      totalMoney:(price+freight-couponPrice)>0?price+freight-couponPrice:0, //合计支付*
      customerPhone:user.phone, //客户手机号
      stoLev:level.name=='免费版'?1:0,//商家是否免费版【0-否，1-是】（商家下单使用）
    }
    const res = await _addOrderAdd(data)
    if (res.status === 200 && res.data.code === '000000') {
      localStorage.setItem('myOrder', JSON.stringify(res.data.data))
      payOrder(res.data.data)
    }else{
      myMessage.warning(res.data.data.mesg);
    }
  }

  const payOrder = async (v) => {
    let openid = {
      //   openid: wx.getStorageSync('openId')
      // openid: ''
    }
    let data = {
      // appid:'wx0e2aefa2f0045f55',
      appid: 'wx0e8580e0e3dcd330',
      // attach: 'myorders',
      orderId: v.id,
      payer: openid,
      tradeType: 'NATIVE',
    }

    let res=await _payOrder(data)
    if (res.status === 200 && res.data.code === '000000') {
      onCancel()//关闭弹框
      setPayCode(res.data.data.payInfo,v)//支付弹框
      //提交订单删除购物车
      console.log(order[0])
      // if(!order[0].freightPrice){
        let ids=[]
        order.map(v=>{
          ids.push(v.id)
        })
        let data={
          ids:ids
        }
        _cancelShoppingCar(data)
      // }
      //购买后失效编辑中
      order.map(v=>{
        let data = {
          id:v.exportId
        }
        _invalidInformation(data)
      })
    }else{
      myMessage.warning(res.msg);
    }
  }
  return (
    <Modal
      visible={visible}
      title="订单结算"
      okText="保存"
      cancelText="取消"
      footer={null}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={980}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      className={styles.checkoutDialog}
    >
      <div className={styles.checkout}>
        <div className={styles.left}>
          <div className={styles.address}>
            {address.length>0&&(<div className={styles.title}>收货人</div>)}
            <div className={styles.addressList}>
              {address.length>0?address.map(v=>{
                return (
                  <div className={`${styles.people} ${checkAddress===v.id?styles.green:''}`}>
                    <div className={styles.ress}>
                      <div className={styles.ress}>{v.customerName}，{v.phoneNum}</div>
                      <div className={styles.ress}>{v.district}{v.address}</div>
                    </div>
                    <div className={styles.updateRess}>
                      <span onClick={()=>editAddress(v)}>更新</span>
                      <span className={styles.splitLine}>|</span>
                      <Popconfirm
                        title="确定移除该地址"
                        onConfirm={() => deleteAddress(v)}
                        okText="确定"
                        cancelText="取消"
                      >
                        <span>删除</span>
                      </Popconfirm>
                    </div>
                    <div onClick={()=>checkAddressId(v.id)} className={`${styles.check} ${checkAddress===v.id?styles.checked:''}`}>
                      {checkAddress===v.id&&(<CheckOutlined />)}
                    </div>
                  </div>
                )
              }):(
                <div>
                  <Form
                    form={form}
                    layout="vertical"
                    name="add_project_form"
                    requiredMark={false}
                    className={styles.createAddressDialog}
                    destroyOnClose={true}
                  >
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="customerName"
                          label="收货人"
                          rules={[
                            {
                              required: true,
                              message: '请输入',
                            },
                          ]}
                        >
                          <Input placeholder="请输入" maxLength={30}/>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="customerPhone"
                          label="手机号"
                          rules={[{
                            required: true,
                            message: '请输入',
                          },PhoneRule]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="customerEmail"
                          label="Email"
                          rules={[EmailValidateRule]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name=""
                          label="收货地址"
                        >
                          <Form.Item
                            label=""
                            name="areaArray"
                            className={styles.Inf_ant_form_item}
                          >
                            <ProvinceSelectCascader width100={true}/>
                          </Form.Item>
                          <Form.Item
                            style={{display: 'inline-block', marginBottom: -10}}
                            label=""
                            name="customerAddress"
                            // rules={[RequiredInputRule]}
                          >
                            <TrimInput style={{width: '520px'}} placeholder="收货详细地址"/>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </div>
            {/*{address.length>0&&(<div className={styles.addAddress} onClick={toAddress}>新增地址</div>)}*/}
            <div className={styles.addAddress} onClick={toAddress}>新增地址</div>
          </div>
          <div className={styles.payWay}>
            <div className={styles.title}>支付方式</div>
            <div className={styles.payList}>
              {payWay.map(v=>{
                return(
                  <div className={`${styles.pays} ${pay.name==v.name?styles.checkPay:''}`} onClick={()=>checkPayWay(v)}>
                    <img src={payWechat}/>
                    <div>{v.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* <div className={styles.time}>
            <div className={styles.title}>制作时间</div>
            <div className={styles.send}>现在下单大约于6月20日左右发出快递。</div>
          </div> */}
          <div className={styles.remark}>
            <div className={styles.title}>订单备注</div>
            <TrimTextArea
              autoSize
              maxLength={200}
              onBlur={(e) => setCanEdit(e.target.value)}
              placeholder="选填订单备注信息"
              style={{marginBottom: 20}}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.order}>
            <div className={styles.title}>订单信息</div>
            <div className={styles.orderList}>
              {order.map(v=>{
                return(
                  <div className={styles.orders}>
                    <div className={styles.img}><img src={v.imageUrl} alt=""/></div>
                    <div className={styles.info}>
                      <div className={styles.title}>{v.productName}</div>
                      <div className={styles.price}>{v.optionName}</div>
                      <div className={styles.number}>×{v.number}</div>
                    </div>
                    <div className={styles.orderPrice}>¥{Number(v.number)*Number(v.price)}</div>
                  </div>
                )
              })}
            </div>
          </div>
          {/*<div className={styles.coupon}>
            <div className={styles.title}>优惠券</div>
            <div className={styles.convertibility}>
                <Input placeholder="输入兑换码" />
                <div>兑换</div>
            </div>
            <div className={styles.couponlist}>
              {coupon.map(v=>{
                return(
                  <div className={styles.coupons}>
                    <div className={styles.img}><img src={imgCoupon}/></div>
                    <div className={styles.info}>
                      <div className={styles.title}>{v.name}</div>
                      <div className={styles.des}>{v.des}</div>
                    </div>
                    <div onClick={()=>checkCouponId(v.id)} className={`${styles.check} ${checkCoupon===v.id?styles.checked:''}`}>
                    {checkCoupon===v.id&&(<CheckOutlined />)}
                  </div>
                  </div>
                )
              })}
            </div>
          </div>*/}
          <div className={styles.bottomPrice}>
            <div className={styles.total}>
              <div className={styles}>合计</div>
              <div className={styles.price}>¥{price}</div>
            </div>
            <div className={styles.freight}>
              <div className={styles.text}>运费</div>
              <div className={styles.price}>¥{freight}</div>
            </div>
            {/*<div className={styles.discounts}>
              <div className={styles.text}>优惠</div>
              <div className={styles.price}>-¥{couponPrice}</div>
            </div>*/}
            <div className={styles.payment}>
              <div className={styles.text}>合计支付</div>
              <div className={styles.price}>¥{(price+freight-couponPrice)>0?price+freight-couponPrice:0}</div>
            </div>
          </div>
          <div className={styles.newfooter}>
            <div className={styles.submit} onClick={()=>submitOrder()}>提交订单</div>
          </div>
          {/*<div style={{textAlign: 'right', paddingTop: 10, fontSize: 12}}>现在下单大约将于6月20日左右发出</div>*/}
        </div>
        <AddressModal
          visible={addressVisible}
          onCreate={onAddress}
          onCancel={() => {
            setAddressVisible(false)
          }}
          clientInfo={thatAddress}
        />
      </div>
    </Modal>
  )
}


export default CheckoutModal
