import { Button, Divider, Form, Col, Row, Slider, Switch, Input } from 'antd'
import CoverUpload from 'components/CoverUpload'
import React, { useState } from 'react'
import { WebLayout } from 'constants/Options'
import styles from './SEOForm.module.less'
import {Link} from "react-router-dom";
import { PAGE_PATH } from 'apis/Router'
const { TextArea } = Input
const layout = {
  wrapperCol: { span: 16 },
}
const MarkstaForm = ({ formRef, webData,infoDetails }) => {
  const [form] = Form.useForm()
  const [initialValues] = useState(webData)

  return (
    <div className={styles.formContrl}>
      {/* 是否显示网站功能 */}
      {infoDetails.brand=='N'&&(
        <div>
          <div style={{fontSize: '14px',color: '#4A4A4A',fontWeight:"bold"}}>功能受限</div>
          <div style={{margin:'10px 0 30px'}}>您当前账户类型不支持该功能，若需使用请升级到更高版本。</div>
          <Button
            key='ok'
            type="primary">
            <Link
              to={PAGE_PATH.settings.children.subscribe}
            >
              升级订阅
            </Link>
          </Button>
        </div>
      )}
      {infoDetails.seo=='Y'&&(
      <Form
        {...layout}
        form={form}
        ref={formRef}
        layout="vertical"
        name="SEOForm"
        initialValues={initialValues}
      >
        <Form.Item label="网站标题" name="websiteTitle">
          <Input />
        </Form.Item>
        <Form.Item label="页面底部文字" name="websiteFooter">
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item label="网站描述/Description" name="websiteDesc">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item label="关键词/Keywords" name="websiteKeywords">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
      )}
    </div>
  )
}
export default MarkstaForm
