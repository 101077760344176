import {
    Button,
    Layout,
    Menu,
    Divider,
    Dropdown,
    PageHeader,
    Spin,
    Select,
    Col,
    Row,
    Input,
    Tooltip,
    Table,
    Modal,
    Form,
    Pagination
} from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined, CloseCircleFilled} from '@ant-design/icons';
import components from 'components'
import {ProjectStatus, ArrangementTypes, DownloadImgSize, VisitTypes} from 'constants/Options'
import React, {useEffect, useRef, useState} from 'react'
import {Link, Route, Switch, useHistory} from 'react-router-dom'
import {PAGE_PATH} from 'apis/Router'
import {
    addProject as _addProject,
    addProjectCategory as _addProjectCategory,
    changeProjectStatus as _changeProjectStatus,
    getPreviewUrlByProject as _getPreviewUrlByProject,
    getProjectById as _getProjectById,
    getProjectCategory as _getProjectCategory,
    getProjectsByCategory as _getProjectsByCategory,
    removeProject as _removeProject,
    saveProjectCategory as _saveProjectCategory,
    addPreset as _addPreset,
    editPreset as _editPreset,
    listPreset as _listPreset,
    removePreset as _removePreset,
} from 'reduxModules/customerPhoto'
import {
    editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import {getEnterpriseInfo as _getInfo} from 'reduxModules/enterprise'

import AddProjectForm from './AddProjectForm'
import PresetList from './PresetList'
import ProjectPage from './ProjectPage'
import RemoveProjectForm from './RemoveProjectForm'
import ShareProjectForm from './ShareProjectForm'
import './projectIndex.module.less'
import classNames from 'classnames'
import styles from './projectIndex.module.less'
import AccessControl from '../../Setting/Components/AccessControl'
import ValidateRulesAndMsg from "../../../Utils/ValidateRulesAndMsg";
import ProjectMenu from "./ProjectMenu";

const {Header, Footer, Sider, Content} = Layout
const {message, CategoryModal, InfoEmptyData} = components
const {Option} = Select;
const ProjectIndex = ({fname, newKey, forPath}) => {
    document.title = `项目 - Infotos`
    // const { fname, newKey } = props
    const [infoDetails, setInfoDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingCategory, setLoadingCategory] = useState(false)
    const [projectCategorys, setProjectCategorys] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({
        key: sessionStorage.getItem('newKey')&&sessionStorage.getItem('newKey')!=='other'?sessionStorage.getItem('newKey'):'all',
        name: '',
        // 所属图片项目分类id
        projectCategoryId: '',
        // 排序类型 SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC
        sortEnumC: '创建日期',
        sortEnum: 'CREATE_TIME_DESC',
        //  项目状态 HIDDEN, ONLINE
        status: '',
    })
    const [Fname, setFname] = useState('全部')
    const [projectList, setProjectList] = useState([])
    const [categoryModalVisible, setCategoryModalVisible] = useState(false)
    const [addVisible, setAddVisible] = useState(false)
    const [bg, setBg] = useState(false)
    const [removeFormVisible, setRemoveFormVisible] = useState(false)
    const [shareFormVisible, setShareFormVisible] = useState(false)
    const [removedProject, setRemovedProject] = useState({})
    const [projectData, setProjectData] = useState({})
    const [JurisdictionVisible, setJurisdictionVisible] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);
    const [ModalText, setModalText] = useState('');
    const [infoName, setInfoName] = useState('')
    const history = useHistory()

    const [accountData, setAccountList] = useState({list: [], pagination: []})
    const [totals, setTotal] = useState(0)
    const pagination = useRef({
            current: 1,
            pageSize: 30,
            total: 0,
        })
    useEffect(() => {
        getProjectCategory()
        getWebDetails()
        getInfo()
        getList()
    }, [])
    const onChange = (page, pageSize) => {
        pagination.current={
            current: page,
            pageSize: 30,
            total: pagination.total,
        }
        getListByCategory(selectedCategory)
    }
    const getInfo = async () => {
        const res = await _getInfo()
        if ([0].includes(res.code)) {
            setInfoName(res.data.name)
        }
    }
    useEffect(() => {
        // 全部的时候api未定怎么传
        console.log('------')
        getListByCategory(selectedCategory)
    }, [selectedCategory])
    const getWebDetails = async () => {
        const res = await _editEnterpriseLevel()
        if ([0].includes(res.code)) {
            setInfoDetails(res.data)
        }
    }
    //获取项目类别
    const getProjectCategory = async () => {
        setLoadingCategory(true)
        const res = await _getProjectCategory()
        if (res.code === 0) {
            setProjectCategorys(res.data)
            setLoadingCategory(false)
        } else {
            message.error(res.msg)
        }
    }

    // 下拉框添加项目类别
    const onAddProjectCategory = async (name) => {
        const body = {
            name: name,
            // sort: projectCategorys.length
        }
        const res = await _addProjectCategory(body)
        if (res.data.code === 0) {
            getProjectCategory()
        }
    }

    // 新增或修改，排序项目类
    const doSaveCategory = async (values) => {
        const res = await _saveProjectCategory(values)
        setCategoryModalVisible(false)
        if (res.data.code === 0) {
            message.success('提交成功')
            getProjectCategory()
        } else {
            message.error('请求失败')
        }
    }
    // 改变项目状态隐藏或发布
    const changeProjectStatus = async (project) => {
        const changeStatus =
            project.status === ProjectStatus[0].key
                ? ProjectStatus[1].key
                : ProjectStatus[0].key
        const res = await _changeProjectStatus({
            id: project.id,
            status: changeStatus,
        })
        if (res.data.code === 0) {
            message.success('更新成功')
            getListByCategory(selectedCategory)
        } else {
            message.error('更新失败:' + res.data.msg)
        }
    }

    const showAdd = () => {
        //判定项目数量是否达到限制
        if (infoDetails.projectNum == 0 || projectList.length < infoDetails.projectNum) {
            setAddVisible(true)
        } else if (projectList.length >= infoDetails.projectNum) {
            setJurisdictionVisible(true);
        }
    }
    //添加项目
    const onAddProject = async (values) => {
        setAddVisible(false)
        console.log(values)
        //授权制造商将商品直接发送给客户
        // values.isAuth2ShipDirectly=true
        // return
        const res = await _addProject(values)
        if (res.data.code === 0) {
            message.success('添加成功!')
            getListByCategory(selectedCategory)
            history.push(`/collection/${res.data.data.id}/photos`)
        }else{
            if(res.data.code===13022) {
                setModalVisible(true)
                setModalText('项目数量已超出限制。若回收站存在删除项目请先清空后再试。');
                // setModalText(res.data.msg);
            }
        }
    }

    const showRemove = (project) => {
        setRemovedProject(project)
        setRemoveFormVisible(true)
    }

    // 展示项目

    const showShare = async (project) => {
        if (project.status === 'HIDDEN') {
            let res = await _changeProjectStatus({
                id: project.id,
                status: 'ONLINE',
            })
            if (res.data.code === 0) {
                getListByCategory(selectedCategory)
                const res = await _getProjectById({id: project.id})
                if (res.code === 0) {
                    setProjectData(res.data)
                    setShareFormVisible(true)
                } else {
                    message.error(res.msg)
                }
            } else {
                message.error('更新失败:' + res.data.msg)
            }
        }
        if (project.status === 'ONLINE') {
            const res = await _getProjectById({id: project.id})
            if (res.code === 0) {
                setProjectData(res.data)
                setShareFormVisible(true)
            } else {
                message.error(res.msg)
            }
        }
        /*
        const res = await _getProjectById({id: project.id})
        if (res.code === 0) {
            setProjectData(res.data)
        } else {
            message.error(res.msg)
        }
        setShareFormVisible(true)*/
    }

    // 删除项目
    const doRemoveProject = async () => {
        const res = await _removeProject({id: removedProject.id})
        if (res.data.code === 0) {
            message.success('删除成功!')
            setRemoveFormVisible(false)
            getListByCategory(selectedCategory)
        } else {
            message.error('删除失败!' + res.data.msg)
        }
        setRemovedProject({})
    }
    const getListByCategory = async (data) => {
        // return
        setLoading(true)
        const res = await _getProjectsByCategory({
            //分类id
            categoryId: data.key==='all'?'':data.key,
            //项目名称
            name: data.name,
            // 所属图片项目分类id
            projectCategoryId: data.projectCategoryId,
            // 排序类型 SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC
            sortEnum: data.sortEnum,
            //  项目状态 HIDDEN, ONLINE
            status: data.status,
            pageNo:pagination.current.current,
            pageSize:pagination.current.pageSize,
        })
        setLoading(false)
        if (res.code === 0) {
            setProjectList(res.data)
            setTotal(res.pagination.total)
            pagination.current=res.pagination
        } else {
            message.error(res.msg)
        }
    }
    // 预览
    const toPreview = async (project) => {
        const res = await _getPreviewUrlByProject({projectId: project.id})
        if (res.data.code == 0) {
            window.open(res.data.data.url)
        }
    }
    //页面跳转
    const toPath=(path)=>{
        forPath(path)
    }
    const onClick = ({key}) => {
        switch (key) {
            case "1":
                setSelectedCategory({
                    key: selectedCategory.key,
                    name: selectedCategory.name,
                    projectCategoryId: selectedCategory.projectCategoryId,
                    sortEnum: 'CREATE_TIME_DESC',
                    sortEnumC: '创建日期',
                    status: selectedCategory.status
                })
                break;
            case "2":
                setSelectedCategory({
                    key: selectedCategory.key,
                    name: selectedCategory.name,
                    projectCategoryId: selectedCategory.projectCategoryId,
                    sortEnum: 'SHOOTING_DAY_DESC',
                    sortEnumC: '拍摄日期',
                    status: selectedCategory.status
                })
                break;
            case "3":
                setSelectedCategory({
                    key: selectedCategory.key,
                    name: selectedCategory.name,
                    projectCategoryId: selectedCategory.projectCategoryId,
                    sortEnum: 'PROJECT_NAME_DESC',
                    sortEnumC: '名称',
                    status: selectedCategory.status
                })
                break;
        }
    };
    const changeSort = (value) => {
        setSelectedCategory({
            key: selectedCategory.key,
            name: selectedCategory.name,
            sortEnumC: selectedCategory.sortEnumC,
            projectCategoryId: selectedCategory.projectCategoryId,
            sortEnum: value,
            status: selectedCategory.status
        })
    }
    const changeName = (event) => {
        // e.persist()
        setSelectedCategory({
            key: selectedCategory.key,
            name: event.target.value,
            projectCategoryId: selectedCategory.projectCategoryId,
            sortEnumC: selectedCategory.sortEnumC,
            sortEnum: selectedCategory.sortEnum,
            status: selectedCategory.status
        })
    }
    // const onKeyDownchange = (e) => {
    //     //   console.log(e.keyCode)
    //     //   console.log(selectedCategory.name)
    //     //   // if（ e.keyCode == 13） {
    //     //   //
    //     //   //   //事件操作
    //     //   // }
    // }
    const searchInput = useRef()
    const clearAll = () => {
        searchInput.current.state.value = '';
        setSelectedCategory({
            key: selectedCategory.key,
            name: '',
            projectCategoryId: selectedCategory.projectCategoryId,
            sortEnumC: selectedCategory.sortEnumC,
            sortEnum: selectedCategory.sortEnum,
            status: selectedCategory.status
        })
    }

    const menu = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClick}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>排序方式</Menu.Item>
                <Menu.Item key="1">创建日期</Menu.Item>
                <Menu.Item key="2">拍摄日期</Menu.Item>
                <Menu.Item key="3">名称</Menu.Item>
            </Menu>
        </div>
    );

    // function handleChange(value) {
    //     // console.log(`selected ${value}`);
    // }

    //添加项目预设列表
    const getList = async () => {
        try {
            const res = await _listPreset({})
            if (res.code === 0) {
                let data = res.data.list
                setAccountList({
                    list: data,
                    // list: reverse(data.records),
                    // pagination: {
                    //     total: data.total,
                    //     pageNo: data.current
                    // }
                })
            }
        } catch (error) {
        } finally {
        }
    }
    const setNewKeys=(key,name)=>{
        console.log(key,name)
        setSelectedCategory({
            key: key,
            name: selectedCategory.name,
            projectCategoryId: selectedCategory.projectCategoryId,
            sortEnum: selectedCategory.sortEnum,
            sortEnumC: selectedCategory.sortEnumC,
            status: selectedCategory.status
        })
        setFname(name)
        console.log(selectedCategory.key)
    }
    return (
        <Layout>
            {/*<AddSelect options={['jack', 'lucy']} defaultValue={'jack'} />*/}
            <ProjectMenu setNewKeys={setNewKeys}
              name={'项目'}
            />
            <Layout style={{marginLeft: 200}}>
                < Header className="sub-header">
                  <PageHeader
                    ghost={false}
                    title={
                      <div style={{fontWeight: 'bold'}}>{Fname}</div>
                    }
                    style={{
                      padding: '24px 50px 23px',
                    }}
                    extra={[
                      <Button type="primary" onClick={showAdd} key={'add'}>
                        添加项目
                      </Button>
                    ]}
                  ></PageHeader>
                  {projectList.length > 0 || selectedCategory.name != '' ? (
                    <Row className={styles.controlBar}>
                      <Col span={12}>
                        {/*<span className={styles.ptCount}>{projectList.length}个项目</span>*/}
                        <span className={styles.ptCount}>{totals}个项目</span>
                        <Divider type="vertical"/>
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button type="link" className="link-btn">
                            按{selectedCategory.sortEnumC}排序
                            <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                          </Button>
                        </Dropdown>
                      </Col>
                      <Col span={12} style={{justifyContent: 'flex-end'}}>
                        <Input
                          className={bg || selectedCategory.name.length > 0 ? styles.searchs : styles.search}
                          placeholder="输入项目名称搜索" onKeyUp={changeName} defaultValue={selectedCategory.name}
                          ref={searchInput}
                          onBlur={() => setBg(false)} onFocus={() => setBg(true)}
                          // prefix={<Tooltip><SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/></Tooltip>}
                          prefix={<iconicon_search className="iconfont iconicon_search" SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                          suffix={
                            selectedCategory.name.length > 0 ?
                              <span
                                className={classNames('iconfont iconbtn_flclose', styles.clearInput)}
                                onClick={clearAll}></span>
                              : ''
                          }
                        />
                      </Col>
                    </Row>) : ''
                  }
                </Header>
                <Content className="sub-content" style={{paddingTop: 170}}>
                  <Spin spinning={loading}>
                    {projectList.length > 0 && (
                      <ProjectPage
                        list={projectList}
                        key={loading}
                        showRemove={showRemove}
                        showShare={showShare}
                        changeStatus={changeProjectStatus}
                        toPreview={toPreview}
                        toPath={toPath}
                      />
                    )}
                    {projectList.length > 0 && (
                      <Pagination
                        className={styles.pagerPositon}
                        current={pagination.current.current}
                        total={totals}
                        defaultPageSize={pagination.current.pageSize}
                        onChange={onChange}
                        showSizeChanger={false}
                        hideOnSinglePage={true}
                      />)}
                    {projectList.length <= 0 && !loading && (
                      <div>
                        <InfoEmptyData description="该分类暂无项目"/>
                        <span className={styles.remarkTip}>添加项目开始向你的朋友或客户展示、分享或交付作品。</span>
                      </div>
                    )}
                  </Spin>
                </Content>
            </Layout>
            <AddProjectForm
                visible={addVisible}
                onCreate={onAddProject}
                onCancel={() => {
                    setAddVisible(false)
                }}
                preset={accountData.list}
                projectCategorys={projectCategorys}
                onAdd={onAddProjectCategory}
            />
            <RemoveProjectForm
                visible={removeFormVisible}
                onCreate={doRemoveProject}
                onCancel={() => {
                    setRemoveFormVisible(false)
                    setRemovedProject({})
                }}
                project={removedProject}
            />
            <CategoryModal
                visible={categoryModalVisible}
                onCreate={doSaveCategory}
                onCancel={() => {
                    setCategoryModalVisible(false)
                }}
                key={loadingCategory}
                list={projectCategorys}
                title={'项目分类管理'}
                tip={'确定删除该项目分类？'}
            />
            <ShareProjectForm
                visible={shareFormVisible}
                onCancel={() => setShareFormVisible(false)}
                project={projectData}
                infoName={infoName}
                key={projectData.id}
            />
            {/*根据项目账号权限展示具体的项目可用个数*/}
            <AccessControl
                visible={JurisdictionVisible}
                htmlTexts={`您当前的账户类型仅支持${infoDetails.projectNum}个项目，若需更多功能请升级到更高版本。`}
                onCancel={() => {
                    setJurisdictionVisible(false)
                }}
            />
            <Modal
              visible={ModalVisible}
              title="功能受限"
              okText="确定"
              centered
              cancelButtonProps={{className: 'footerCanceltext'}}
              closable={false}
              maskClosable={false}
              width={600}
              onOk={() => {
                  setModalVisible(false)
              }}
              footer={[
                  <Button
                    key='ok'
                    type="primary"
                    onClick={() => {
                        setModalVisible(false)
                    }}>
                      确定
                  </Button>

              ]}
            >
                <div className={styles.upgrade}>{ModalText}</div>
            </Modal>
        </Layout>
    )
}

export default ProjectIndex
