import Api from 'apis/Api.js'
import request from 'utils/Request'

// 分页查询展示图片
/**
 * categoryId
integer($int64)
(query)
展示分类id

isShow
string
(query)
是否展示

Available values : Y, N

pageNo
integer($int32)
(query)
当前页面

pageSize
integer($int32)
(query)
分页大小

showcaseType
string
(query)
展示类型

Available values : INDEX, WORKS
 * @param {*} param0
 */
export const _getShowcasePhotoListForPage = async ({
  pageNo = 1,
  pageSize = 100,
  categoryId = undefined,
  showcaseType = undefined,
  isShow = undefined,
}) => {
  return await request({
    method: 'get',
    url: Api.showcasePhoto.getListForPage,
    params: { pageNo, pageSize, categoryId, showcaseType, isShow },
  })
}

// 获取展示预览地址
/**
 *
 * @param {*} param0
 * showcaseType *
string
(query)
展示类型

Available values : INDEX, WORKS
 */
export const _getShowcasePhotoPreviewUrl = async ({ showcaseType }) => {
  return await request({
    method: 'get',
    url: Api.showcasePhoto.previewUrl,
    params: {
      showcaseType,
    },
  })
}

// 添加展示图片
/**
 *
 * @param {*} param0
 * showcaseType  展示分类
 * categoryId 展示分类Id
 * photos 图片合集
 */
export const _addShowcasePhoto = async ({
  showcaseType = 'INDEX',
  categoryId = undefined,
  isCover = 'N',
  isWaterMark = 'N',
  photos = [],
}) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.add,
    data: {
      showcaseType,
      categoryId,
      isWaterMark,
      isCover,
      photos,
    },
  })
}

// 添加描述
export const _addDescription = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.addDescription,
    data: data,
  })
}

// 调整客片照片顺序(将当前已加载的所有图片顺序全部提交)
export const _changeSort = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.changeSort,
    data: data,
  })
}

// 显示到首页

export const _copyToIndex = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.copyToIndex,
    data: data,
  })
}

// 删除展示图片

export const _remove = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.remove,
    data: data,
  })
}

// 移动、复制客片到其他分类

export const _moveOrCopy = async (data) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.moveOrCopy,
    data: data,
  })
}

// 获取下载地址
export const _getShowcasePhotoDownloadUrl = async ({ ids = [] }) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.getDownloadUrl,
    data: {
      ids,
    },
  })
}

// 校验图片名称是否存在
/**
 *
 * @param {*} param0
 * showcaseType 展示类型
INDEX:首页, WORKS:作品
categoryId 分类Id
imageNames 图片名称集合
 */

export const _checkImage = async ({
  showcaseType = 'INDEX',
  categoryId = undefined,
  imageNames = [],
}) => {
  return await request({
    method: 'post',
    url: Api.showcasePhoto.checkImage,
    data: {
      showcaseType,
      categoryId,
      imageNames,
    },
  })
}
export const _getPhotoNum = async (params) => {
  return await request({
    method: 'get',
    url: Api.showcasePhoto.getPhotoNum,
    params
  })
}
