import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import AddSelect from 'components/AddSelect'
import React, {useState} from 'react'
import myMessage from 'components/Message'
import styles from './index.module.less'
import {EmailValidateRule, PhoneRule} from 'utils/ValidateRulesAndMsg'
import {TrimInput, TrimTextArea} from 'components/WithTrim/index'
import {CustomerTypes} from 'constants/Options'
import commonComponent from 'components/index'
import ProvinceSelectCascader from '../ProvinceSelectCascader/index'
import data from '../ProvinceSelectCascader/data.json'

const {Option} = Select

const AddAddressModal = ({
                           visible,
                           onCreate,
                           onCancel,
                           clientInfo,
                           isAdd,
                         }) => {
  const [form] = Form.useForm()
  const {TextArea} = Input;
  const client = JSON.parse(JSON.stringify(clientInfo))
  // useState(() => {
    form.setFieldsValue({
      // customerName: client.customerName ? client.customerName : '',
      // customerPhone: client.customerPhone ? client.customerPhone : '',
      // customerWechat: client.customerWechat ? client.customerWechat : '',
      // customerEmail: client.customerEmail ? client.customerEmail : '',
      // areaArray: client.customerProvinceGeoId ? [client.customerProvinceGeoId, client.customerCityGeoId, client.customerAreaGeoId] : '',
      // customerAddress: client.customerAddress ? client.customerAddress : '',
      // customerRemark: client.customerRemark ? client.customerRemark : '',
      // customerCompany: client.customerCompany ? client.customerCompany : '',

      customerName: client.customerName ? client.customerName : '',
      customerPhone: client.phoneNum ? client.phoneNum : '',
      customerWechat: client.customerWechat ? client.customerWechat : '',
      customerEmail: client.email ? client.email : '',
      areaArray: client.province ? [client.province, client.city, client.region] : '',
      customerAddress: client.address ? client.address : '',
    })
  // }, [clientInfo])
  return (
    <Modal
      visible={visible}
      title={isAdd ? "添加地址" : '编辑地址'}
      okText={isAdd ? "创建" : '保存'}
      cancelText="取消"
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      forceRender={true}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (!values.customerPhone) {
              myMessage.warning('联系电话必须填写')
              return
            }
            const provinceGeoId = values.areaArray[0]
            const cityGeoId = values.areaArray[1]
            const AreaGeoId = values.areaArray[2]
            const provinceData =
              data.find((item) => [provinceGeoId].includes(item.value)) || {}
            // 设置市数据
            const CityOptions = provinceData.children
            const cityData =
              CityOptions.find((item) => [cityGeoId].includes(item.value)) || {}
            // 设置 区 options
            const AreaOptions = cityData.children
            const AreaData=AreaOptions.find((item) => [AreaGeoId].includes(item.value)) || {}
            values.district=provinceData.label+cityData.label+AreaData.label
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.createAddressDialog}
        destroyOnClose={true}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="customerName"
              label="收货人"
              rules={[
                {
                  required: true,
                  message: '请输入',
                },
              ]}
            >
              <Input placeholder="请输入" maxLength={30}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerPhone"
              label="手机号"
              rules={[{
                required: true,
                message: '请输入',
              },PhoneRule]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerEmail"
              label="Email"
              rules={[EmailValidateRule]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name=""
              label="收货地址"
            >
              <Form.Item
                label=""
                name="areaArray"
                className={styles.Inf_ant_form_item}
              >
                <ProvinceSelectCascader width100={true}/>
              </Form.Item>
              <Form.Item
                style={{display: 'inline-block'}}
                label=""
                name="customerAddress"
                // rules={[RequiredInputRule]}
              >
                <TrimInput style={{width: '520px'}} placeholder="收货详细地址"/>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddAddressModal
