import {Button, Col, DatePicker, Form, Modal, Row} from 'antd'
import AddSelect from 'components/AddSelect'
import { VersionsTypes } from 'constants/Options'
import styles from './overRunModel.module.less'
import { PAGE_PATH } from 'apis/Router'
import components from 'components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { getFullRoute } from 'utils/Tools'
import {Link} from "react-router-dom";

const OverRunModel = ({
                            visible,
                            onCancel,
                          htmlTexts
                        }) => {
    return (
        <Modal
            visible={visible}
            title="提示"
            okText="升级订阅"
            centered
            cancelButtonProps={{className: 'footerCanceltext'}}
            onCancel={onCancel}
            width={600}
            footer={[
                <Button
                    key='ok'
                    type="primary"
                    onClick={onCancel}>
                    知道了
                </Button>

            ]}
        >
            <div className={styles.overRun}>{htmlTexts}</div>
        </Modal>
    )
}

export default OverRunModel
