import {Button, Dropdown, Menu, Modal, Spin, Table, Tooltip} from 'antd'
import commonComponent from 'components/index'
import {
  getMyStorage as _getList,
  addCart as _addCart,
  removeMyStorage as _remove,
} from 'reduxModules/order'
import React, { forwardRef, useState, useEffect } from 'react'
import styles from "./Project.module.less";
import {CopyToClipboard} from "react-copy-to-clipboard";
const { MyMessage, InfoEmptyData, ImageCardCell } = commonComponent
const ProjectPage = (props, ref) => {
  const [projectData, setProjectData] = useState({ list: [], pagination: {} })
  const [adding, setAdding] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [checkItem, setCheckItem] = useState(null)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [shareVisible, setShareVisible] = useState(false)
  // 是否加载中
  const [loading, setLoading] = useState(false)
  const onAddShopCart = async (id) => {
    // 派发添加请求
    const body = {
      id: id,
    }
    // 派发修改数据请求
    return await _addCart(body)
  }
  const getList = async ({ pagination = {} }) => {
    let res = null

    await setLoading(true)

    res = await _getList({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    })

    await setLoading(false)
    if (res.code === 0) {
      const {
        formattedData: { pagination: formattedPagination, list },
      } = res.data

      const newPhotosData = {
        pagination: formattedPagination,
        list,
      }
      setProjectData(newPhotosData)
    } else {
      MyMessage.error(res.msg)
    }
  }
  // 删除
  const onRemove = async (id) => {
    // 派发修改数据请求
    return await _remove({ id })
  }
  useEffect(() => {
    getList({})
  }, [])
  const handleTableChange = (pagination) => {
    // console.log(pagination)
    this.getList({ pagination })
  }
  const onDelete = async () => {
    const { getList, onRemove, pagination } = this.props
    try {
      const resData = await onRemove(this.state.checkItem.id)

      if (resData.data && [0].includes(resData.data.code)) {
        MyMessage.success('删除成功')
        // 派发获取列表数据
        getList({ pagination })
      } else {
        MyMessage.error('删除失败')
      }
    } catch (error) {}
  }
  const handleItem = async (type, item) => {
    setCheckItem(item)
    if(type === '分享'){
      setShareVisible(true)
    }else if(type === '编辑'){

    }else if(type === '删除'){
      // myMessage.success('修改成功')
      // getListByClient(selectedClient)
      setDeleteVisible(true)
    }
  }
  const onPreview=()=>{}
  const onEdit=()=>{}
  const columns = [
    {
      title: '编辑中的项目',
      dataIndex: 'name',
      key: 'name',
      render: (_, { name, src, description }) => (
        <ImageCardCell
          imgSize={100}
          title={name}
          imgSrc={src}
          description={description}
        />
      ),
    },
    {
      title: '类别',
      dataIndex: 'typeDesc',
      key: 'typeDesc',
    },
    {
      title: '最近更新时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '',
      dataIndex: 'opt',
      key: 'opt',
      width: 200,
      render: (_, item) => (
        <div className={styles.tableOpt}>
          <Button
            className={styles.btnAdd2Cart}
            key={'addShopCart' + item.id}
            // type="primary"
            onClick={() => this.onAddCar(item.id)}
            loading={currentId === item.id && adding}
          >
            加入购物车
          </Button>
          <Dropdown
            overlay={
              <div className={styles.settingsMenu}>
                <Menu>
                  <Menu.Item><a key={'share' + item.id} onClick={() => handleItem('分享',item)}>分享给客户</a></Menu.Item>
                  <Menu.Item><a key={'preview' + item.id} onClick={() => onPreview('预览',item)}>预览</a></Menu.Item>
                  <Menu.Divider />
                  <Menu.Item><a key={'edit' + item.id} onClick={() => onEdit('编辑',item)}>编辑</a></Menu.Item>
                  {/*<Menu.Divider />*/}
                  <Menu.Item><a key={'delete' + item.id} onClick={() => handleItem('删除',item)}>删除</a></Menu.Item>
                </Menu>
              </div>
            }
            placement="bottomRight"
            trigger={['click']}>
            <span className="iconfont iconbtn_list_set"></span>
          </Dropdown>
        </div>
      ),
    },
  ]
  // console.log(cacheData)
  return (
    <Spin tip="数据加载中" spinning={loading}>
      {projectData.list.length > 0 && (
        <div className={styles.myProject}>
          <div className={styles.controlBar}>
            {projectData.pagination.total}条
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={projectData.list}
            pagination={{
              ...projectData.pagination,
              showSizeChanger: false,
              className: styles.pagerPositon
            }}
            onChange={()=>this.handleTableChange}
          />
          <Modal
            title="删除暂存"
            visible={deleteVisible}
            onOk={()=>this.onDelete}
            onCancel={()=>setDeleteVisible(false)}
            okText="确认"
            cancelText="取消"
            width={600}
            closable={false}
            centered
            maskClosable={false}
            cancelButtonProps={{className: 'footerCanceltext'}}
          >
            <div className="Inf_page_title">删除该条相册编辑数据将不可恢复，确定继续？</div>
          </Modal>
          <Modal
            title="分享"
            visible={shareVisible}
            onCancel={()=>setShareVisible(false)}
            footer={null}
            width={600}
            closable={true}
            centered
            maskClosable={false}
            cancelButtonProps={{className: 'footerCanceltext'}}
          >
            <div className="shareText">
              <div>项目链接：https://design.infotos.co/view/8u9o4Nst</div>
              <CopyToClipboard text={`https://design.infotos.co/view/8u9o4Nst`}>
                <Tooltip title="已复制" trigger={['click']}>
                  <Button type="primary">
                    复制
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            </div>
          </Modal>
        </div>
      )}
      {projectData.list.length <= 0 && !loading && (
        <InfoEmptyData description="暂无暂存的设计项目" />
      )}
    </Spin>
  )
}

export default forwardRef(ProjectPage)
