import {Modal} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import styles from './index.module.less'
import QRCode from 'qrcode.react';
import Imgs from 'imgs'
import myMessage from "../Message";
import { Link, useHistory, useParams } from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import Api from 'apis/Api.js'
import {
  payCallback as _payCallback,
  upgrade as _upgrade,
  getLevelOrder as _getLevelOrder,
} from 'reduxModules/subscribe'
import {
  getOrderDetails as _getOrderDetails,
} from 'reduxModules/store'
// import {
//   toMasterCurrency as _toMasterCurrency,
// } from 'reduxModules/customerPhoto'
const {payWechat} = Imgs
const PaymentQRcodeModal = ({
                           visible,
                           onCancel,
                           wxCode,
                           order,
                         }) => {
  let { id } = useParams()
  const exHeader = {
    projectId: id,
  }
  const [codeVisible, setCodeVisible] = useState(false)
  const [payInter,setPayInter]=useState(null)
  const history = useHistory()
  // 定时器查看支付情况
  let inters = useRef(null)
  useEffect(() => {
    setCodeVisible(visible)
    if(payInter){
      clearInterval(payInter)
      setPayInter(null)
    }
    if(visible){
      payCallback()
    }else{
      clearInterval(inters.current)
    }
  }, [visible])
  const onCancels=()=>{
    clearInterval(payInter)
    // 跳转到我的订单
    history.push(PAGE_PATH.my.children.orders)
    onCancel()
    setCodeVisible(false)
  }
  const payCallback=async()=>{
    let order=JSON.parse(localStorage.getItem('myOrder'))
    let inter=setInterval(async ()=>{
      //升级续费订单首次支付状态查看
      const resData = order?.status=='waitPay'?await _getOrderDetails({orderId: order.id}):await _getLevelOrder()
      if ((resData.data && [0].includes(resData.data.code))||(resData.data && resData.code=='000000')) {
        let orderType=order?.status=='waitPay'?resData.data:resData.data.data[0]//判定是订阅计划支付还是订单再支付
        if(orderType.isValid=='Y'&&orderType.status=='Y'||!orderType?.isValid&&orderType?.status!='waitPay'){
          clearInterval(inter)
          clearInterval(payInter)
          setCodeVisible(false)
          history.push(PAGE_PATH.my.children.orders)
        }
      }
    },1000)
    setPayInter(inter)
  }
  /*const getPayStatus=async ()=>{
    let json={
      orderNum:order?.orderNum,
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.getPayStatus+'/'+order?.orderNum
    }
    const res = await _toMasterCurrency({data,exHeader})
    // console.log(res,inters.current)
    if (res.code == 0) {
      if(res.data[0]?.status== "paid") {
        onCancels()
        clearInterval(inters.current)
      }
    }else{
      clearInterval(inters.current)
      myMessage.warning(res.msg);
    }
  }*/
  return (
    <Modal
      className={styles.paymentDialog}
      visible={codeVisible}
      centered
      maskClosable={false}
      onCancel={onCancels}
      footer={null}
      width={600}
      forceRender={true}
    >
      <div className={styles.codes}>
        <div className={styles.payway}><img src={payWechat}/>微信支付</div>
        <QRCode
          id="qrCode"
          value={wxCode}
          size={300} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          /*imageSettings={{ // 二维码中间的logo图片
            src: 'logoUrl',
            height: 100,
            width: 100,
            excavate: true, // 中间图片所在的位置是否镂空
          }}*/
        />
        <div>请使用微信扫一扫<br/>扫描二维码即可支付</div>
      </div>
    </Modal>
  )
}

export default PaymentQRcodeModal
