import {Button, Layout, Menu, Modal, PageHeader, Spin} from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import {
    editEnterpriseLevel as _editEnterpriseLevel,
  cancelLevelOrder as _cancelLevelOrder,
  deleteLevelOrder as _deleteLevelOrder,
  upgradeLevelOrder as _upgradeLevelOrder,
} from 'reduxModules/subscribe'
import {
  cancelOrder as _cancelOrder,
  deleteOrder as _deleteOrder,
  payOrder as _payOrder,
} from 'reduxModules/store'
import {useHistory} from 'react-router-dom'
import styles from "./index.module.less";
import Api from 'apis/Api.js'
import commonComponent from 'components/index'
const { myMessage,PaymentQRcodeModal } = commonComponent

const { Header, Footer, Sider, Content } = Layout

const MyPage = ({ routes, name }) => {
  let history = useHistory()
  document.title = `${name} - Infotos`
  let location = useLocation()
  const childRef = useRef()
  const [infoDetails, setInfoDetails] = useState({})
  const [orderHead,setorderhead] = useState([])
  const [QRcodeVisible,setQRcodeVisible]=useState(false)//支付二维码弹框
  const [payCode,setPayCode]=useState('')//支付二维码
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [visibleText, setVisibleText] = useState('删除订单')
  const [order, setOrder] = useState(null)
    useEffect(() => {
        getWebDetails()
    }, [])
    useEffect(() => {
      if(history.location.pathname.includes('/my/order-details')){//是否订单详情
        let oldOrder=JSON.parse(localStorage.getItem('myOrder'))
        console.log(oldOrder)
        setOrder(oldOrder)
        setOrderBtns(oldOrder)
      }
    }, [window.location.href])
  const setOrderBtns=(order)=>{
    let btns=[]
    if(order) {
      if (order.status=='waitPay'||order.status == 'N' && order.isValid == 'Y') {
        btns.push(<Button style={{
          color: '#1AA77D',
          background: '#fff',
          border: '0.5px solid rgba(26, 167, 125, 0.6)',
          borderRadius: '2px',
          boxShadow: '0 0 1px rgba(26, 167, 125, 0.6)',
        }} onClick={()=>payOrder('pay')}>支付</Button>,)
      }
      if (order.status=='waitPay'||order.status == 'N' && order.isValid == 'Y') {
        btns.push(<Button style={{
          color: '#1AA77D',
          background: '#fff',
          border: '0.5px solid rgba(26, 167, 125, 0.6)',
          borderRadius: '2px',
          boxShadow: '0 0 1px rgba(26, 167, 125, 0.6)',
        }} onClick={()=>payOrder('cancel')}>取消订单</Button>,)
      }
      if (order.status=='cancel'||order.status == 'N' && order.isValid == 'N') {
        btns.push(<Button style={{
          color: '#1AA77D',
          background: '#fff',
          border: '0.5px solid rgba(26, 167, 125, 0.6)',
          borderRadius: '2px',
          boxShadow: '0 0 1px rgba(26, 167, 125, 0.6)',
        }} onClick={()=>payOrder('delete')}>删除订单</Button>,)
      }
      if (order.status=='paid'||order.status == 'Y' && order.isValid == 'Y') {
        btns.push(<Button style={{
          color: '#1AA77D',
          background: '#fff',
          border: '0.5px solid rgba(26, 167, 125, 0.6)',
          borderRadius: '2px',
          boxShadow: '0 0 1px rgba(26, 167, 125, 0.6)',
        }}>打印订单</Button>,)
      }
      setorderhead(btns)
    }
  }
  const payOrder= async (way)=>{
    let order=JSON.parse(localStorage.getItem('myOrder')),res
    if(way=='pay'){//再次支付订单
      let openid = {
        // openid: 'ousZ75EJVQqqJIje1VrezjBJXqkE'
        // openid: JSON.parse(sessionStorage.getItem('user')).userId
        // openid: loginUser.id
        // openid:''
      }
      let json = {
        // appid:'wx0e2aefa2f0045f55',
        appid: 'wx0e8580e0e3dcd330',
        payer: openid,
        tradeType: 'NATIVE',
        notifyUrl:`${Api.url}/enterprise/level/unifiedOrder/v3/callback`,
      }
      let data=order.status=='waitPay'?{
        appid: 'wx0e8580e0e3dcd330',
        // attach: 'myorders',
        orderId: order.id,
        payer: openid,
        tradeType: 'NATIVE',
      }:{
        orderId:order.id,
        // couponId: accUpgrade.editionType,
        couponId: order.couponId,
        payForm: json,
      }
      res = order.status=='waitPay'?await _payOrder(data):await _upgradeLevelOrder(data)
      if(res.status==200){
        if(res.data.code==0) {
          // setPayCode(res.data.data.payInfo)
          setPayCode(res.data.data.payInfo)
          setQRcodeVisible(true)
        }else if(res.data.code==13034){
          myMessage.warning(res.data.msg)
        }
      }
    }
    if(way=='cancel'){//取消订单
      setVisibleText('取消订单')
      setDeleteVisible(true)
    }
    if(way=='delete') {//删除订单
      setVisibleText('删除订单')
      setDeleteVisible(true)
    }
  }
  const deleteProduct=async ()=>{
    let res,order=JSON.parse(localStorage.getItem('myOrder'))
    console.log(order)
    if(visibleText=='取消订单'){
      res = order.status=='waitPay'?await _cancelOrder({orderId :order.id}):await _cancelLevelOrder({id:order.id})
    }
    if(visibleText=='删除订单'){
      res = order.status=='cancel'?await _deleteOrder({orderId :order.id}):await _deleteLevelOrder({id:order.id})
    }
    console.log(res)
    if(res.status==200&&(res.data.code==0||res.data.code=='000000')){
      setDeleteVisible(false)
      if(visibleText=='取消订单'){
        let oldOrder=JSON.parse(JSON.stringify(order))
        if(order.status=='waitPay'){
          oldOrder.status='cancel'
        }else {
          oldOrder.isValid = 'N'
        }
        setOrderBtns(oldOrder)
        // 跳转到我的订单详情
        history.push(`/my/order-details/${order.id}`)
      }
      if(visibleText=='删除订单'){
        localStorage.removeItem('myOrder')
        // 跳转到我的订单
        history.push(PAGE_PATH.my.children.orders)
      }
    }
  }
  const subSiderMenu = routes.map((menu) => {
    if (menu.name === '订单详情') {return(<></>)}
    if (menu.name === '我的暂存') {
      return (
        <>
          <Menu.Item key={menu.path}>
            <Link to={menu.path}>{menu.name}</Link>
          </Menu.Item>
          {/*<Menu.Divider/>*/}
        </>
      )
    }
    return (
      <Menu.Item key={menu.path}>
        <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
    )
  })

  const onSave = () => {
    childRef.current.onSaveForm()
  }
    const getWebDetails = async () => {
        const res = await _editEnterpriseLevel()
        if ([0].includes(res.code)) {
          console.log(res.data)
            setInfoDetails(res.data)
        }
    }
  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} ref={childRef} infoDetails={infoDetails}/>
        )}
      />
    )
  }
  // 返回
  const goBack = () => {
    history.goBack()
  }
  return (
    <Layout>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
        }}
        theme="light"
        className={classNames('sub-sidebar')}
      >
        <div className="sub-title">{name}</div>
        <Menu
          mode="inline"
          theme="light"
          defaultSelectedKeys={[location.pathname]}
          style={{ border: 'none' }}
          className="sub-sider-menu"
          inlineIndent={30}
        >
          {subSiderMenu}
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Header className="sub-header">
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={route.path}
                path={route.path}
                render={() => {
                  const name =
                    route.path === PAGE_PATH.settings.children.seo
                      ? '网站' + route.name
                      : route.name

                  return (
                    <PageHeader
                      ghost={false}
                      title={<div style={{ fontWeight: 'bold'}} id="forBack">
                        {route.path === PAGE_PATH.my.children.orderDetails&&(<Button
                        icon={
                          <span
                            className={`iconfont iconbtn_back`}
                            style={{fontSize:'12px'}}
                          ></span>
                        }
                        onClick={goBack}
                        style={{
                          width: '28px',
                          height: '22px',
                          lineHeight: '20px',
                          padding: 0,
                          marginRight: '10px',
                        }}
                      />)}
                      {name=='登录历史'?'账号登录历史记录':name=='订单详情'?`订单${order?.orderNum}`:name}
                      </div>}
                      style={{
                        padding: '24px 50px 23px',
                      }}
                      extra={
                        ['info'].includes(route.page)
                          ? [
                              <Button
                                key={'onSave' + i}
                                type="primary"
                                onClick={onSave.bind(this, route.page)}
                              >
                                保存
                              </Button>,
                            ]
                          : route.path === PAGE_PATH.my.children.orderDetails?orderHead:[]
                      }
                    ></PageHeader>
                  )
                }}
              />
            ))}
          </Switch>
        </Header>
        <Content className="sub-content">
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
          {/*//支付弹框*/}
          <PaymentQRcodeModal visible={QRcodeVisible} wxCode={payCode} onCancel={()=>setQRcodeVisible(false)}></PaymentQRcodeModal>
          <Modal
            title={visibleText}
            visible={deleteVisible}
            onOk={deleteProduct}
            onCancel={() => setDeleteVisible(false)}
            okText="确定"
            cancelText="取消"
            width={600}
            closable={false}
            centered
            maskClosable={false}
            cancelButtonProps={{className: 'footerCanceltext'}}
          >
            <div className="Inf_page_title">确定要继续{visibleText=='取消订单'?'取消订单':'删除订单'}？</div>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  )
}

export default MyPage
