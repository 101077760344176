import {Button, Layout, Menu, Divider, Dropdown, PageHeader, Spin, Select, Col, Row, Input, Tooltip, Card,Table, Pagination, Modal } from 'antd'
import {InfoCircleOutlined, UserOutlined, SearchOutlined,CloseCircleFilled} from '@ant-design/icons';

import components from 'components'
import {ProjectStatus, ArrangementTypes} from 'constants/Options'
import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
import {
    getInfoClient as _getInfoClient,
    editClient as _editClient,
    addClient as _addClient,
    removeClient as _removeClient,
} from 'reduxModules/client'
import CommonComponent from 'components/index'
import classNames from "classnames";
import myMessage from 'components/Message'
import styles from './index.module.less'
import Imgs from 'imgs'
import AddClientForm from '../Components/AddClientForm'
import EditClientForm from '../Components/EditClientForm'
import moment from "moment";
import {editEnterpriseLevel as _editEnterpriseLevel,} from 'reduxModules/subscribe'
import Img from 'imgs'
const {Header, Footer, Sider, Content} = Layout
const { MyMessage, InfoEmptyData } = CommonComponent
const {ClientProfoto} = Imgs

const Client = ({name}) => {
    document.title = `${name} - Infotos`
    let history = useHistory()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    // 选中的行keys
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // 选中的行
    const [selectedRows, setSelectedRows] = useState([])
    // 客户总数
    const [total, setTotal] = useState(0)
    const [bg, setBg] = useState(false)
    const [deleteVisible, setdeleteVisible] = useState(false)
    const [selectedClient, setSelectedClient] = useState({
        sourceEnum: '', //客户来源
        sourceName: '全部', //客户来源
        grouping: '一组', //客户分组
        searchValue:'',//邮件/客户名称/客户电话
        pageNo:1,
        pageSize:20,
        current:10,
        totalCount:20
    })
    const [ClientList, setClientList] = useState([])
    const [addVisible, setAddVisible] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [clientInfo, setClientInfo] = useState({})
    const [keys, setKeys] = useState(0)
    const [infoDetails, setInfoDetails] = useState({crm:null})
    useEffect(() => {
        getWebDetails()
    }, [])
    // 获取账号权限
    const getWebDetails = async () => {
        const res = await _editEnterpriseLevel()
        if ([0].includes(res.code)) {
            setInfoDetails(res.data)
        }
    }
    // 添加
    const showAdd = (item,key) => {
        setClientInfo({});
        setAddVisible(true)
        setIsAdd(true)
    }
    // 客户来源
    const onClick = ({key}) => {
        // switch (key) {
        //     case "1":
                setSelectedClient({
                    sourceEnum: key==1?'':key==2?'PROJECT':key==3?'SALE':'OTHER', //客户来源
                    sourceName: key==1?'全部':key==2?'项目':key==3?'订单':'其他', //客户来源
                    grouping: '', //客户分组
                    searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
                    pageNo:selectedClient.pageNo,
                    pageSize:selectedClient.pageSize,
                })
              /*  break;
            case "2":
                setSelectedClient({
                    sourceEnum: 'PROJECT', //客户来源
                    sourceName: '项目', //客户来源
                    grouping: '', //客户分组
                    searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
                    pageNo:selectedClient.pageNo,
                    pageSize:selectedClient.pageSize,
                })
                break;
            case "3":
                setSelectedClient({
                    sourceEnum: 'SALE', //客户来源
                    sourceName: '销售', //客户来源
                    grouping: '', //客户分组
                    searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
                    pageNo:selectedClient.pageNo,
                    pageSize:selectedClient.pageSize,
                })
                break;
            case "4":
                setSelectedClient({
                    sourceEnum: 'OTHER', //客户来源
                    sourceName: '其他', //客户来源
                    grouping: '', //客户分组
                    searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
                    pageNo:selectedClient.pageNo,
                    pageSize:selectedClient.pageSize,
                })
                break;*/
        // }

    };

    // 客户分组
    const onClicks = ({key}) => {
        switch (key) {
            case "1":
                setSelectedClient({
                    sourceEnum: '全部', //客户来源
                    grouping: '一组', //客户分组
                    searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
                    pageNo:selectedClient.pageNo,
                    pageSize:selectedClient.pageSize,
                })
                break;
        }
    };
    // 搜索名称
    const changeName = (event) => {
        // e.persist()
        setSelectedClient({
            sourceEnum: selectedClient.sourceEnum, //客户来源
            sourceName: selectedClient.sourceName, //客户来源
            grouping: '一组', //客户分组
            searchValue:event.target.value,//邮件/客户名称/客户电话
            pageNo:selectedClient.pageNo,
            pageSize:selectedClient.pageSize,
            totalCount:20
        })
    }
    const searchInput = useRef()
    const clearAll = () => {
        searchInput.current.state.value = '';
        setSelectedClient({
            sourceEnum: selectedClient.sourceEnum, //客户来源
            sourceName: selectedClient.sourceName, //客户来源
            grouping: '一组', //客户分组
            searchValue:'',//邮件/客户名称/客户电话
            pageNo:1,
            pageSize:selectedClient.pageSize,
            totalCount:20
        })
    }
    const menu = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClick}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>客户来源</Menu.Item>
                <Menu.Item key="1">全部</Menu.Item>
                <Menu.Item key="2">项目</Menu.Item>
                <Menu.Item key="3">订单</Menu.Item>
                <Menu.Item key="4">其他</Menu.Item>
            </Menu>
        </div>
    );
    const menus = (
        <div className={styles.sortMenu}>
            <Menu onClick={onClicks}>
                {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
                <Menu.Item key="0" disabled className={styles.titleName}>客户分组</Menu.Item>
                <Menu.Item key="1">一组</Menu.Item>
            </Menu>
        </div>
    );
    const onBaseClick=(current,pageSize)=>{
        setSelectedClient({
            sourceEnum: selectedClient.sourceEnum, //客户来源
            sourceName: selectedClient.sourceName, //客户来源
            grouping: '一组', //客户分组
            searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
            pageNo:current,
            pageSize:selectedClient.pageSize,
            totalCount:20
        })
    }
    // 获取客户列表
    const getListByClient = async (data) => {
        setLoading(true)
        const res = await _getInfoClient({
            sourceEnum: selectedClient.sourceEnum,
            searchValue:data.searchValue,//邮件/客户名称/客户电话
            pageNo:selectedClient.pageNo,
            pageSize:selectedClient.pageSize,
        })
        setLoading(false)
        if (res.data.code === 0) {
            setClientList(res.data.data.list)
            // setSelectedClient({
            //     sourceEnum: '全部', //客户来源
            //     grouping: '一组', //客户分组
            //     searchValue:selectedClient.searchValue,//邮件/客户名称/客户电话
            //     pageNo:1,
            //     pageSize:20,
            //     totalCount:res.data.data.totalCount
            // })
            setTotal(res.data.data.totalCount)
        } else {
            if(!(res.data.code==13044&&res.data.msg=='您当前账号不支持客户管理!')) {
                myMessage.error(res.data.msg)
            }
        }
    }
    useEffect(() => {
        getListByClient(selectedClient)
    }, [selectedClient])
    const accountCell = ({customerPortrait, customerName ,item}) => (<div onClick={clickRow(item)} className={styles.account_cell}>
        <img
            src={customerPortrait || ClientProfoto}/>
        <div>
            <p className={styles.nickname}>{customerName}</p>
        </div>
    </div>)
    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, item) =>  {return (
                <div onClick={() => clickRow(item)} className={styles.account_cell} style={{cursor:'pointer'}}>
                    <img src={item.customerPortrait || ClientProfoto}/>
                    <div>
                        <p className={styles.nickname}>{item.customerName}</p>
                    </div>
                </div>
            )},
        },
        {
            title: '手机号码',
            dataIndex: 'customerPhone',
            key: 'customerPhone',
        },
        {
            title: 'Email',
            dataIndex: 'customerEmail',
            key: 'customerEmail',
        },
        {
            title: '所在地',
            dataIndex: 'customerFullAddress',
            key: 'customerFullAddress',
            render: (_, item) =>  {return (
                <div>
                    {item.customerProvinceGeo}{item.customerCityGeo}{/*{item.customerAreaGeo}*/}
                </div>
            )},
        },

        {
            title: '创建日期',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (_, item) =>  {return (
                <div>
                    {moment(item.createTime).format('YYYY/MM/DD')}
                </div>
            )},
        },
        {
            title: '',
            dataIndex: 'opt',
            key: 'opt',
            width: 20,
            render: (_, item) => {
                // if (+item.rolesId === 1) return ''
                return (
                    <div>
                        <Dropdown
                            overlay={
                                <div className={styles.settingsMenu}>
                                    <Menu>
                                        {/* <Menu.Item><a key='0' onClick={() => handleItem('停用', item)}>停用</a></Menu.Item> */}
                                        {/* <Menu.Item><a key='1' onClick={() => handleItem('添加客片', item)}>添加客片项目</a></Menu.Item>
                                        <Menu.Item><a key='2' onClick={() => handleItem('添加消费', item)}>添加消费记录</a></Menu.Item>
                                        <Menu.Item><a key='3' onClick={() => handleItem('添加标签', item)}>添加标签</a></Menu.Item>
                                        <Menu.Divider /> */}
                                        <Menu.Item><a key='4' onClick={() => handleItem('编辑', item)}>编辑</a></Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item><a key='5' onClick={() => handleItem('删除', item)}>删除</a></Menu.Item>
                                    </Menu>
                                </div>
                            }
                            placement="bottomRight"
                            trigger={['click']}>
                            <span className="iconfont iconbtn_list_set"></span>
                        </Dropdown>
                    </div>
                )
            }
        }
    ]
    const handleItem = async (type, item) => {
        if(type === '编辑'){
            setKeys(keys+1)
            setClientInfo({...item});
            setAddVisible(true)
            setIsAdd(false)
        }else if(type === '删除'){
            // myMessage.success('修改成功')
            // getListByClient(selectedClient)
            setClientInfo(item);
            setdeleteVisible(true)
        }
    }

    useEffect(() => {
        // console.log(clientInfo,'参数')
    }, [clientInfo])
    //添加客户
    const onAddClient = async (values) => {
        values.customerProvinceGeoId=values.areaArray[0]
        values.customerCityGeoId=values.areaArray[1]
        values.customerAreaGeoId=values.areaArray[2]
        if(clientInfo.id) {
            values.id=clientInfo.id
            const res = await _editClient(values)
            if (res.data.code === 0) {
                myMessage.success('修改成功')
                setAddVisible(false)
                getListByClient(selectedClient)
                setClientInfo({});
                // history.push(`/collection/${res.data.data.id}/photos`)
            }
        }else{
            const res = await _addClient(values)
            if (res.data.code === 0) {
                myMessage.success('添加成功')
                setAddVisible(false)
                getListByClient(selectedClient)
                setClientInfo({});
                // history.push(`/collection/${res.data.data.id}/photos`)
            }
        }
    }
    const clickRow=(row)=>{
        sessionStorage.setItem('client',JSON.stringify(row))
        history.push(PAGE_PATH.clientInfo.index)
    }
    const deleteModal=async ()=>{
        let data={id:clientInfo.id}
        const res = await _removeClient(data)
        if (res.data.code === 0) {
            getListByClient(selectedClient)
            setClientInfo({});
            setdeleteVisible(false)
            myMessage.success('删除成功')
            // history.push(`/collection/${res.data.data.id}/photos`)
        }
    }
    return (
        <div className={styles.Inf_page}>
            {/*< Header className="sub-header" >*/}
            <PageHeader
                style={{
                    padding: '24px 50px 23px',
                }}
                ghost={false}
                title="客户"
                extra={
                    infoDetails.crm==='Y'&&(
                      <Button type="primary" onClick={showAdd.bind('','add')} key={'add'}>添加</Button>
                    )
                }
            >
            </PageHeader>
            {/*<Divider  style={{ margin: '0px 0' }}/>*/}

            {/*</Header>*/}
            {/*<Spin tip="加载中" spinning={loading}>*/}

            {/*    <div style={{height: '30px'}}></div>*/}
            {/*</Spin>*/}
            <Card bordered={false} className={styles.tables}>
                {/*是否显示客户权限*/}
                {infoDetails.crm==='Y'&&(
                    <Spin tip="数据加载中" spinning={loading}>
                            {ClientList.length > 0||selectedClient.sourceEnum!=''||selectedClient.searchValue!=''?(
                        <Row className={styles.controlBar}>
                        <Col span={12}>
                            <span className={styles.ptCount}>{total}条</span>
                            <Divider type="vertical"/>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button type="link" className="link-btn">
                                    {selectedClient.sourceName}来源
                                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                </Button>
                            </Dropdown>
                            {/*<Dropdown overlay={menus} trigger={['click']}>
                                    <Button type="link" className="link-btn">
                                        {selectedClient.grouping}
                                        <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                    </Button>
                                </Dropdown>*/}
                        </Col>
                        <Col span={12} style={{justifyContent: 'flex-end'}}>
                            <Input className={bg || selectedClient.searchValue ? styles.searchs : styles.search}
                                   placeholder="输入客户名、电话或Email搜索" onKeyUp={changeName} defaultValue={selectedClient.searchValue} ref={searchInput}
                                   onBlur={() => setBg(false)} onFocus={() => setBg(true)}
                                //    prefix={<Tooltip><SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/></Tooltip>}
                                   prefix={<iconicon_search className="iconfont iconicon_search" SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                                   suffix={
                                       selectedClient.searchValue ?
                                           <span className={classNames('iconfont iconbtn_flclose', styles.clearInput)}
                                                 onClick={clearAll}></span>
                                           : ''
                                   }
                            />
                        </Col>
                    </Row>):null
                    }
                        {ClientList.length > 0 && (
                                <Table
                                    // className={styles.tablesbody}
                                    style={{marginBottom: '30px'}}
                                    dataSource={ClientList}
                                    columns={columns}
                                    pagination={{
                                        simple:false,
                                        current:selectedClient.pageNo,
                                        total:total,
                                        hideOnSinglePage: true,
                                        howSizeChanger: false,
                                        position:["bottomCenter"],
                                        pageSize:20,
                                        onChange:(current,pageSize)=>{
                                        onBaseClick(current,pageSize)
                                        }
                                    }}
                            />
                        )}
                        {ClientList.length <= 0 && !loading && (
                            <div>
                                <InfoEmptyData description="暂无客户数据" />
                                <span className={styles.remarkTip}>添加客户并关联应用随时跟进不流失。</span>
                            </div>
                        )}
                    </Spin>
                )}
                {infoDetails.crm==='N'&&(
                  <div className={styles.Trailtext}>
                      <div className={styles.restricted}><img src={Img.restricted} className={styles.restrictedImg} /></div>
                      <div className={styles.dialogTitle}>您当前的账户类型不支持</div>
                      <div className={styles.dialogDescription}>客户模块可以创建并关联完整的客户数据，包括基本信息、项目与销售记录、维护记录等，帮助您更好的维护客户关系。</div>
                      <Button
                        key='ok'
                        type="primary">
                          <Link
                            to={PAGE_PATH.settings.children.subscribe}
                          >
                              升级账号
                          </Link>
                      </Button>
                  </div>
                )}
            </Card>
            {clientInfo.id?<AddClientForm
                visible={addVisible}
                clientInfo={clientInfo}
                isAdd={isAdd}
                onCreate={onAddClient}
                onCancel={() => {
                    setAddVisible(false)
                }}
            />:<EditClientForm
                visible={addVisible}
                clientInfo={clientInfo}
                isAdd={isAdd}
                onCreate={onAddClient}
                onCancel={() => {
                    setAddVisible(false)
                }}
                key={keys}
            />}
            <Modal
                title="删除客户"
                visible={deleteVisible}
                onOk={deleteModal}
                onCancel={()=>setdeleteVisible(false)}
                okText="确认"
                cancelText="取消"
                width={600}
                closable={false}
                centered
                maskClosable={false}
                cancelButtonProps={{className: 'footerCanceltext'}}
            >
                <div className="Inf_page_title">该客户相关的绑定将会自动解除，且数据不可恢复，确定删除？</div>
            </Modal>
        </div>
    )
}

export default Client
