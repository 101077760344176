/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-10-26 22:10:45
 */
import commonComponent from 'components/index'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  addShowcasePhoto as _addShowcasePhoto,
  changeSort as _changeSort,
  checkImage as _checkImage,
  getShowcasePhotoListForPage as _getPhotoList,
  getPhotoNum as _getPhotoNum,
} from 'reduxModules/showcasePhoto'
import PhotoPage from '../Components/PhotoPage'
import OverRunModel from '../Components/OverRunModel'
import {Modal} from "antd";
import AccessControl from "../../Setting/Components/AccessControl";

const { MyMessage, UploadModal, InfoEmptyData } = commonComponent

const WebSettings = (props, ref) => {
  const formRef = useRef()
  // 照片数据
  const [photosData, setPhotosData] = useState({ list: [] })
  // 是否加载中
  const [loading, setLoading] = useState(false)
  // 是否重新加载
  const [reloading, setReloading] = useState(false)

  // 是否还有下一页
  const [hasMore, setHasMore] = useState(false)

  // 上传的弹窗显示与否
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  // 上传弹窗ref
  const [uploadModalRef, setUploadModalRef] = useState()
  const [OverRunVisible, setOverRunVisible] = useState(false)
  let history = useHistory()
  // 重新获取数据
  const reloadData = () => {
    setPhotosData({ list: [] })
    setReloading(true)
    getPhotos({})
    setReloading(false)
  }
  // 新增照片
  const savePhotos = async (res) => {
    const addRes = await _addShowcasePhoto({
      showcaseType: 'INDEX',
      isCover: res.cover ? 'Y' : 'N',
      isWaterMark: res.waterMark ? 'Y' : 'N',
      photos: [
        {
          imageKey: res.key,
          imageName: res.file.name,
        },
      ],
    })
    if (addRes.data.code === 0) {
      reloadData()
    }
  }

  // 上传 ref
  const onRef = (ref) => {
    setUploadModalRef(ref)
  }
  // 展示上传的modal
  const doShowModalVisible = async () => {
    const res = await _getPhotoNum({ })
    if(res.code==0) {
      if (res.data >= props.infoDetails.websiteNum) {//判定图片数量是否超过上限
        setOverRunVisible(true)
      } else {
        setUploadModalVisible(true)
        uploadModalRef.setShowUploadBtn(true)
        uploadModalRef.setMinStatus(false)
      }
    }
  }
  // 隐藏上传的modal
  const doHiddenModalVisible = () => {
    setUploadModalVisible(false)
    uploadModalRef.setShowUploadBtn(false)
    uploadModalRef.setMinStatus(false)
  }

  const getPhotos = async ({ pagination = {} }) => {
    let res = null
    setLoading(true)
    res = await _getPhotoList({
      ...pagination,
      showcaseType: 'INDEX',
    })

    setLoading(false)
    setHasMore(false)
    if (res.code === 0) {
      const {
        formattedData: { pagination: formattedPagination, list },
      } = res.data
      const newPhotosData = {
        pagination: formattedPagination,
        list:
          formattedPagination.pageNo === 1 || formattedPagination.current === 1
            ? list
            : photosData.list.concat(list),
      }
      setPhotosData(newPhotosData)
      setHasMore(
        formattedPagination.current * formattedPagination.pageSize <
          formattedPagination.total
      )
    } else {
      MyMessage.error(res.msg)
    }
  }

  const loadData = (page) => {
    if (hasMore) {
      getPhotos({
        pagination: { pageNo: page },
      })
    }
  }

  const checkPhotos = async (data) => {
    return await _checkImage({
      showcaseType: 'INDEX',
      imageNames: data.imageNames,
    })
  }

  useEffect(() => {
    getPhotos({})
  }, [])
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      doShowModalVisible()
    },
  }))

  return (
    <>
      <PhotoPage
        // key={loading}
        photosData={photosData}
        loadData={loadData}
        loading={loading}
        hasMore={hasMore}
        getPhotos={getPhotos}
        key={reloading}
        onShow={doShowModalVisible}
        headerTitle="首页"
        _changeSort={_changeSort}
        setPhotosData={setPhotosData}
      />

      <UploadModal
        onRef={onRef}
        visible={uploadModalVisible}
        addPhotos={savePhotos}
        onCancel={doHiddenModalVisible}
        options={{ key: undefined, name: '首页' }}
        _checkPhotos={checkPhotos}
      />
      <OverRunModel
        visible={OverRunVisible}
        htmlTexts='您当前的账户类型网站图片额度已满，若需更多请升级到更高版本，或联系客服。'
        onCancel={() => {
          setOverRunVisible(false)
        }}
      />
    </>
  )
}

export default forwardRef(WebSettings)
