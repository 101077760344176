import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Divider, Popconfirm } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import myMessage from '../Message/index'
import styles from './index.module.less'
import { isEmpty } from 'lodash'

/**
 *
 * @param {array} list- 默认展示的列表 *里面需要有key来区分拖动的列*
 * @param {function} onCreate- 保存的方法
 * @param {string} title- modal头
 */
const CategoryModal = ({ visible, onCreate, onCancel, list = [], title, tip }) => {
  const [form] = Form.useForm()
  const [editingItem, setEditingItem] = useState({})
  const [items, setItems] = useState([])
  useEffect(() => {
    setItems(list)
  }, [list])
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const doCreate = () => {
    onCreate(items)
  }

  const onEdit = (item) => {
    setEditingItem(item)
    form.setFieldsValue({
      editItem: item.name,
    })
  }

  const onDelete = (object) => {
    if (items.length === 1) {
      myMessage.warning('至少得保留一个分类！')
    } else {
      const newItems = items.filter((item) => item !== object)
      setItems(newItems)
    }
  }

  const doChangeEdit = (values) => {
    const index = items.indexOf(editingItem)
    const newItems = items.concat()
    newItems[index] = {
      key: editingItem.key,
      id: editingItem.id,
      name: values.editItem,
    }
    setItems(newItems)
    setEditingItem({})
  }

  const CategoryEditItem = ({ item }) => {
    return (
      <div>
        {editingItem.key === item.key ? (
          <Form onFinish={doChangeEdit} form={form} layout="inline">
            <div className={styles.item}>
              <span className={styles.label}>
                <Form.Item name="editItem">
                  <Input style={{height:30}} maxLength={20} />
                </Form.Item>
              </span>
              <span className={styles.action}>
                <a
                  key={`save_${item.key}`}
                  className="link-btn-default"
                  onClick={() => form.submit()}
                >
                  保存
                </a>
                <Divider type="vertical" />
                <a
                  key={`cancel_${item.key}`}
                  className="link-btn-default"
                  onClick={() => setEditingItem({})}
                >
                  取消
                </a>
              </span>
            </div>
          </Form>
        ) : (
          <div className={styles.item}>
            <span className={styles.label}>
              <span className={styles.itemIcon}>
                <span className="iconfont iconicon_sort"></span>
              </span>
              {item.name}
            </span>
            <span className={styles.action}>
              <Popconfirm title={tip} onConfirm={() => onDelete(item)}>
                <a key={`delete_${item.key}`} className="link-btn-default">
                  删除
                </a>
              </Popconfirm>
              <Divider type="vertical" />
              <a
                key={`edit_${item.key}`}
                className="link-btn-default"
                onClick={() => onEdit(item)}
              >
                编辑
              </a>
            </span>
          </div>
        )}
      </div>
    )
  }

  const Category = ({ category, index }) => {
    return (
      <Draggable draggableId={`draggable${category.key}`} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CategoryEditItem item={category} />
          </div>
        )}
      </Draggable>
    )
  }

  const CategoryList = ({ list }) => {
    return list.map((item, index) => (
      <Category category={item} index={index} key={item.key} />
    ))
  }
  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const categorys = reorder(
      items,
      result.source.index,
      result.destination.index
    )
    setItems(categorys)
  }
  const addItem = () => {
    const newItems = items.concat()
    newItems.unshift({
      key: new Date().valueOf() + '' + items.length,
      id: '',
      name: '未命名的分类',
    })
    setItems(newItems)
  }

  const FooterComponent = () => {
    return (
      <div className={styles.footer}>
        <div>
          <Button type="link" className="link-btn" onClick={addItem} style={{width: 'auto'}}>
            添加
          </Button>
        </div>
        <div>
          <Button
            style={{ border: 'none', boxShadow: 'none' }}
            onClick={onCancel}
          >
            取消
          </Button>
          <Button
            type="primary"
            onClick={doCreate}
            disabled={!isEmpty(editingItem)}
          >
            保存
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Modal
      visible={visible}
      title={title}
      okText="保存"
      cancelText="取消"
      onCancel={onCancel}
      width={600}
      centered
      maskClosable={false}
      footer={<FooterComponent />}
    >
      <div className="Inf_page_text" style={{marginBottom: 30, marginTop: -15}}>可通过拖拉重新排序，或点击左下角添加按钮新增。</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                overflow: 'auto',
                maxHeight: 600,
              }}
            >
              <CategoryList list={items} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  )
}
export default CategoryModal
