//订单详情
import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from "react";
import {Col, Form, Input, Modal, Row, Select, Spin, Table, Button, PageHeader, Layout,Image} from "antd";
import styles from './orderDetails.module.less'
import moment from "moment";
import {useParams, useHistory} from 'react-router-dom'
import {
  getOrderDetails as _getOrderDetails,
  getLogistEnu as _getLogistEnu,
  deliverGoods as _deliverGoods,
  eiditDeliverGoods as _eiditDeliverGoods,
  getOrderAddress as _getOrderAddress,
} from 'reduxModules/store'
import {orderType, payType} from 'constants/Options'
import {getOptionBykeyName} from 'utils/Tools'
import myMessage from 'components/Message'
import Imgs from 'imgs'
import {map} from "lodash/fp";

const {downloadCover} = Imgs

const {Option} = Select
const {Content} = Layout

const OrderDetails = ({name}) => {
  let history = useHistory()
  const [form] = Form.useForm()
  let {orderId} = useParams()
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState({
    addressVo: {},
    orderLogVos: [],
    payVos: []
  })
  const [paymentList, setPaymentList] = useState([
    {
      paymentType: '全款',
      paymentMethod: '微信支付',
      paymentTime: '2018/05/25 12:56',
      paymentStatus: '已支付',
      paymentPrice: '￥269.00',
    }
  ])
  const [productList, setProductList] = useState([
    {
      productName: '精装对裱相册',
      productCover: 'https://thumbs.infotos.cn/1400085036937572354/301cc1d230584bee9a26141fbddcbd91-general',
      price: '￥269.00',
      quantity: '1',
      productTotalPrice: '￥269.00',
    }
  ])
  const [selectedClient, setSelectedClient] = useState({
    pageNo: 1,
    pageSize: 20,
    current: 10,
    totalCount: 20
  })
  const [total, setTotal] = useState(0)

  const [visible, setVisible] = useState(false)
  const [projects, setProjects] = useState([])
  const [logisticCompany, setLogisticCompany] = useState([])
  const [showLogistic,setShowLogistic]= useState(false)
  const logistic = useRef(null);
  const orders = useRef(null);
  const logisticId = useRef(null);
  useEffect(() => {
    getLogistEnu()
  }, [])
  const showVisible = () => {
    let arr=[]
    orders.current.productVos.map(v=>{
      //当没有发货且不是供应商且不为数字下载、服务）
      if(v.deliveryFlag=='no'&&!v.supplierId&&v.typeId!=4&&v.typeId!=7) {
        arr.push(v)
      }
    })
    setProjects(arr)
    logisticId.current=null
    setVisible(true)
  }
  const addDeliver = async (values) => {
    let data={
      orderProductEditForms	:values.orderProductEditForms, //发货商品
      logisticalCompany	:values.logisticalCompany, //物流公司
      logisticalNum	:values.logisticalNum, //物流单号
      orderId	:order.id,//订单id
      orderNum:order.orderNum,// 订单号
      storeId:sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        :null,//商家ID
    }
    let res;
    if(!logisticId.current){
      res = await _deliverGoods(data)
    }else{
      data.id=logisticId.current
      console.log(data)
      res = await _eiditDeliverGoods(data)
    }
    if (res.data.code === '000000') {
      getOrderDetail()
      setVisible(false)
    } else {
      myMessage.error(res.mesg)
    }
    setLoading(false)
  }
  const columns = [
    // {
    //   title: '支付类目',
    //   dataIndex: 'paymentType',
    //   key: 'paymentType',
    // },
    {
      title: '支付方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (_, item) => {
        return (
          <div>
            {
              getOptionBykeyName(
                'value',
                item.payType,
                'label', payType)
            }
          </div>
        )
      }
    },
    {
      title: '支付时间',
      dataIndex: 'payTime',
      key: 'payTime',
      render: (_, item) => {
        return (
          <div>
            {
              item.payTime?.replace(/-/g, '/').replace('T',' ').split('.')[0]
            }
          </div>
        )
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (_, item) => {
        return (
          <div>
            {
              getOptionBykeyName(
                'value',
                item.status,
                'label', orderType)
            }
          </div>
        )
      }
    },
    {
      title: '金额',
      dataIndex: 'paymentPrice',
      key: 'paymentPrice',
      className: 'price4Right',
      render: (_, item) => {
        return (
          <div>
            ￥ {item.payMoney}
          </div>
        )
      }
    }
  ]
  const column = [
    {
      title: '产品与描述',
      dataIndex: 'productName',
      key: 'productName',
      render: (_, item) => {
        return (
          <div className={styles.productName}>
            <img src={item.imageUrl != '../../static/image/download_cover.jpg' ? item.imageUrl : downloadCover}/>
            <div>
              <p className={styles.productName}>{item.productName}</p>
              <p className={styles.productDesc}>{item.optionName}{item.addPageNum?', 新增'+item.addPageNum+'页':''}</p>
              <div className={styles.logisticAddition}>
                {(item.typeId==4||item.typeId==7)?(
                  <span className={styles.noneedLogistic}>非实体商品无需发货</span>):(
                  <div>
                    {!item.supplierId?(
                      <div>
                        {item.deliveryFlag=='no'?(
                          <span className={styles.logisticSelf}>需自行发货</span>
                        ):(
                          <span style={{background:'#9b9b9b'}}>已发货</span>
                        )}
                      </div>
                    ):(
                      <div>
                        {item.deliveryFlag=='no'?(
                          <span>供应商发货</span>
                        ):(
                          <span style={{background:'#9b9b9b'}}>已发货</span>
                        )}
                      </div>
                    )
                    }
                  </div>
                )
                }
              </div>
            </div>
          </div>
        )
      }
    },
    {
      title: '单价',
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      render: (_, item) => {
        return (
          <div>
            ￥{item.salesPrice}
          </div>
        )
      }
    },
    {
      title: '数量',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: '总价',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      className: 'price4Right',
      render: (_, item) => {
        return (
          <div>
            ￥{item.totalPrice}
          </div>
        )
      }
    }
  ]
  const getOrderDetail = async () => {
    setLoading(true)
    const res = await _getOrderDetails({orderId: orderId})
    setLoading(false)
    if (res.code === '000000') {
      // console.log(res.data)
      res.data.orderDate = res.data.orderDate.replace(/-/g, '/').split(':')[0] + ':' + res.data.orderDate.replace(/-/g, '/').split(':')[1]
      res.data.orderLogVos.map(v=>{
        v.createdTime=v.createdTime.replace(/-/g, '/')
      })
      res.data.logisticsInfoVo.map(v=>{
        logistic.current.map(va=>{
          if(v.logisticalCompany==va.id){
            v.logisticalCompanyText=va.name
          }
        })
      })
      let arr=[]
      res.data.productVos.map(v=>{
        //当没有发货且不是供应商且不为数字下载、服务）
        if(v.deliveryFlag=='no'&&!v.supplierId&&v.typeId!=4&&v.typeId!=7) {
          arr.push(v)
        }
      })
      setProjects(arr)
      //状态为已支付且有执行发货商品
      setShowLogistic(arr.length>0&&res.data.status=='paid')
      setOrder(res.data)
      orders.current=res.data
    } else {
      myMessage.error(res.mesg)
    }
  }
  const getLogistEnu = async () => {
    const res = await _getLogistEnu({})
    if (res.data.code === '000000') {
      let arr=[]
      for(let key in res.data.data){
        arr.push({
          id:key,
          name:res.data.data[key]
        })
      }
      setLogisticCompany(arr)
      logistic.current=arr
      getOrderDetail()
    } else {
      myMessage.error(res.mesg)
    }
  }
  const eidit=(val)=>{
    //获取产品id
    let ids=[]
    if(val.productId) {
      val.productId.split(',').map(v => {
        ids.push(Number(v))
      })
    }
    //回显产品id
    let arr=[]
    orders.current.productVos.map(v=>{
      //当没有发货且不是供应商且不为数字下载、服务）
      if(ids.includes(v.id)) {
        arr.push(v)
      }
    })
    setProjects(arr)
    logisticId.current=val.id
    form.setFieldsValue({
      orderProductEditForms: ids,
      logisticalCompany: val.logisticalCompany,
      logisticalNum: val.logisticalNum,
    })
    setVisible(true)
  }
  // 返回
  const goBack = () => {
    history.goBack()
  }
  return (
    <Layout>
      {
        // projects.length != 0 ?(
          showLogistic?(
          <PageHeader
            ghost={false}
            className={styles.titleBar}
            title={
              <div id="orderDetail">
                <Button
                  icon={
                    <span
                      className={`iconfont iconbtn_back`}
                    ></span>
                  }
                  onClick={goBack}
                  className={styles.backBtn}
                />
                订单{order.orderNum}
              </div>
            }
            extra={[
              <Button className={styles.normalBtn}>打印订单</Button>,
              <Button type="primary" onClick={() => {
                // setVisible(true)
                showVisible()
              }}>发货</Button>
            ]}
          ></PageHeader>
          ):(
          <PageHeader
            ghost={false}
            className={styles.titleBar}
            title={
              <div id="orderDetail">
                <Button
                  icon={
                    <span
                      className={`iconfont iconbtn_back`}
                    ></span>
                  }
                  onClick={goBack}
                  className={styles.backBtn}
                />
                订单{order.orderNum}
              </div>
            }
            extra={[
              <Button className={styles.normalBtn}>打印订单</Button>
            ]}
          ></PageHeader>
        )
      }
      <Spin tip="数据加载中" spinning={loading}>
        <Content className={styles.orderPage}>
          <Row className={styles.infos}>
            <Col span={12} className={styles.client}>
              <div className={styles.title}>收货人</div>
              <div>{order.addressVo.customerName}</div>
              <div>{order.addressVo.phoneNum}</div>
              <div>{order.addressVo.district}{order.addressVo.address}</div>
              {order.remarks && (
                <div className={styles.mark}>
                  <div className={styles.title}>订单备注</div>
                  <div>{order.remarks}</div>
                </div>
              )}
            </Col>
            <Col span={12} className={styles.abstract}>
              <div className={styles.title}>订单摘要</div>
              <div>当前状态：{order.statusText}</div>
              <div>创建日期：{order.orderDate}</div>
              <div>订单来源：{order.orderSource}</div>
              <div className={styles.toBuyAccount}>下单账号：{order.customerName}<span className={styles.splitLine}>|</span><span className={styles.sendOrderInfo}>发送订单信息</span></div>
              <div className={styles.logisticsInfoVo}>
                <span>物流信息：</span>
                {order?.logisticsInfoVo?.length>0?(
                  <div>
                    {order.logisticsInfoVo.map(v=>(
                      <div>{v.logisticalCompanyText}（{v.logisticalNum}）
                        {!v.supplierId &&order.status!=='complete'&&
                        <span>|</span>
                        }
                        {!v.supplierId&&order.status!=='complete' &&
                        <span className={styles.edit} onClick={()=>eidit(v)}>更新发货</span>}
                      </div>
                    ))}
                  </div>
                ):(
                  <div>无</div>
                )}
              </div>
            </Col>
          </Row>
          <div className={styles.title}>支付信息</div>
          {order.payVos.length > 0 && (order.status != 'waitPay'&&order.status != 'cancel'&&order.status != 'invalid' )? (
            <Table
              // className={styles.payment}
              // style={{marginBottom: '30px'}}
              dataSource={order.payVos}
              columns={columns}
              pagination={{
                simple: false,
                current: selectedClient.pageNo,
                total: total,
                hideOnSinglePage: true,
                howSizeChanger: false,
                position: ["bottomCenter"],
                pageSize: 20,
              }}
            />
          ) : (
            <div className={styles.noInfo}>暂无相关信息，等待客户支付。</div>
          )}
          <div className={styles.title}>商品信息</div>
          <Table
            // className={styles.project}
            style={{marginBottom: '30px'}}
            dataSource={order.productVos}
            columns={column}
            pagination={{
              simple: false,
              current: selectedClient.pageNo,
              total: total,
              hideOnSinglePage: true,
              howSizeChanger: false,
              position: ["bottomCenter"],
              pageSize: 20,
            }}
          />
          <Row className={styles.priceList}>
            <Col span={6} offset={18}>
              <span>合计</span>
              <span>￥{order.productPrice}</span>
            </Col>
            <Col span={6} offset={18}>
              <span>优惠</span>
              <span>￥{order.reliefPrice}</span>
            </Col>
            <Col span={6} offset={18}>
              <span>运费</span>
              <span>￥{order.freight}</span>
            </Col>
            <Col span={6} offset={18} className={styles.totalPrice}>
              <span>费用合计</span>
              <span>￥{order.totalMoney}</span>
            </Col>
          </Row>
          {/* <div className={styles.blockTitle}>
            <span>商品制作信息</span>
            <span className={`iconfont iconiconOpened ${styles.btnIcon}`}></span>
          </div>
          <div className={styles.makeInfo}>
            <div>
              <img src="https://thumbs.infotos.cn/1400085036937572354/301cc1d230584bee9a26141fbddcbd91-general" alt=""/>
            </div>
            <div>供应商-创意云集 提交了发货（顺丰速运，83048274054785），2018/05/25 12:56</div>
          </div> */}
          <div className={styles.blockTitle}>
            <span>订单记录</span>
            {/* <span className={`iconfont iconiconClosed ${styles.btnIcon}`}></span> */}
          </div>
          <div className={styles.orderRecord}>
            {/*<div>
              <div>供应商-创意云集 提交了发货（顺丰速运，83048274054785），2018/05/25 12:56</div>
              <div className={styles.makeInfo}>
                <img src="https://thumbs.infotos.cn/1400085036937572354/301cc1d230584bee9a26141fbddcbd91-general" alt=""/>
              </div>
            </div>*/}
            {order.orderLogVos.map((item) => (
                <div>
                  {item.describe}，{item.createdTime}
                  <div className={styles.logImgs}>
                    {item.imageUrls?.map((v)=>
                      <Image src={v} alt=""/>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </Content>
      </Spin>
      <Modal
        visible={visible}
        title={`${logisticId.current?'更新发货信息':'添加发货信息'}`}
        okText="确定"
        cancelText="取消"
        centered
        cancelButtonProps={{className: 'footerCanceltext'}}
        onCancel={() => {
          setVisible(false)
        }}
        maskClosable={false}
        width={600}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()
              addDeliver(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info)
            })
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="add_logistic_form"
          requiredMark={false}
          className={styles.createDialog}
          initialValues={{
            logisticCompanyId: '',
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="orderProductEditForms" label="选择商品"
                         rules={[
                           {
                             required: true,
                             message: '请选择发货商品',
                           },
                         ]}
              >
                {logisticId.current?(
                  <Select mode="multiple"
                          disabled
                          placeholder="请选择选择商品"
                          suffixIcon={
                            <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                          }
                  >
                    {/*<Option value="">默认</Option>*/}
                    {projects.map(v => (
                      <Option value={v.id}>{v.productName}</Option>
                    ))}
                  </Select>
                ):(
                  <Select mode="multiple"
                          placeholder="请选择选择商品"
                          suffixIcon={
                            <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                          }
                  >
                    {/*<Option value="">默认</Option>*/}
                    {projects.map(v => (
                      <Option value={v.id}>{v.productName}</Option>
                    ))}
                  </Select>
                )}

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="logisticalCompany" label="运输物流"
                         rules={[
                           {
                             required: true,
                             message: '请选择',
                           },
                         ]}
              >
                <Select
                  placeholder="请选择运输物流名称"
                  suffixIcon={
                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                  }
                >
                  {/*<Option value="">默认</Option>*/}
                  {logisticCompany.map(v => (
                    <Option value={v.id}>{v.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="logisticalNum"
                label="物流单号"
                rules={[
                  {
                    required: true,
                    message: '请输入物流单号',
                  },
                ]}
              >
                <Input placeholder="请输入" maxLength={30}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout>
  )
}
export default forwardRef(OrderDetails)
