import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import MarkstaForm from '../Components/MarkstaForm'

import { saveWeb as _saveWeb } from 'reduxModules/settings'
import message from 'components/Message'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import { PAGE_PATH } from 'apis/Router'
import {Button} from "antd";
import {Link} from "react-router-dom";

const Marksta = (props, ref) => {
  const formRef = useRef()
  // const [infoDetails, setInfoDetails] = useState({})
  // console.log(props.infoDetails.multiUser)
  useEffect(() => {
    // getWebDetails()
  }, [])
//权限
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      // setInfoDetails(res.data)
      // console.log(infoDetails)
    }
  }
  const onSave = async (values) => {
    const res = await _saveWeb(values)
    if (res.data.code === 0) {
      message.success('保存成功')
    } else {
      message.error(res.data.msg)
    }
  }
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then((values) => {
          onSave({ ...props.webData, ...values })
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))

  return (
        <div> {props.infoDetails.watermark == 'Y' ? <MarkstaForm formRef={formRef} webData={props.webData}/>
            :
            (
                <div>
                  <div style={{fontSize: '14px', color: '#4A4A4A', fontWeight: "bold"}}>功能受限</div>
                  <div style={{margin: '10px 0 30px'}}>您当前的账户类型不支持该功能，若需更多功能请升级到更高版本。</div>
                  <Button
                      key='ok'
                      type="primary">
                    <Link
                        to={PAGE_PATH.settings.children.subscribe}
                    >
                      升级订阅
                    </Link>
                  </Button>
                </div>)
        }
        </div>

      )
}

export default forwardRef(Marksta)
