// 添加描述弹窗
import AddDescriptionModal from './AddDescriptionModal/index'
import AddSelect from './AddSelect'
import CategoryModal from './CategoryModal/index'
import CoverUpload from './CoverUpload/index'
import AccUpgradeModal from './AccUpgradeModal/index'
import CheckoutModal from './CheckoutModal/index'
import ShoppingCartModal from './ShoppingCartModal/index'
import AddressModal from './AddressModal/index'
// 悬浮操作
import HoverOpt from './HoverOpt/index'
import ImageCardCell from './ImageCardCell/index'
import message from './Message'
import MyMessage from './Message/index'
// 移动/复制弹窗
import MoveOrCopyModal from './MoveOrCopyModal/index'
// 走马灯预览弹窗
import PreviewCarouselModal from './PreviewCarouselModal/index'
// 商品卡片
import ProductCard from './ProductCard/index'
// 省市区三级联动选择器
import ProvinceSelectCascader from './ProvinceSelectCascader/index'
// 空数据状态
import InfoEmptyData from './InfoEmptyData'
import withTrim, {
  TrimInput,
  TrimInputNumber,
  TrimPswInput,
  TrimSearch,
  TrimTextArea,
} from './WithTrim/index'
// 上传弹出框
import UploadModal from './UploadModal/index'
// 上传弹出框
import PaymentQRcodeModal from './PaymentQRcodeModal/index'

export default {
  AddSelect,
  message,
  ImageCardCell,
  CoverUpload,
  AccUpgradeModal,
  CheckoutModal,
  ShoppingCartModal,
  AddressModal,
  CategoryModal,
  MyMessage,
  withTrim,
  TrimInput,
  TrimPswInput,
  TrimTextArea,
  TrimSearch,
  TrimInputNumber,
  ProductCard,
  ProvinceSelectCascader,
  MoveOrCopyModal,
  AddDescriptionModal,
  PreviewCarouselModal,
  UploadModal,
  HoverOpt,
  InfoEmptyData,
  PaymentQRcodeModal,
}
