import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Layout,
  List,
  Menu,
  PageHeader,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  Typography,
  Radio, Card,
} from 'antd'
import components from 'components'
import CoverUpload from 'components/CoverUpload'
import { DownloadImgSize, ProjectStatus, VisitTypes } from 'constants/Options'
import Imgs from 'imgs'
import { isEmpty } from 'lodash'
import { map } from 'lodash/fp'
import Mock from 'mockjs'
import CommonComponent from 'components/index'
import React, {useEffect, useState} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getSelectOptions } from 'utils/Help'
import { uploadImage } from 'utils/Uploader'
import { EmailValidateRule, PhoneValidateRule } from 'utils/ValidateRulesAndMsg'
import styles from './settingsPage.module.less'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import {Link} from "react-router-dom";
import { PAGE_PATH } from 'apis/Router'
const { Random } = Mock
const { Header, Footer, Sider, Content } = Layout
const { Text } = Typography
const { AddSelect, ProvinceSelectCascader } = components
const { TextArea } = Input
const { Option } = Select
const keys = ['base', 'privacy', 'download', 'collect', 'info']
const activeStyle = { fontWeight: 'bolder', color: '#000' }
const {message, InfoEmptyData} = CommonComponent

const { DefaultCoverPNG } = Imgs
const customizeRenderEmpty = () => <div></div>
const codes = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
// 项目基础
const BaseFormBody = ({
  onAdd,
  categorys,
  showRemove,
  projectDetail,
  coverImg,
  isCoverImg,
  setCoverImg,
  customRequest,
  setStatus
}) => {

  const [isOnline, setIsOnline] = useState(projectDetail.status)
  const changeOnline = (value) => {
    setIsOnline(value)
  }
  const coverPhotoProps = {
    customRequest: customRequest,
    fileList: coverImg,
    accept: '.jpg, .jpeg',
    beforeUpload: (file) => {
      const isJpg = file.type === 'image/jpeg'
      if (!isJpg) {
        message.warning('请上传JPG格式得图片!')
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
      message.warning('封面仅支持1MB以内的图片!')
      }
      return isJpg && isLt2M
    },
    onChange: (info) => {
      setCoverImg(info)
    },
  }
  useEffect(() => {
    if(projectDetail.status!==isOnline){
      setStatus(projectDetail.status)
      setIsOnline(projectDetail.status)
    }
  }, [projectDetail])
  return (
    <div className={styles.baseForm}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="项目名称" name="name">
            <Input maxLength={30}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="分类" name="projectCategoryId">
            <AddSelect options={categorys} onAdd={onAdd} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="拍摄日期" name="shootingDay">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="失效日期" name="loseDay">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="状态">
            <Form.Item name="status" noStyle>
              <Select
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
                onChange={changeOnline}
              >
                {getSelectOptions(ProjectStatus)}
              </Select>
            </Form.Item>
            <div className={styles.desc}>
              说明：隐藏表示仅本地管理，仅项目管理者可见，上线表示发布项目，任何人可通过地址访问查看。
            </div>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="项目地址">
            {isOnline === ProjectStatus[1].key ? (
              <>
                <span className={styles.urlInput}>
                  {projectDetail.projectUrlPrefix}/collection/{projectDetail.projectUri}
                  {/* 高元@210413：注释掉了项目地址编辑功能，待后端功能匹配后再打开 */}
                  {/* <Form.Item name="projectUri" noStyle>
                    <Input bordered={false} className={styles.urlInput} />
                  </Form.Item> */}
                  {/* //下面不是相关 */}
                  {/* <Text keyboard>{projectDetail.projectUri}</Text> */}
                </span>
                <CopyToClipboard text={projectDetail.projectFullUrl}>
                  <Tooltip title="已复制" trigger={['click']}>
                    <Button type="primary" size="small" className="black-btn">
                      复制
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </>
            ) : (
              <span className={styles.notOnline}>还未上线，更改上面状态选项为上线并保存则发布项目</span>
            )}
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={
              <div>
                <div>封面照片</div>
                <div className={styles.desc}>
                  说明：封面照片将会显示在默认落地页以及其他展示项目的页面。点选项目中的图片可设置封面。
                </div>
              </div>
            }
            name="coverPhotoKey"
            className={styles.coverPhotoUpload}
          >
            <CoverUpload
              uploadProps={coverPhotoProps}
              showButtons={{
                uploadButton: (
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    上传
                  </Button>
                ),
                replaceButton: (
                  <Button type="primary" style={{ padding: '4px 40px' }}>
                    替换
                  </Button>
                ),
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Button className="dange-default-btn" onClick={showRemove}>
        删除该项目
      </Button>
    </div>
  )
}
// 隐私设置
const PrivacyFormBody = ({
  form,
  photoCategorys,
  projectDetail,
  changeCategoryIds,
}) => {
  const [visitTypeValue, setVisitTypeValue] = useState(projectDetail.visitType)

  const [allowHidden, setAllowHidden] = useState(projectDetail.isSetPhotoHidden)
  const [show, setShow] = useState(projectDetail.isGuestPieceShow)

  const [share, setShare] = useState(projectDetail.isAllowShare)

  const changeShare = (checked) => {
    setShare(checked)
  }

  const changeAllowHidden = (checked) => {
    setAllowHidden(checked)
  }
  const changeShow = (checked) => {
    setShow(checked)
  }
  const [request, setRequest] = useState(projectDetail.isRequestPhone)
  const changeRequestPhone = (checked) => {
    setRequest(checked)
  }

  const [checkedIds, setCheckedIds] = useState(
    projectDetail.allowVisitorPhotoCategoryIds
  )
  const fillCode = () => {
    form.current.setFieldsValue({
      visitPassword: Random.string(codes, 8),
    })
  }

  const onChangeCheckBox = (e, item) => {
    let newIds = checkedIds.concat()
    if (e.target.checked) {
      newIds.push(item.id)
    } else {
      newIds = newIds.filter((id) => item.id !== id)
    }
    changeCategoryIds(newIds)
    setCheckedIds(newIds)
  }

  const changeVisitType = (value) => {
    setVisitTypeValue(value)
    const defaultVisits = photoCategorys.map((item) => item.id)
    setCheckedIds(defaultVisits)
    changeCategoryIds(defaultVisits)
  }

  return (
    <div className={styles.privacyForm}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="访问设置" name="visitType">
            <Select
              onChange={changeVisitType}
              suffixIcon={
                <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
              }
            >
              {getSelectOptions(VisitTypes)}
            </Select>
          </Form.Item>
        </Col>

        {!visitTypeValue || visitTypeValue === VisitTypes[0].key ? null : (
          <Col span={12}>
            <Form.Item label="访问密码" name="visitPassword">
              <Input
                maxLength={12}
                addonAfter={
                  <a className="link-btn" onClick={fillCode}>
                    生成
                  </a>
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {/* <Divider style={{ paddingTop: 0 }} /> */}
      {visitTypeValue === VisitTypes[0].key ? (
        <Row gutter={16}>
          {/*<Col span={24}>
            <Form.Item
              label="允许访问的分类目录"
              name="allowVisitorPhotoCategoryIds"
            >
              <List
                header={<div></div>}
                footer={<div></div>}
                itemLayout="vertical"
                dataSource={photoCategorys}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      <span className="Inf_page_text">
                        {item.photoCount}张照片
                      </span>
                    }
                  >
                    <Checkbox
                      checked={checkedIds.includes(item.id)}
                      onChange={(e) => onChangeCheckBox(e, item)}
                    ></Checkbox>
                    <span className={styles.checkBoxLabel}>{item.name}</span>
                  </List.Item>
                )}
              />
            </Form.Item>
          </Col>*/}
        </Row>
      ) : null}

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="请求访客的手机号码">
            <Form.Item name="isRequestPhone" noStyle valuePropName="checked">
              <Switch onChange={changeRequestPhone}
                disabled
              />
            </Form.Item>
            <span className={styles.switchLabel}>{request ? '是' : '否'}</span>
            <div className={styles.desc}>
              说明：选择需要则在访客模式与浏览模式下访问时将会请求获取浏览者的手机电话。该功能仅适用于微信分享渠道。
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ paddingTop: 0 }} />

      <Row gutter={16}>
        <Col span={24}>
            <Form.Item label="允许分享">
              <Form.Item noStyle name="isAllowShare" valuePropName="checked">
                <Switch onChange={changeShare} />
              </Form.Item>
              <span className={styles.switchLabel}>{share ? '是' : '否'}</span>
              <div className={styles.desc}>
                说明：选择是则允许分享该项目给其他人。选择开启将使你的作品与品牌尽可能多的进行传播。
              </div>
            </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="网站是否显示该项目">
            <Form.Item name="isGuestPieceShow" noStyle valuePropName="checked">
              <Switch onChange={changeShow} />
            </Form.Item>
            <span className={styles.switchLabel}>
              {show ? '显示' : '不显示'}
            </span>
            <div className={styles.desc}>
              说明：选择显示则该项目将会显示在公开的网站内，任何浏览者均可看到项目入口。
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

// 下载设置
const DownloadFormBody = ({
  form,
  projectDetail,
  photoCategorys,
  changeDownCategoryIds,infoDetails
}) => {
  const [show, setShow] = useState(projectDetail.canDownload)
  const [showPin, setShowPin] = useState(projectDetail.isDownloadCode)
  const [visitorDownload, setVisitorDownload] = useState(projectDetail.isVisitorDownload)
  const [checkedIds, setCheckedIds] = useState(
    projectDetail.canDownloadCategoryIds || []
  )
  useEffect(() => {
    accessControl()
  }, [])
  const accessControl =async()=>{
    // debugger
    const res = await _editEnterpriseLevel();
    if ([0].includes(res.code)) {
      infoDetails=res.data
      //判定下载尺寸条件是否达标
      if (infoDetails.downloadSize !== 0) {
        DownloadImgSize.map(val => {
          if (val.label.indexOf(infoDetails['downloadSize']) == -1) {
            val.disabled = true
            val.label = val.label+' * 当前账户类型不支持'
          }
        })
      }else{
        DownloadImgSize.map(val => {
          val.disabled = false
          val.label = val.label.split('*')[0]
        })
      }
    }
  }
  const fillDownloadCode = () => {
    form.current.setFieldsValue({
      downloadCode: Random.string(codes, 4),
    })
  }
  const changeDownload = (checked) => {
    setShow(checked)
    if (checked) {
      form.current.setFieldsValue({
        isDownloadCode: true,
        downloadSize: DownloadImgSize[0].key,
      })
      const defaultVisits = photoCategorys.map((item) => item.id)
      setCheckedIds(defaultVisits)
      changeDownCategoryIds(defaultVisits)
      setShowPin(true)
      fillDownloadCode()
    }else{
      setShowPin(false)
      form.current.setFieldsValue({
        isDownloadCode: false,
      })
    }
  }

  const changeShowPin = (checked) => {
    setShowPin(checked)
    fillDownloadCode()
  }

  const changeVisitorDownlaod = (checked) => {
    setVisitorDownload(checked)
  }

  const onChangeCheckBox = (e, item) => {
    let newIds = checkedIds.concat()
    if (e.target.checked) {
      newIds.push(item.id)
    } else {
      newIds = newIds.filter((id) => item.id !== id)
    }
    changeDownCategoryIds(newIds)
    setCheckedIds(newIds)
  }
  return (
    <div className={styles.downloadForm}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="允许下载">
            <Form.Item name="canDownload" noStyle valuePropName="checked">
              <Switch onChange={changeDownload} />
            </Form.Item>
            <span className={styles.switchLabel}>{show ? '是' : '否'}</span>
          </Form.Item>
        </Col>
        <div style={{ display: show ? '' : 'none', width: '100%' }}>
          <Col span={24}>
            <Form.Item
              label="可下载文件夹"
              name="canDownloadCategoryIds"
            >
              <List
                header={<div></div>}
                footer={<div></div>}
                itemLayout="vertical"
                dataSource={photoCategorys}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      <span className="Inf_page_text">
                        {item.photoCount}张照片
                      </span>
                    }
                  >
                    <Checkbox
                      checked={checkedIds.includes(item.id)}
                      onChange={(e) => onChangeCheckBox(e, item)}
                    ></Checkbox>
                    <span className={styles.checkBoxLabel}>{item.name}</span>
                  </List.Item>
                )}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="下载尺寸" name="downloadSize">
              <Select
                suffixIcon={
                  <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                }
              >
                {getSelectOptions(DownloadImgSize)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="下载是否需要验证码">
              <Form.Item name="isDownloadCode" noStyle valuePropName="checked">
                <Switch onChange={changeShowPin} />
              </Form.Item>
              <span className={styles.switchLabel}>
                {showPin ? '是' : '否'}
              </span>
            </Form.Item>
          </Col>
          <Col span={12} style={{ display: showPin ? '' : 'none' }}>
            <Form.Item label="验证码" name="downloadCode">
              <Input
                maxLength={8}
                addonAfter={
                  <a className="link-btn" onClick={fillDownloadCode}>
                    生成
                  </a>
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="允许访客下载">
              <Form.Item
                  name="isVisitorDownload"
                  noStyle
                  valuePropName="checked"
              >
                <Switch onChange={changeVisitorDownlaod}/>
              </Form.Item>
              <span className={styles.switchLabel}>{visitorDownload ? '是' : '否'}</span>
              <div className={styles.desc}>
                说明：选择是则允许浏览者在访客模式下载照片。
              </div>
            </Form.Item>
          </Col>
        </div>
      </Row>
    </div>
  )
}

// 收藏设置
const CollectFormBody = ({
  list = [],
  showBookmarkForm,
  editBookmark,
  deleteBookmark,
  projectDetail,
}) => {
  const [show, setShow] = useState(projectDetail.isShowBookmark)
  const [allowMessage, setAllowMessage] = useState(projectDetail.isMessage)
  const removeBookMark = (id) => {
    if (list.length > 1) {
      deleteBookmark(id)
    } else {
      message.warning('至少保留一个收藏夹')
    }
  }

  const getMenu = (record) => (
    <div className={styles.settingsMenu}>
      <Menu>
        <Menu.Item>
          <a
            type="link"
            className="link-btn-default"
            onClick={() => editBookmark(record)}
          >
            编辑
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a
            type="link"
            onClick={() => removeBookMark(record.id)}
            className="link-btn-default"
          >
            删除
          </a>
        </Menu.Item>
      </Menu>
    </div>
  )
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '最大照片数',
      dataIndex: 'maxCountValue',
      key: 'maxCountValue',
    },
    {
      title: '',
      key: 'opt',
      render: (record) => (
        <Dropdown
          overlay={getMenu.bind(this, record)}
          key={`bookmarl${record.id}`}
          trigger={['click']}
          className={styles.setButton}
        >
          <span className="iconfont iconbtn_list_set"></span>
        </Dropdown>
      ),
    },
  ]
  const changeShowBookmark = (checked) => {
    setShow(checked)
  }

  return (
    <div className={styles.collectForm}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="收藏夹及相关功能">
            <Form.Item name="isShowBookmark" noStyle valuePropName="checked">
              <Switch onChange={changeShowBookmark} />
            </Form.Item>
            <span className={styles.switchLabel}>{show ? '显示' : '不显示'}</span>
          </Form.Item>
        </Col>
        <div style={{ display: show ? '' : 'none', width: '100%' }}>
          <Col span={24}>
            <Form.Item label="收藏夹目录">
              <Button
                onClick={showBookmarkForm}
                // icon={<span className="iconfont iconicon_btn_fav"></span>}
                // type="primary"
                // ghost
                className={styles.add_btn}
              >
                添加收藏夹
              </Button>
              <div className={styles.desc}>
                说明：新添加的收藏夹目录仅对客户模式访问显示，其他模式仅显示默认收藏夹。
              </div>
            </Form.Item>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={list}
                pagination={false}
              />
            </ConfigProvider>
          </Col>
        </div>
      </Row>
    </div>
  )
}

// 销售设置
const SalesFormBody = ({
  form,
  projectDetail,
  pricesheetList,
  infoDetails
}) => {
  const [show, setShow] = useState(projectDetail.isShowPrintStore)
  const [movePriceId, setMovePriceId] = useState(false)
  const [placeholderText, setplaceholderText] = useState('请选择价格表')
  useEffect(() => {
    if(projectDetail.priceId&&pricesheetList.length>0){ //判定价格表是否存在
      let showPricesheetList=pricesheetList.find(v=>v.id==projectDetail.priceId)
      if(!showPricesheetList){
        form.current.setFieldsValue({
          priceId: '',
        })
        setMovePriceId(true)
        setplaceholderText('当前价格表已失效请重新选择')
      }
    }
  }, [pricesheetList,infoDetails])
  const changeShowPrintStore = (checked) => {
    let hasPriceId=pricesheetList.find(v=>v.id==projectDetail.priceId)
    if(!hasPriceId && pricesheetList.length>0){//如果不存在或应用价格表已删除则选择默认价格表 且 必须有价格表
      let priceId=pricesheetList.find(v=>v.isDefault=='yes')
      form.current.setFieldsValue({
        priceId:priceId.id
      })
      setMovePriceId(false)
    }
    setShow(checked)
  }
  // const [authorize, setAuthorize] = useState(projectDetail.isAuth2ShipDirectly)
  const [authorize, setAuthorize] = useState(true)
  const changeAuthorize = (checked) => {
    setAuthorize(checked)
  }

  const [allowDesign, setAllowDesign] = useState(false)
  const changeAllowDesign = (checked) => {
    setAllowDesign(checked)
  }
  return (
  <div className={styles.collectForm}>
    {/*不为免费版且展示销售*/}
    {infoDetails.customSales=='Y' && (
      <div>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="开启印坊允许购买产品">
            <Form.Item name="isShowPrintStore" noStyle valuePropName="checked">
              <Switch onChange={changeShowPrintStore} />
            </Form.Item>
            <span className={styles.switchLabel}>{show ? '是' : '否'}</span>
            <div className={styles.desc}>
              说明：开启后将会允许浏览者在交付的画廊浏览购买产品。
            </div>
          </Form.Item>
        </Col>
        <div style={{display: show ? '' : 'none', width: '100%'}}>
          <Col span={24}>
            <Form.Item className={`${movePriceId ? styles.selectedRed : ''}`} label={`${movePriceId ?'应用的价格表已失效请重新选择':'应用的价格表'}`} name="priceId">
              {pricesheetList.length>0?(
                <Select
                  suffixIcon={
                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                  }
                  placeholder="请选择"
                  onChange={(value) => setMovePriceId(false)}
                >
                  {pricesheetList.map(v=>(
                    <Option value={v.id}>{v.name}</Option>
                  ))}
                </Select>
              ):(
                <div className={styles.noPrice}>提示：还没有价格表可供选择。立即去 <Link to={PAGE_PATH.store.children.pricesheet}>添加价格表</Link></div>
              )}
            </Form.Item>
          </Col>
        </div>
      </Row>
      <Divider style={{ marginTop: 10, display: show ? '' : 'none', width: '100%' }} />
      <Row gutter={16}>
        <div style={{display: show ? '' : 'none', width: '100%'}}>
          <Col span={24}>
          <Form.Item label="允许用户购买相册产品时自行排版">
            <Form.Item name="isAllowClientDesign" noStyle valuePropName="checked">
              <Switch onChange={changeAllowDesign} disabled/>
            </Form.Item>
            <span className={styles.switchLabel}>{allowDesign ? '是' : '否'}</span>
            <div className={styles.desc}>
              说明：开启后在客户购买相册类产品时则会允许其先自行排版后再提交购买订单。关闭后客户会直接提交购买，购买后由您来设计排版。
            </div>
          </Form.Item>
          </Col>
        </div>
      </Row>
      <Divider style={{ marginTop: 10, display: show ? '' : 'none', width: '100%' }} />
      <Row gutter={16}>
        <div style={{display: show ? '' : 'none', width: '100%'}}>
          <Col span={24}>
          <Form.Item label="授权制造商将商品直接发送给客户">
            <Form.Item name="isAuth2ShipDirectly" noStyle valuePropName="checked">
              <Switch onChange={changeAuthorize} disabled/>
            </Form.Item>
            <span className={styles.switchLabel}>{authorize ? '是' : '否'}</span>
            <div className={styles.desc}>
              说明：开启后在客户购买商品时会将客户信息授权给制造商，所购商品在完成后将会直接邮寄给客户。选择否客户信息将不会对制造商可见，您在设置中的收货地址会接收完成的商品（会验证并要求完善收货地址）。
            </div>
          </Form.Item>
          </Col>
        </div>
      </Row>
    </div>
    )}
    {/*免费版或不展示销售*/}
    {infoDetails.customSales=='N' && (
      <div>
        <div style={{fontWeight: "bold"}}>您当前的账户类型不支持</div>
        <div style={{margin: '10px 0 30px'}}>项目销售设置允许访问者在交付的画廊浏览购买产品，以增加您的收入。若需该功能请升级到更高版本。</div>
        <Button
          key='ok'
          type="primary">
          <Link
            to={PAGE_PATH.settings.children.subscribe}
          >
            升级订阅
          </Link>
        </Button>
      </div>
    )}
    </div>
  )
}

//界面设置
const ViewForm = ({ projectDetail, form }) => {
  const colorOptions = [{
    value: 'light',
    text: '浅色',
  }, {
    value: 'dark',
    text: '深色',
  }];
  const langOptions = [
    {
      key: 'EN',
      label: 'English',
    },
    {
      key: 'JP',
      label: '日本語'
    },
    {
      key: 'KR',
      label: '한국어'
    },
    {
      key: 'CN',
      label: '简体中文'
    },
    {
      key: 'HK',
      label: '繁體中文（中国香港）'
    },
    {
      key: 'TW',
      label: '繁體中文（中国台湾）'
    }
  ];
  const darkStyle = {
    background: '#000',
  }
  const lightStyle = {
    border: '1px solid #000',
  }
  return (
  <div className={styles.infoFormForm}>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label="界面语言" name="language">
          <Select
             suffixIcon={
              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
            }
            defaultValue={projectDetail.language}
            onChange={(value) => form.current.setFieldsValue({
              language: value,
            })}
          >
            {getSelectOptions(langOptions)}
          </Select>
          <div className={styles.desc}>
            说明：该设置仅对项目交付的Web网页端，微信小程序暂不支持多语言。
          </div>
        </Form.Item>
        <Form.Item
          name="websiteLayoutType"
          label="颜色"
          className={styles.webLayout}
        >
          <Radio.Group buttonStyle="solid" defaultValue="light" disabled>
            {map(item => (<Radio.Button value={item.value} key={item.value}>
              <div className={styles.flexArea}>
                <span className={styles.buttonCheckIcon} style={item.value === 'dark' ? darkStyle : lightStyle}></span>
                {item.text}
              </div>
            </Radio.Button>))(colorOptions)}
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  </div>
  )
};

export default class SettingsPage extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      activeKey: keys[0],
      coverImg:
        isEmpty(props.projectDetail.coverPhotoInfo) ||
        isEmpty(props.projectDetail.coverPhotoInfo.photoUrl)
          ? [
              {
                url: DefaultCoverPNG,
              },
            ]
          : [
              {
                url: props.projectDetail.coverPhotoInfo.photoUrl,
              },
            ],
      isCoverImg:isEmpty(props.projectDetail.coverPhotoInfo) ||
      isEmpty(props.projectDetail.coverPhotoInfo.photoUrl)
          ? false
          : true,
      photoCategoryIds: props.projectDetail
        ? props.projectDetail.allowVisitorPhotoCategoryIds
        : [],
      canDownloadCategoryIds: [],
      status:props.projectDetail.status?props.projectDetail.status:false
    }
  }
  customRequestCoverImg = (info) => {
    const { onProgress, file, onSuccess, onError } = info
    // // 派发请求
    uploadImage({
      file: file,
      errorCallback: async () => {},
      completeCallback: async (res) => {
        const { hash, urlPrefix } = res

        this.formRef.current.setFieldsValue({
          coverPhotoKey: hash,
          // status:projectDetail.status
        })
        this.setState({
          coverImg: [
            {
              url: urlPrefix + hash,
            },
          ],
        })
      },
    })
  }
  changeAcitive = (key) => {
    this.setState({ activeKey: key })
  }

  toCreate = () => {
    let data=this.formRef.current.getFieldValue()
    if(data.isShowPrintStore&&!data.priceId){//判定价格表是否失效且已重新选择
      message.warning('价格表无效，请检查销售设置的选择！')
      return
    }
    this.formRef.current.submit()
  }
  onFinish = (values) => {
    const { photoCategoryIds, canDownloadCategoryIds } = this.state
    // if(this.props.infoDetails.type=='Trail '){
    //   values.isShowPrintStore=this.props.projectDetail.isShowPrintStore
    // }
    this.props.onSave({
      ...values,
      allowVisitorPhotoCategoryIds: photoCategoryIds,
      canDownloadCategoryIds: canDownloadCategoryIds,
    })
  }

  changeCategoryIds = (ids) => {
    // console.log('ids', ids)
    this.setState({ photoCategoryIds: ids })
  }

  changeDownCategoryIds = (ids) => {
    this.setState({ canDownloadCategoryIds: ids })
  }
  setCoverImg = (info) => {
    this.setState({ coverImg: info.fileList.filter((file) => !!file.status) })
  }

  showArea = (formRef) => {
    const { activeKey, coverImg,isCoverImg } = this.state
    const {
      addCategory,
      projectCategorys,
      photoCategorys,
      showRemove,
      showBookmarkForm,
      bookmarks,
      editBookmark,
      deleteBookmark,
      projectDetail,
      infoDetails,
      pricesheetList
    } = this.props

    const setStatus=(status)=>{
      console.log(status)
      this.formRef.current.setFieldsValue({
        status:status,
      })
    }
    let formBody = (
      <div>
        <div
          style={{ width: '80%', display: activeKey === keys[0] ? '' : 'none' }}
        >
          <BaseFormBody
              form={formRef}
            onAdd={addCategory}
            categorys={projectCategorys}
            showRemove={showRemove}
            projectDetail={projectDetail}
            coverImg={coverImg}
            isCoverImg={isCoverImg}
            setCoverImg={this.setCoverImg}
            customRequest={this.customRequestCoverImg}
              setStatus={setStatus}
          />
        </div>
        <div
          style={{ width: '80%', display: activeKey === keys[1] ? '' : 'none' }}
        >
          <PrivacyFormBody
            form={formRef}
            photoCategorys={photoCategorys}
            projectDetail={projectDetail}
            changeCategoryIds={this.changeCategoryIds}
            visitTypeValueChange={this.props.visitTypeValueChange}
          />
        </div>
        <div
          style={{ width: '80%', display: activeKey === keys[2] ? '' : 'none' }}
        >
          <DownloadFormBody
            form={formRef}
            projectDetail={projectDetail}
            photoCategorys={photoCategorys}
            infoDetails={infoDetails}
            changeDownCategoryIds={this.changeDownCategoryIds}
          />
        </div>
        <div
          style={{ width: '80%', display: activeKey === keys[3] ? '' : 'none' }}
        >
          <CollectFormBody
            showBookmarkForm={showBookmarkForm}
            list={bookmarks}
            deleteBookmark={deleteBookmark}
            editBookmark={editBookmark}
            projectDetail={projectDetail}
            form={formRef}
          />
        </div>
        <div
          style={{ width: '80%', display: activeKey === keys[5] ? '' : 'none' }}
        >
          <ViewForm projectDetail={projectDetail} form={formRef} />
        </div>
        <div
          style={{ width: '80%', display: activeKey === keys[4] ? '' : 'none' }}
        >
          <SalesFormBody form={formRef} projectDetail={projectDetail} pricesheetList={pricesheetList} infoDetails={infoDetails}/>
        </div>
      </div>
    )
    return <div>{formBody}</div>
  }


  render() {
    const { name, projectDetail } = this.props
    const { activeKey } = this.state
    const initialValues = projectDetail
    //写死授权制造商将商品直接发送给客户
    projectDetail.isAuth2ShipDirectly=true
    //是否允许客户购买相册产品时自行排版
    projectDetail.isAllowClientDesign=false
    // console.log(projectDetail)
    return (
      <div>
        <Header className="sub-header" style={{ height: 120 }}>
          <PageHeader
            ghost={false}
            title={<div style={{ fontWeight: 'bold' }}>设置</div>}
            style={{
              padding: '24px 50px 23px',
            }}
            extra={[
              <Button type="primary" key="saveSettings" onClick={this.toCreate}>
                保存
              </Button>,
            ]}
          >
            <div>
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[0] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[0])}
              >
                项目基础
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[1] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[1])}
              >
                隐私设置
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[2] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[2])}
              >
                下载设置
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[5] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[5])}
              >
               界面设置
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[3] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[3])}
              >
                收藏设置
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                className="tab-btn"
                style={activeKey === keys[4] ? activeStyle : null}
                onClick={this.changeAcitive.bind(this, keys[4])}
              >
                销售设置
              </Button>
            </div>
          </PageHeader>
        </Header>
        <Content
          className="sub-content"
          style={{ minHeight: 'calc(100vh - 120px)', marginTop: 20 }}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            layout="vertical"
            className={styles.projectSetsForm}
            initialValues={initialValues}
            key={projectDetail.id}
            forceRender={true}
          >
            {this.showArea(this.formRef)}
          </Form>
        </Content>
      </div>
    )
  }
}
