/*
 * @Author: melon
 * @Date: 2020-08-11 16:51:05
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-17 21:20:20
 */
// Servers
import {
  _getMyStorage,
  _addCart,
  _getMyCart,
  _removeCart,
  _removeMyStorage,
  _addShoppingCar,
  _cancelShoppingCar,
  _editShoppingCar,
  _getShoppingCar,
  _addInformation,
  _invalidInformation,
} from 'servers/order'

// Selectors
import {
  StorageListSelector,
  CartListSelector,
  CartAddSelector,
  CartRemoveSelector,
} from 'selectors/order/OrderSelectors'

// Actions

// Action Creators

// init data

const initialState = {
  shoppingCart:0,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return {
        ...state,
        shoppingCart:action.shoppingCart+1,
      }
  }
}

// 异步action

// 获取暂存列表
export const getMyStorage = async (data) => {
  const res = await _getMyStorage(data)
  return new Promise(function (resolve, reject) {
    resolve(StorageListSelector(res))
    reject({})
  })
}

// 获取购物车列表
export const getMyCart = async (data) => {
  const res = await _getMyCart(data)
  return new Promise(function (resolve, reject) {
    resolve(CartListSelector(res))
    reject({})
  })
}

// 加入购物车
export const addCart = (data) => _addCart(CartAddSelector(data))

// 删除购物车
export const removeCart = (data) => _removeCart(CartRemoveSelector(data))

// 删除暂存
export const removeMyStorage = (data) => _removeMyStorage(data)
export const addShoppingCar = (data) => _addShoppingCar(data)
export const cancelShoppingCar = (data) => _cancelShoppingCar(data)
export const editShoppingCar = (data) => _editShoppingCar(data)
export const getShoppingCar = (data) => _getShoppingCar(data)
export const addInformation = (data) => _addInformation(data)
export const invalidInformation = (data) => _invalidInformation(data)
