/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-09-22 11:14:02
 */
import { Button, Spin } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import classNames from 'classnames'
import myMessage from 'components/Message/index'
import Imgs from 'imgs'
import { isEmpty } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { login as _login,addRecord as _addRecord } from 'reduxModules/member'
import { setAccessToken } from 'utils/Request'
import { Decrypt, Encrypt } from 'utils/Secret'
// 登录表单
import LoginForm from '../Components/Form'
import styles from './index.module.less'

const { LogoWT, Wxcode } = Imgs

const Login = () => {
  const history = useHistory()
  const [sendRequesting, setSendRequesting] = useState(false)
  const [formData, setFormData] = useState({})
  const [browsers, setBrowser] = useState('')
  const [clientTypeSimple, setClientTypeSimple] = useState('')
  const [one, setOne] = useState(true)
  const [address, setAddress] = useState({
    country:'',
    province:'',
    city:'',
    district:'',
    township:'',
  })
  const [longLat, setLongLat] = useState('')
  const [PCaddress, setPCaddress] = useState(null)

  const doLogin = async (values) => {
    // 派发登录请求
    try {
      setSendRequesting(true)
      const res = await _login(values)
      if (res.data && [0].includes(res.data.code)) {
        const {
          data: { data: resData },
        } = res
        const { accessToken = undefined } = resData
        let users={...resData,user:values.account}
        // 设置 登录用户信息 数据
        sessionStorage.setItem('user', JSON.stringify(users))
        localStorage.setItem('remember', values.remember ? true : false)
        // 如果勾选记住我 设置登录信息
        if (values.remember) {
          const loginInfo = {
            account: Encrypt(values.account),
            password: Encrypt(values.password),
          }
          localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
        } else {
          localStorage.removeItem('loginInfo')
        }
        // 设置 token 数据
        setAccessToken(accessToken)
        myMessage.success('欢迎')
        let obj=PCaddress?JSON.parse(JSON.stringify(PCaddress)):'未知/海外'
        obj.memberId=users.userId
        obj.account=users.user
        obj.enterpriseId=users.enterpriseId
        obj.status=true
        _addRecord(obj)
      } else {
        myMessage.error(res.data.msg || '登录失败')
      }
      return res
    } catch (error) {
      myMessage.error('系统繁忙')
    } finally {
      setSendRequesting(false)
    }
  }
// 获取地址信息
  const getAddress = async() => {
    let browser=getBrowserInfo()
    setBrowser(browser)
    let clientTypeSimple=''
    if(browser.split('-')[1].indexOf('Windows')<0){
      clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(';')[0]
    }else{
      clientTypeSimple=getExploreName()+', '+browser.split('-')[1].split(' ')[0]
    }
    setClientTypeSimple(clientTypeSimple)
    //获取地理位置与经纬度
    try {
      fetch("https://restapi.amap.com/v3/ip?key=28d085faf15e8975ef1338d723e18a9b").then((res) => {
        if (res.ok) {
          res.text().then(async(data) => {
            const detail = JSON.parse(data)
            if (detail.rectangle.length == 0) {
              let datas={
                browsers:getBrowserInfo(),
                clientTypeSimple:clientTypeSimple,
                ...address
              }
              gotoView('',datas)
              return
            }
            let rectangle = ((parseFloat(detail.rectangle.split(';')[0].split(',')[0]) +
              parseFloat(detail.rectangle.split(';')[1].split(',')[0])) / 2).toFixed(8) + ',' + ((
              parseFloat(detail.rectangle.split(';')[0].split(',')[1]) +
              parseFloat(detail.rectangle.split(';')[1].split(',')[1])) / 2).toFixed(8)
            await setLongLat(rectangle)
            fetch("https://restapi.amap.com/v3/geocode/regeo?output=json&location=" + rectangle + "&key=28d085faf15e8975ef1338d723e18a9b&radius=1000&extensions=all").then((re) => {
              if (re.ok) {
                re.text().then(async(data) => {
                  await setAddress(JSON.parse(data).regeocode.addressComponent)
                  let datas={
                    browsers:getBrowserInfo(),
                    clientTypeSimple:clientTypeSimple,
                    location:JSON.parse(data).regeocode.addressComponent.streetNumber.location,
                    ...JSON.parse(data).regeocode.addressComponent
                  }
                  gotoView('',datas)
                })
              }
            })
          })
        }
      })
    } catch (error) {
      gotoView('','')
    } finally
    {
    }
  }
//获取浏览器及版本号
  const getBrowserInfo=()=>{
    var agent = navigator.userAgent.toLowerCase();
    var regStr_ie = /msie [\d.]+;/gi;
    var regStr_ff = /firefox\/[\d.]+/gi
    var regStr_chrome = /chrome\/[\d.]+/gi;
    var regStr_saf = /safari\/[\d.]+/gi;

    let str = navigator.userAgent;
    let rsg = /\((.*?)\)/;
    let arr = rsg.exec(str);
    let result = "";
    if(arr.length >= 2){
      result = arr[1];
    }else{
      result = str.toLowerCase().indexOf("mac os x") > 0 ? "mac os":"windows"
    }
    //IE
    if (agent.indexOf("msie") > 0) {
      return agent.match(regStr_ie)+'-'+result;
    }
    //firefox
    if (agent.indexOf("firefox") > 0) {
      return agent.match(regStr_ff)+'-'+result;
    }
    //Safari
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
      return agent.match(regStr_saf)+'-'+result;
    }
    //Chrome
    if (agent.indexOf("chrome") > 0) {
      return agent.match(regStr_chrome)+'-'+result;
    }
    //（2）然后获取版本号
    var browser = getBrowserInfo();//返回的是个长度为1的数组，根据自己的需要进行转换
    var verinfo = (browser + "").replace(/[^0-9.]/ig, "");

  }
  const getExploreName=()=>{
    var userAgent = navigator.userAgent;
    if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
      return 'Opera';
    }
    else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
      return 'IE';
    }
    else if(userAgent.indexOf("Edge") > -1){
      return 'Edge';
    }
    else if(userAgent.indexOf("Firefox") > -1){
      return 'Firefox';
    }
    else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
      return 'Safari';
    }
    else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
      return 'Chrome';
    }
    else if(!!window.ActiveXObject || "ActiveXObject" in window){
      return 'IE>=11';
    }
    else{
      return 'Unkonwn';
    }
  }
  const gotoView = async (customerId,adr) => {
    let recordBrowseDTO={
      clientType:browsers?browsers:adr.browsers,
      clientTypeSimple:clientTypeSimple?clientTypeSimple:adr.clientTypeSimple,
      country:address.country?address.country:adr.country,
      provinces:address.province?address.province:adr.province,
      cities:address.city?address.city:adr.city,
      area:adr.district,
      address:address.country?address.country+address.province+address.city:adr.country+adr.province+adr.city,
      latitude:longLat.split(',')[1]?longLat.split(',')[1]:adr.location?adr.location.split(',')[1]:'',
      longitude:longLat.split(',')[0]?longLat.split(',')[0]:adr.location?adr.location.split(',')[0]:'',
      type:'Webpage',
      fullAddress:address.country?address.country+address.province+address.city+address.district+address.township:adr.country+adr.province+adr.city+adr.district+adr.township,
      described:browsers?browsers:adr.browsers,
    }
    console.log(recordBrowseDTO)
    setPCaddress(recordBrowseDTO)
  }
  const goRegister = () => {
    history.push(PAGE_PATH.register)
  }
  useEffect(() => {
    getAddress()
    const remember = localStorage.getItem('remember')
    const loginInfo = localStorage.getItem('loginInfo')
      ? JSON.parse(localStorage.getItem('loginInfo'))
      : {}

    if (['true'].includes(remember) && !isEmpty(loginInfo)) {
      setFormData({
        remember: true,
        account: Decrypt(loginInfo.account),
        password: Decrypt(loginInfo.password),
      })
    } else {
      setFormData({
        remember: false,
        account: undefined,
        password: undefined,
      })
    }
  }, [])

  return (
    <div className={styles.login_page}>
      <div className={classNames(styles.login_show_info_container)}>
        <div className={classNames(styles.left_header)}>
          <p className={classNames(styles.welcome)}>Welcome to</p>
          <div className={styles.brands}>
            <img src={LogoWT} alt=""></img>
          </div>
        </div>
        <div className={classNames(styles.left_content)}>
          <p className={styles.brands_text}>
            开始建立并管理你的业务，为客户提供专业优雅的体验。
          </p>
          {/* <Button onClick={() => goRegister()} className={styles.register_btn}>
            创建账号
          </Button> */}
        </div>
        {/* <div className={styles.left_footer}>
          <img src={Wxcode} className={styles.code_img}></img>
          <span className={styles.follow_text}>
            欢迎关注我们的微信公众号，获取更多资料和优惠。
          </span>
        </div> */}
      </div>

      <div className={classNames(styles.login_form_container)}>
        <Spin
          tip="登录中..."
          spinning={sendRequesting}
          size="large"
          style={{ width: '100%', height: '100%' }}
        >
          <LoginForm
            doSubmit={doLogin}
            history={history}
            formData={formData}
            key={formData.remember}
          />
        </Spin>
      </div>
    </div>
  )
}

export default Login
