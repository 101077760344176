// 购物袋
import {Form, Modal, InputNumber, Input, message, Popconfirm, Tooltip} from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import myMessage from 'components/Message'
import styles from './index.module.less'
import {
  CheckOutlined,
} from '@ant-design/icons';

import {
  getShoppingCar as _getShoppingCar,
  cancelShoppingCar as _cancelShoppingCar,
} from 'reduxModules/order'

const ShoppingCartModal = ({ data, visible, onCreate, onCancel, toCheckouts }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [checkIds, setCheckIds] = useState([])
  const [allPrice, setAllPrice] = useState(0)
  const [list, setList] = useState([
    // {id:0,name:'实木框画',des:'项目名称，5x7”，乌木色',price:'68.00',img:'',number:1},
    // {id:1,name:'相册：三生三世十里桃花 ',des:'项目名称，软皮，方8寸，40页',price:'68.00',img:'',number:1},
  ])
  useEffect(() => {
    gets()
  },[visible])
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  // 选项价格设置
  const setInputValue = (val, e, i, t) => {
    let arr = JSON.parse(JSON.stringify(list))
    arr[i][t] = Number(e)
    setList(arr)
  }
  const priceBlur = (val, e, i, t) => {
    let arr = JSON.parse(JSON.stringify(list))
    let reg = /(^[1-9][0-9]*$)/
    if (reg.test(e)) {
      arr[i][t] = Number(e)
    } else {
      arr[i][t] = 1
      // myMessage.warning('请输入正确的数量')
    }
    setList(arr)
  }
  // 单选
  const checkList=(id)=>{
    let oldIds=JSON.parse(JSON.stringify(checkIds))
    checkIds.includes(id)?oldIds.splice(oldIds.indexOf(id),1):oldIds.push(id)
    setCheckIds(oldIds)
  }
  // 全选
  const checkAll=()=>{
    setCheckIds(list.length!==checkIds.length?list.map(v=>v.id):[])
  }
  //获取购物车
  const gets=async ()=>{
    const res = await _getShoppingCar({})
    if (res.status==200&&res.data.code=='000000') {
      setList(res.data.data)
    } else {
      message.error(res.msg)
    }
  }
  useEffect(() => {
    setPrice()
  }, [checkIds,list])
  //设置总价
  const setPrice=()=>{
    let price=0
    list.map(v=>{
      if(checkIds.includes(v.id)){
        price+=Number(v.number)*Number(v.price)
      }
    })
    setAllPrice(price)
  }
  const deleteCar= async (v)=>{
    const res = await _cancelShoppingCar({ids:[v.id]})
    if (res.status==200&&res.data.code=='000000') {
      gets()
    } else {
      message.error(res.msg)
    }
  }
  //数量变化
  const changeNum=(v,text,i)=>{
    let arr=JSON.parse(JSON.stringify(list))
    if(text=='remove'){
      arr[i].number=arr[i].number==1?1:arr[i].number-1
    }else{
      arr[i].number++
    }
    // let addPrice=v.addPageNum>0>(v.addPageNum*v?.pageSalesPrice)/2
    // arr[i].allPrice = (arr[i].number*(v.price + addPrice)).toFixed(2)
    setList(arr)
  }
  //到购物车
  const getCheckouts=()=>{
    if(checkIds.length==0){
      myMessage.warning('请选择购物袋中的商品')
      return
    }
    /*let order=[]
    list.map(v=>{
      if(checkIds.includes(v.id)) {
        let addPrice = v.addPageNum > 0 > (v.addPageNum * v?.pageSalesPrice) / 2
        order.push({
          extraFreight : v.freightPrice,
          // v.pageSalesPrice=0
          addPageNum:v.addPageNum,//加页数量
          allPrice:(v.number * (v.price + addPrice)).toFixed(2),//商品总价
          describe:v.described,//产品描述
          exportId:v.exportId,//导出ID
          imageId:v.imageId,//图片id
          imageUrl:v.imageUrl,//图片url
          number:v.number,//总数
          optionName:v.optionName,//商品选项名称
          pageControlType:v.pageControlType,//页数控制方式
          pageSalesPrice:v.pageSalesPrice,//加页销售价格
          pageStart:'',//起始页位置
          paperBinding:'',//材质与装订
          paperThickness:'',//纸张厚度
          productId:v.productId,//产品ID
          productName:v.name,//产品名称
          productSpecificationId:v.productSpecificationId,//产品规格ID
          salesPrice:v.price,//销售价格
          serialNumber:v.exportInformationVo.serialNumber,//导出编号
          showSpine:'',//是否显示书脊文字
          supplierId:v.supplierId,//供应商ID
          id:v.id,
          enterpriseId:v.enterpriseId,
          type:'',//类型
        })
      }
    })
    order.map(v=>{
      console.log(v)
      delete v.described
      delete v.exportInformationVo
      delete v.baseResultVO
    })*/
    let order=list.filter(v=>{
      let addPrice=v.addPageNum>0>(v.addPageNum*v?.pageSalesPrice)/2
      v.salesPrice=v.price
      v.productName=v.name
      v.extraFreight=v.freightPrice
      v.serialNumber=v.exportInformationVo.serialNumber
      v.allPrice=(v.number*(v.price + addPrice)).toFixed(2)
      v.projectName=v?.baseResultVO?.name //来源项目
      // v.pageSalesPrice=0
      return checkIds.includes(v.id)
    })
    toCheckouts(order)
    // console.log(order,list,checkIds)
  }
  useEffect(() => {
    // form.setFieldsValue({
    //   ...data,
    // })
  }, [form, data])
  return (
    <Modal
      visible={visible}
      title="购物袋"
      // okText="保存"
      // cancelText="取消"
      footer={null}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={900}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      className={styles.cartDialog}
    >
      <div className={styles.carBody}>
      {list.map((v,i)=>{
        return(
          <div className={styles.cartList}>
            <div onClick={()=>checkList(v.id)} className={`${styles.check} ${checkIds.includes(v.id)?styles.checked:''}`}>
              {checkIds.includes(v.id)&&(<CheckOutlined />)}
            </div>
            <div className={styles.infos}>
              <div className={styles.img}>
                <img src={v.imageUrl} alt=""/>
              </div>
              <div className={styles.info}>
                <div className={styles.name}>{v.name}</div>
                <div className={styles.des}>{v.optionName}</div>
                <div className={styles.des}>{v.baseResultVO.name}</div>
                <Popconfirm
                  title="确定从购物袋移除？"
                  onConfirm={() => deleteCar(v)}
                  okText="确定"
                  cancelText="取消"
                >
                  <div className={styles.delete}>移除</div>
                </Popconfirm>
              </div>
            </div>
            {/* <div className={styles.number}>
              <Input
                className={`${!v.number?styles.inputred:''}`}
                type="text"
                value={v.number}
                onChange={e => {
                  setInputValue(v, e.target.value, i, 'number');
                }}
                onBlur={e => {
                  priceBlur(v, e.target.value, i, 'number');
                }}
              />
            </div> */}
            <div className={styles.pdCount}>
              <span className="iconfont iconiconOpened" onClick={()=>changeNum(v,'remove',i)}></span>
                {v.number}
              <span className="iconfont iconiconClosed" onClick={()=>changeNum(v,'add',i)}></span>
            </div>
            <div className={styles.price}>¥{v.number*v.price}</div>
          </div>
        )
      })}
      {list.length==0&&(
        <div className={styles.nodataInCart}>
          <div className="iconfont iconico_remind"></div>
          <div>当前购物袋是空的</div>
          <div>请至项目内点击购买定制查看商品并购买</div>
        </div>
      )}
      </div>
      {list.length!=0&&(
        <div>
          <div className={`${styles.cartList} ${styles.last}`}>
            <div className={`${styles.check} ${checkIds.length===list.length?styles.checked:''}`} onClick={()=>checkAll()}>
              {checkIds.length===list.length&&(<CheckOutlined />)}
              <span className={styles.all}>全选</span>
            </div>
            <div className={styles.price}>
              <span className={styles.all}>合计</span>
              <span>¥{allPrice}</span>
            </div>
          </div>
          <p className={styles.noFreight}>不含运费</p>
          <div className={styles.newfooter}>
            <div className={styles.submit} onClick={getCheckouts}>结算</div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ShoppingCartModal
