/*
 * @Author: melon
 * @Date: 2020-06-29 11:22:13
 * @Last Modified by: melon
 * @Last Modified time: 2020-07-16 11:19:04
 */
import { Spin } from 'antd'
import commonComponent from 'components/index'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  editEnterpriseInfo as _edit,
  getEnterpriseInfo as _getInfo,
} from 'reduxModules/enterprise'
import BusinessForm from '../Components/BusinessForm'

const { MyMessage } = commonComponent

const Business = (props, ref) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState({})
  const [requesting, setRequesting] = useState(false)

  const getInfo = async () => {
    try {
      setRequesting(true)
      const res = await _getInfo()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setFormData(formattedData)
      }
      return res
    } catch (error) {
      console.log('========>error=====>')
      console.log(error)
    } finally {
      setRequesting(false)
    }
  }
  useEffect(() => {
    getInfo()
  }, [])
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then(async (values) => {
          // formRef.current.resetFields()
          const body = {
            ...values,
            provinceGeoId: values['areaArray'][0], // 省id
            cityGeoId: values['areaArray'][1], // 市id
            areaGeoId: values['areaArray'][2], // 区id
          }
          // 派发修改数据请求
          try {
            const resData = await _edit(body)
            if (resData.data && [0].includes(resData.data.code)) {
              MyMessage.success('保存成功')
              // 派发获取信息数据
              getInfo()
            } else {
              MyMessage.error('保存失败')
            }
          } catch (error) {}
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))

  return (
    <Spin tip="数据加载中" spinning={requesting}>
      <BusinessForm formRef={formRef} formData={formData}/>
    </Spin>
  )
}

export default forwardRef(Business)
