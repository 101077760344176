//订阅升级
import {Form, Modal, InputNumber, Input, Space, Row, Col, Image} from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react';
import Imgs from 'imgs'
import AddressModal from '../AddressModal/index'
import styles from './index.module.less'
import {EmailValidateRule, PhoneRule} from 'utils/ValidateRulesAndMsg'
import {TrimInput, TrimTextArea} from 'components/WithTrim/index'
import {
  CheckOutlined,
} from '@ant-design/icons';
import {
  _getAccountInfo,
} from 'servers/account'
import {
  payCallback as _payCallback,
  upgrade as _upgrade,
  getLevelOrder as _getLevelOrder,
} from 'reduxModules/subscribe'
import ProvinceSelectCascader from "../ProvinceSelectCascader";
// import { PaymentQRcodeModal } from '../PaymentQRcodeModal/index.js'
import myMessage from 'components/Message'
import Api from 'apis/Api.js'
import { useHistory } from 'react-router-dom'
import { PAGE_PATH } from 'apis/Router'
const {payAlipay,payPaypal,payWechat,imgCoupon,imgUpgrade} = Imgs
const AccUpgradeModal = ({ data, visible, onCreate, onCancel,accUpgrade,parentShowPay,parentClosePay }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // const [checkIds, setCheckIds] = useState([])
  // const [allPrice, setAllPrice] = useState(0)
  const [canEdit, setCanEdit] = useState(false)
  const [checkAddress, setCheckAddress] = useState(null)
  const [checkCoupon, setCheckCoupon] = useState(null)
  const [AccUpgrade, setAccUpgrade] = useState([
    // {id:0,name:'Infotos专业版订阅',size:'100G存储',user:'单用户',price:'单价￥78/月',payWay:'需按年付',phone:'18602819355',img:'',month:'12',pricePaid:936.00},
  ])
  const [payWay, setPayWay] = useState([
    {name:'微信支付',img:''},
    // {name:'支付宝支付',img:''},
  ])
  const [pay, setPay] = useState('微信支付')
  const [coupon, setCoupon] = useState([
    // {name:'首单专享60元优惠券',img:'',des:'2021.05.01-2021.06.01 下单满500元享'},
  ])
  const [addressVisible, setAddressVisible] = useState(false)
  const [priceFrom,setPriceFrom]=useState({
    allPrice:0,
    couponPrice:0
  })
  const [payCode,setPayCode]=useState('')
  const history = useHistory()
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  const getCode=(text)=>{
    // let qrCodeImage = new Image();
    // qrCodeImage.src = `data:image/png;base64,${QRCode.toString(text, { type: 'png', size: 100 })}`;
    // return qrCodeImage
  }
  // 选项价格设置
  // const setInputValue = (val, e, i, t) => {
  //   let arr = JSON.parse(JSON.stringify(list))
  //   arr[i][t] = Number(e)
  //   setList(arr)
  // }
  // const priceBlur = (val, e, i, t) => {
  //   let arr = JSON.parse(JSON.stringify(list))
  //   let reg = /(^[1-9][0-9]*$)/
  //   if (reg.test(e)) {
  //     arr[i][t] = Number(e)
  //   } else {
  //     arr[i][t] = 1
  //     // myMessage.warning('请输入正确的数量')
  //   }
  //   setList(arr)
  // }
  // 单选
  // const checkList=(id)=>{
  //   let oldIds=JSON.parse(JSON.stringify(checkIds))
  //   checkIds.includes(id)?oldIds.splice(oldIds.indexOf(id),1):oldIds.push(id)
  //   setCheckIds(oldIds)
  // }
  // 全选
  // const checkAll=()=>{
  //   setCheckIds(list.length!==checkIds.length?list.map(v=>v.id):[])
  // }

  // useEffect(() => {
  //   setPrice()
  // }, [checkIds,list])
  //设置总价
  // const setPrice=()=>{
  //   let price=0
  //   list.map(v=>{
  //     if(checkIds.includes(v.id)){
  //       price+=Number(v.number)*Number(v.price)
  //     }
  //   })
  //   setAllPrice(price)
  // }
  const checkAddressId=(id)=>{
    setCheckAddress(id)
  }
  const checkCouponId=(id)=>{
    setCheckCoupon(id)
  }
  useEffect( () => {
    if(accUpgrade&&accUpgrade.name) {
      getName()
    }
  }, [visible])
  const getName=async()=>{
    console.log(accUpgrade)
    const res = await _getAccountInfo()
    let arr=[{id:accUpgrade?.id,name:accUpgrade.name,size:accUpgrade[1],user:'单用户',price:accUpgrade.price,payWay:accUpgrade.desc,phone:res.data.data.account,img:'',month:'12',pricePaid:accUpgrade.price*12}]
    // console.log(accUpgrade,arr)
    setAccUpgrade(arr)
    setPriceFrom({
      allPrice:accUpgrade.price*12,
      couponPrice:accUpgrade.price*12-accUpgrade.editionPrice
    })
    // form.setFieldsValue({
    //   ...data,
    // })
  }
  const payment=async ()=>{
    let openid = {
        // openid: 'ousZ75EJVQqqJIje1VrezjBJXqkE'
        // openid: JSON.parse(sessionStorage.getItem('user')).userId
        // openid: loginUser.id
        // openid:''
    }
    let json = {
      // appid:'wx0e2aefa2f0045f55',
      appid: 'wx0e8580e0e3dcd330',
      // attach: 'myorders',
      // orderId: v.id,
      payer: openid,
      tradeType: 'NATIVE',
      notifyUrl:`${Api.url}/enterprise/level/unifiedOrder/v3/callback`,
    }

    let data={
        levelId: accUpgrade.id,
        // couponId: accUpgrade.editionType,
        // couponId: '',
        payForm: json,
    }
    // console.log(Api.BACKEND_API_URL,'--')
    // return
    const res = await _upgrade(data)
    if(res.status==200){
      if(res.data.code==0) {
        localStorage.setItem('myOrder', '')
        // setPayCode(res.data.data.payInfo)
        parentShowPay(res.data.data.payInfo)
        // setQRcodeVisible(true)
      }else if(res.data.code==13034){
        myMessage.warning(res.data.msg)
      }else if(res.data.code==13038){//已存在未支付订单
        myMessage.warning('当前存在待处理的订阅订单，请至我的订单查看处理')
      }
    }
  }
  const onAddProject=()=>{}
  const toAddress=()=>{
    setAddressVisible(true)
  }
  const checkPayaway=(item)=>{
    setPay(item.name)
  }
  return (
    <Modal
      visible={visible}
      title="订阅升级"
      okText="保存"
      cancelText="取消"
      footer={null}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={800}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
    >
      <div className={styles.AccUpgrade}>
        <div className={styles.limits}>
          {AccUpgrade.length>0&&AccUpgrade.map(v=>{
            return(
              <div className={styles.acc}>
                <div className={styles.info}>
                  {/*{payCode&&(<QRCode value={payCode} size={100} />)}*/}

                  <div><img src={imgUpgrade} /></div>
                  <div className={styles.infos}>
                    <div className={styles.title}>Infotos{v.name}订阅</div>
                    <div>{v.size} · {v.user} · 单价￥{v.price}/月 · {v.payWay}</div>
                    <div>账号{v.phone}</div>
                  </div>
                </div>
                <div className={styles.month}>{v.month}个月</div>
                <div className={styles.pricePaid}>¥ {v.pricePaid}</div>
              </div>
            )}
          )}
        </div>
        <div className={styles.priceBlock}>
          <div className={styles.coupon}>
            <div className={styles.title}>优惠券</div>
            <div className={styles.convertibility}>
              <Input placeholder="输入兑换码" />
              <div>兑换</div>
            </div>
            <div className={styles.couponlist}>
              {coupon.map(v=>{
                return(
                  <div className={styles.coupons}>
                    <div className={styles.img}><img src={imgCoupon}/></div>
                    <div className={styles.info}>
                      <div className={styles.title}>{v.name}</div>
                      <div className={styles.des}>{v.des}</div>
                    </div>
                    <div onClick={()=>checkCouponId(v.id)} className={`${styles.check} ${checkCoupon===v.id?styles.checked:''}`}>
                      {checkCoupon===v.id&&(<CheckOutlined />)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.bottomPrice}>
            <div className={styles.total}>
              <div className={styles}>合计</div>
              <div className={styles.price}>¥{priceFrom.allPrice}</div>
            </div>
            <div className={styles.discounts}>
              <div className={styles.text}>优惠</div>
              <div className={styles.price}>¥{priceFrom.couponPrice}</div>
            </div>
            <div className={styles.payment}>
              <div className={styles.text}>合计支付</div>
              <div className={styles.price}>¥{(priceFrom.allPrice-priceFrom.couponPrice).toFixed(2)}</div>
            </div>
          </div>
        </div>

        <div className={styles.payWay}>
          <div className={styles.title}>支付方式</div>
          <div className={styles.blockGroup}>
            <div className={styles.payList}>
              {payWay.map(v=>{
                return(
                  <div className={`${v.name==pay?styles.check:''} ${styles.payType}`} onClick={()=>checkPayaway(v)}>
                    <img src={payWechat}/>
                    <div>{v.name}</div>
                  </div>
                )
              })}
            </div>
            <div className={styles.newfooter}>
              <div className={styles.submit} onClick={payment}>提交订单</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default AccUpgradeModal
