import {Button, Card, Layout, Menu, PageHeader} from 'antd'
import {PAGE_PATH} from 'apis/Router'
import message from 'components/Message'
import React, {useEffect, useRef, useState} from 'react'
import {Link, Route, Switch, useLocation, useHistory} from 'react-router-dom'
import {getWeb as _getWeb} from 'reduxModules/settings'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
// import { RouteWithSubRoutes } from 'utils/Help'
import './index.module.less'
import {flow, map, reject} from "lodash/fp";
import styles from "../../Store/Containers/index.module.less";
import classNames from "classnames";
import Img from 'imgs'

const {Header, Footer, Sider, Content} = Layout

const Store = ({routes, name}) => {
  document.title = `${name} - Infotos`
  let location = useLocation()
  let history = useHistory()
  const childRef = useRef()
  const [webData, setWebData] = useState({})
  const [infoDetails, setInfoDetails] = useState({type:null})
  const [isSetting, setIsSetting] = useState(false)
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      console.log(res.data.type)
      setInfoDetails(res.data)
    }
  }
  const getWebData = async () => {
    const res = await _getWeb()
    if (res.code === 0) {
      setWebData(res.data)
    } else {
      message.error(res.msg)
    }
  }
  useEffect(() => {
    getWebDetails()
    getWebData()
  }, [])
  /*const subSiderMenu = routes.map((menu) => (
      <Menu.Item key={menu.path}>
          <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
  ))*/
  const subSiderMenu = flow(
    reject(({page}) => ['storeSetting', 'StoreOrderDetails', 'StoreProductsPage', 'StoreNewProductPage', 'StoreNewProductDownloadPage', 'StoreNewProductPackagePage','StoreEarningsRecordPage','WithdrawRecordPage'].includes(page)),
    map((menu) => (
      <Menu.Item key={menu.path}>
        <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
    ))
  )(routes)
  const onSave = () => {
    childRef.current.onSaveForm()
  }
  const addDeliver = (values) => {
    // childRef.onSaveForm()
    console.log(childRef.current)
  }

  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component
            {...props}
            routes={route.routes}
            ref={childRef}
            webData={webData}
            infoDetails={infoDetails}
            getWebData={getWebData}
            setWebData={setWebData}
          />
        )}
      />
    )
  }

  const changeMenu = (key) => {
    setIsSetting(key.key != '/store/setting')
    getWebData()
  }
  const changeName = async (data) => {
    // console.log(data)
  }
  // 返回
  const goBack = () => {
    history.goBack()
  }
  return (
    <Layout>
      {/*不为免费版且展示销售*/}
      {infoDetails.customSales=='Y' && (
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
          }}
          theme="light"
          className="sub-sidebar"
        >
          <div className="sub-title">{name}</div>
          <Menu
            mode="inline"
            theme="light"
            defaultSelectedKeys={[location.pathname]}
            style={{border: 'none'}}
            className="sub-sider-menu"
            inlineIndent={30}
            onClick={changeMenu}
          >
            {subSiderMenu}
            <Menu.Divider/>
            <Menu.Item key={PAGE_PATH.store.children.setting}>
              <Link to={PAGE_PATH.store.children.setting}>设置</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      )}
      {/*不为免费版且展示销售*/}
      {infoDetails.customSales=='Y' && (
        <Layout style={{marginLeft: 200}}>
          <Content>
            <Switch>
              {routes.map((route, i) => (
                // eslint-disable-next-line no-undef
                <RouteWithSubRoutes key={i} {...route} infoDetails={infoDetails}/>
              ))}
            </Switch>
          </Content>
        </Layout>
      )}
      {/*免费版或不展示销售*/}
      {infoDetails.customSales=='N' && (
        <Layout>
          <PageHeader
            ghost={false}
            className={styles.titleBar}
            title={
              <div id="orderList">
                销售
              </div>
            }
          ></PageHeader>
          <Card bordered={false} className={styles.Trailtext}>
            <div>
              {/* <span className={'iconfont iconbtn_flclose'}></span> */}
              <div className={styles.restricted}><img src={Img.restricted} className={styles.restrictedImg} /></div>
              <div className={styles.dialogTitle}>您当前的账户类型不支持</div>
              <div className={styles.dialogDescription}>销售模块可以让您轻松地管理在线商店（印坊），直接通过交付画廊销售专业的打印/印刷产品或其他自有商品，在线销售照片并赚取更多收入。</div>
              <Button
                key='ok'
                type="primary">
                <Link
                  to={PAGE_PATH.settings.children.subscribe}
                >
                  升级账号
                </Link>
              </Button>
            </div>
          </Card>
        </Layout>
      )}
    </Layout>
  )
}

export default Store
