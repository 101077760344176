import {Button, Divider, Dropdown, Menu, Modal, Spin, Table, Tooltip} from 'antd'
import {
  getOrderSelf as _getOrderSelf,
  payOrder as _payOrder,
} from 'reduxModules/store'
import {
  getLevelOrder as _getLevelOrder,
  cancelLevelOrder as _cancelLevelOrder,
  deleteLevelOrder as _deleteLevelOrder,
  upgradeLevelOrder as _upgradeLevelOrder,
  payCallback as _payCallback,
} from 'reduxModules/subscribe'
import React, { forwardRef, useState, useEffect } from 'react'
import Imgs from 'imgs'
import myMessage from 'components/Message'
import styles from "./orders.module.less";
// import {CopyToClipboard} from "react-copy-to-clipboard";
import {useHistory} from 'react-router-dom'
import commonComponent from 'components/index'
import { getTime,getTimeDate } from 'utils/Tools'
import Api from 'apis/Api.js'
// import axios from 'axios'
const {imgUpgrade} = Imgs
const { MyMessage, InfoEmptyData, ImageCardCell, ShoppingCartModal, CheckoutModal, AccUpgradeModal, AddressModal,PaymentQRcodeModal } = commonComponent
const OrdersPage = (props, ref,infoDetails) => {
  let history = useHistory()
  const [ordersData, setOrdersData] = useState({ list: [], pagination: {total:3,page:1,pageSize:10} })
  // const [adding, setAdding] = useState(false)
  // const [currentId, setCurrentId] = useState(null)
  const [checkItem, setCheckItem] = useState(null)
  const [deleteVisible, setDeleteVisible] = useState(false)
  // const [visibleText, setVisibleText] = useState('删除订单')
  const [shareVisible, setShareVisible] = useState(false)
  const [shoppingCartVisible, setShoppingCartVisible] = useState(false)
  const [CheckoutVisible, setCheckoutVisible] = useState(false)
  const [AccUpgradeVisible, setAccUpgradeVisible] = useState(false)
  // const [addressVisible, setAddressVisible] = useState(false)
  // 是否加载中
  const [loading, setLoading] = useState(false)
  const [orderType, setOrderType] = useState('orderProduct')
  const [orderTypeText, setOrderTypeText] = useState('商品订单')

  const [QRcodeVisible,setQRcodeVisible]=useState(false)//支付二维码弹框
  const [payCode,setPayCode]=useState('')//支付二维码
  // const [payInter,setPayInter]=useState(null)//支付二维码
  const [selectedOrder, setSelectedOrder] = useState({
    pageNum:1,
    pageSize:10
  })
  const close=()=>{
    setShoppingCartVisible(false)
    setCheckoutVisible(false)
    setAccUpgradeVisible(false)
  }
  // const onAddShopCart = async (id) => {
  //   // 派发添加请求
  //   const body = {
  //     id: id,
  //   }
  //   // 派发修改数据请求
  //   return await _addCart(body)
  // }
  // const getList = async ({ pagination = {} }) => {
  //   let res = null

  //   await setLoading(true)

  //   res = await _getList({
  //     pageNo: pagination.current,
  //     pageSize: pagination.pageSize,
  //   })

  //   await setLoading(false)
  //   if (res.code === 0) {
  //     const {
  //       formattedData: { pagination: formattedPagination, list },
  //     } = res.data

  //     const newPhotosData = {
  //       pagination: formattedPagination,
  //       list,
  //     }
  //     setOrdersData(newPhotosData)
  //   } else {
  //     MyMessage.error(res.msg)
  //   }
  // }
  // 删除
  // const onRemove = async (id) => {
  //   // 派发修改数据请求
  //   return await _remove({ id })
  // }
  useEffect(() => {
    // console.log(props,infoDetails)
    if(props.infoDetails.name!='免费版') {
      getProductOrder()
    }else{
      onClick({key:'orderAcc'})
    }
    // getList({})
  }, [infoDetails])
  const handleTableChange = (current,pageSize) => {
    setSelectedOrder({
      pageNum:current,
      pageSize:pageSize
    })
  }
  useEffect(() => {
    if('orderProduct'==orderType){
      getProductOrder()
    }
  }, [selectedOrder])
  // const onDelete = async () => {
  //   const { getList, onRemove, pagination } = this.props
  //   try {
  //     const resData = await onRemove(this.state.checkItem.id)

  //     if (resData.data && [0].includes(resData.data.code)) {
  //       MyMessage.success('删除成功')
  //       // 派发获取列表数据
  //       getList({ pagination })
  //     } else {
  //       MyMessage.error('删除失败')
  //     }
  //   } catch (error) {}
  // }
  // const handleItem = async (type, item) => {
  //   setCheckItem(item)
  //   if(type === '分享'){
  //     setShareVisible(true)
  //   }else if(type === '编辑'){

  //   }else if(type === '删除'){
  //     // myMessage.success('修改成功')
  //     // getListByClient(selectedClient)
  //     setDeleteVisible(true)
  //   }
  // }
  // const onPreview=()=>{}
  // const onEdit=()=>{}
  const clickRow=(row)=>{
    localStorage.setItem('myOrder', JSON.stringify(row))
    if(row.status=='待支付'){
      setShoppingCartVisible(true)
    }else {
      history.push(`/my/order-details/${row.id||row.customerId}`)
    }
  }
  // 商品订单
  const getProductOrder=async()=>{
    if(!props?.infoDetails?.name||props.infoDetails.name=='免费版') {return}
    // console.log(orderTypeText,!props?.infoDetails?.name,'++++++++++++++++')
    setLoading(true)
    // const res = await _getInfoClient({})
    let data={
      pageNum: selectedOrder.pageNum,
      pageSize: selectedOrder.pageSize,
      storeId: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId :null,
    }
    /*if(selectedOrder.research){
      data.research=selectedOrder.research
    }
    if(selectedOrder.status){
      data.status=selectedOrder.status
    }*/
    const res = await _getOrderSelf(data)
    setLoading(false)
    if (res.code === '000000') {
      res.data.records.map(v=>{
        // v.type=v.productVos[0].type=='相册'?3:2//商家下单订单
        v.type=3//商家下单订单
        v.price=v.totalMoney
        let t=v.orderDate?getTimeDate(v.orderDate):''
        v.orderDate=t
        v.supplierId=v.productVos.find(v=>!v.supplierId)
      })
      // console.log(res.data.records)
      setOrdersData({
        list:res.data.records,
        pagination: {
          page:res.data.current,
          pageSize:10,
          // current:res.data.current,
          total:res.data.total
        }
      })
    } else {
      myMessage.error(res.mesg)
    }
  }
  // 账号订阅升级订单
  const getLevelOrder=async()=>{
    const resData = await _getLevelOrder()
    if (resData.data && [0].includes(resData.data.code)) {
      resData.data.data.map(v=>{
        v.statusText=v.isValid=='Y'&&v.status=='N'?'待支付':v.isValid=='Y'&&v.status=='Y'?'已支付':'订单已取消' //待支付isValid=Y&&status=N；已支付isValid=Y&&status=Y；订单已取消isValid=N&&status=N
        v.type=1//权限升级订单
        v.price=v.payMoney
        v.year=v.year||1
        let t=v.createTime?getTime(v.createTime).replace(/-/g,'/'):''
        v.time=t?t.split(':')[0]+':'+t.split(':')[1]:''
      })
      let obj={
        list:resData.data.data,
        pagination: {page: 1, pageSize: 10,total:resData.data.data.length}
      }
      setOrdersData(obj)
    } else {
      // MyMessage.error('删除失败')
    }
  }
  const onClick = async ({key}) => {
    setOrderType(key)
    switch (key) {
      case "orderProduct":
        setOrderTypeText('商品订单')
        getProductOrder()
        /*setOrdersData({ list: [
            {time:'2021/07/08',orderNum:'SA2454885471',name:'Infotos专业版订阅',type:1,year:1,times:'2021/07/08 - 2022/07/08',status:'待支付',price:'680.00'},
            {time:'2016/6/28',orderNum:'39853458794',name:'方8寸真皮跨页相册',type:2,describe:'项目名称，8寸，40页',number:1,status:'商品准备中',price:'320.00',img:''},
            {time:'2016/6/28',orderNum:'39853458794',name:'儿童摄影',type:3,number:1,status:'拍摄已完成',price:'320.00',child:[
                {name:'儿童摄影',describe:'全天拍摄 / 600张底片 / 80张精修全送 / 提供相册 / 服装与化妆请自备',type:1,number:1,price:'12000.00'},
                {name:'12x18” Matted Walnut Frame',describe:'软皮，8寸，40页',type:2,number:1,price:'12000.00',img:''},
              ]},
          ], pagination: {total:3} })*/
        break;
      case "orderAcc":
        try {
          setOrderTypeText('账号订阅订单')
          getLevelOrder()
        } catch (error) {}
        break;
    }
  };
  const payOrder= async (order,way)=>{
    setCheckItem(order)
    let res
    if(way=='pay'){//再次支付订单
      let openid = {
        // openid: 'ousZ75EJVQqqJIje1VrezjBJXqkE'
        // openid: JSON.parse(sessionStorage.getItem('user')).userId
        // openid: loginUser.id
        // openid:''
      }
      let json = {
        // appid:'wx0e2aefa2f0045f55',
        appid: 'wx0e8580e0e3dcd330',
        payer: openid,
        tradeType: 'NATIVE',
        notifyUrl:`${Api.url}/enterprise/level/unifiedOrder/v3/callback`,
      }
      let data=orderTypeText=='账号订阅订单'?{
        orderId:order.id,
        // couponId: accUpgrade.editionType,
        couponId: order.couponId,
        payForm: json,
      }:{
        appid: 'wx0e8580e0e3dcd330',
        // attach: 'myorders',
        orderId: order.id,
        payer: openid,
        tradeType: 'NATIVE',
      }
      res = orderTypeText=='账号订阅订单'?await _upgradeLevelOrder(data):await _payOrder(data)
      if(res.status==200){
        if(res.data.code==0||res.data.code=='000000') {
          // setPayCode(res.data.data.payInfo)
          localStorage.setItem('myOrder', JSON.stringify(order))
          setPayCode(res.data.data.payInfo)
          setQRcodeVisible(true)
          // res = await _payCallback()
          // console.log(res)
          /*axios.post(`${Api.url}/enterprise/level/unifiedOrder/v3/callback`,{})
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.error(error);
            });*/
          // let inter=setInterval(()=>{
          //   console.log()
          // },1000)
          // setPayInter(inter)
          // console.log(res.data.data.payInfo)
        // }else if(res.data.code==13034){
        }else{
          MyMessage.warning(res.data?.msg||res.data?.mesg)
        }
      }
    }
    // if(way=='cancel'){//取消订单
    //   setVisibleText('取消订单')
    //   setDeleteVisible(true)
    //   // res = await _cancelLevelOrder({id:order.id})
    // }
    // if(way=='delete') {//删除订单
    //   setVisibleText('删除订单')
    //   setDeleteVisible(true)
    // }
  }
  // const deleteProduct=async ()=>{
  //   let res
  //   if(visibleText=='取消订单'){
  //     res = await _cancelLevelOrder({id:checkItem.id})
  //   }
  //   if(visibleText=='删除订单'){
  //     res = await _deleteLevelOrder({id:checkItem.id})
  //   }
  //   if(res.status==200&&res.data.code==0){
  //     setDeleteVisible(false)
  //     onClick({key:orderType})
  //   }
  // }
  //跳转到销售订单
  const toOrders=()=>{
    history.push(`/store/order`)
  }
  const menu = (
    <div className={styles.sortMenu}>
      <Menu onClick={onClick}>
        <Menu.Item key="0" disabled className={styles.titleName}>订单类型</Menu.Item>
        {props.infoDetails.name!=='免费版'&&(<Menu.Item key="orderProduct">商品订单</Menu.Item>)}
        <Menu.Item key="orderAcc">账号订阅订单</Menu.Item>
      </Menu>
    </div>
  );

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => (
        <div className={styles.orderList}>
          {item.type==1?(
            <div className={styles.subscription}>
              <div className={styles.orderInfo}>{item.time.split(' ')[0]}<span>订单号：{item.orderNum}</span></div>
              <div className={styles.orderImg}>
                <img src={imgUpgrade} />
                <div className={styles.subInfo}>
                  <div className={styles.name}>{item.name}订阅</div>
                  <div className={styles.subInfo}>{item.year}年{item.serviceTime!=null&&(<span> · 有效期至{getTimeDate(item.serviceTime)}</span>)}</div>
                </div>
              </div>
            </div>
          ):item.type==2?(
            <div className={styles.product}>
              <div className={styles.orderInfo}>{item.time}<span>订单号：{item.orderNum}</span></div>
              <div className={styles.pdImage}>
                <img  />
                <div className={styles.subInfo}>
                  <div className={styles.name}>{item.name}</div>
                  <div>{item.describe}</div>
                  <div>×{item.number}</div>
                </div>
              </div>
            </div>
          ):(
            <div className={styles.service}>
              <div className={styles.orderInfo}>{item.orderDate}<span>订单号：{item.orderNum}</span></div>
              <div className={styles.child}>
                {item.productVos.map(v=>{
                  return(
                    <div>
                      {/*相册*/}
                      {v.typeId||!v.typeId?(
                        <div className={styles.subInfo}>
                          <div className={styles.pdImage}>
                            <img src={v.imageUrl}/>
                            <div>
                              <div className={styles.name}>{v.productName}</div>
                              <div>{v.optionName}</div>
                              <div>×{v.number}</div>
                              <div>{v.projectName}</div>
                            </div>
                          </div>
                        </div>
                        ):(
                        <div className={styles.subInfo}>
                          <div className={styles.name}>{v.name}</div>
                          <div>{v.describe}</div>
                          <div>数量 ×{v.number}</div>
                          <div>价格 ¥{v.price}</div>
                        </div>
                        )}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (_, item) => (
        <div>
          <div className={styles.priceBlock}>Price</div>
          <div className={styles.orderPrice}>合计 ¥{item.price}</div>
            {item?.supplierId&&item.type!=1&&(
              <span className={styles.logisticSelf} onClick={()=>toOrders()}>自有商品</span>
            )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'opt',
      key: 'opt',
      width: 100,
      render: (_, item) => (
        <div>
          <div className={styles.status}>{item.statusText}</div>
          <div className={styles.actions}>
            {(item.status=='waitPay'||item.status=='N'&&item.isValid=='Y')&&(<Button className={styles.btnPay} onClick={()=>payOrder(item,'pay')}>{'支付'}</Button>)}
            {/* {item.status=='N'&&item.isValid=='Y'&&(<Button onClick={()=>payOrder(item,'cancel')}>取消订单</Button>)} */}
            {/* {item.status=='N'&&item.isValid=='N'&&(<Button onClick={()=>payOrder(item,'delete')}>删除订单</Button>)} */}
            <Button onClick={()=>clickRow(item)}>{'订单详情'}</Button>
          </div>
        </div>
      ),
    },
  ]
  return (
    <Spin tip="数据加载中" spinning={loading}>
      <div className={styles.myOrder}>
        <div className={styles.controlBar}>
          {ordersData.pagination.total}条
          <Divider type="vertical"/>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link" className="link-btn">
              {orderTypeText}
              <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
            </Button>
          </Dropdown>
        </div>
        {ordersData.list.length > 0 && (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={ordersData.list}
            showHeader={false}
            pagination={{
              ...ordersData.pagination,
              showSizeChanger: false,
              className: styles.pagerPositon,
              onChange:(current,pageSize)=>{
                handleTableChange(current,pageSize)
              }
            }}
          />
        )}
      </div>
      {ordersData.list.length <= 0 && !loading && (
        <InfoEmptyData description="暂无订单信息" />
      )}
      {/*<ShoppingCartModal*/}
      {/*  onCancel={()=>close()}*/}
      {/*  visible={shoppingCartVisible}*/}
      {/*/>*/}
      <CheckoutModal
        onCancel={()=>close()}
        visible={CheckoutVisible}
      />
      <AccUpgradeModal
        onCancel={()=>close()}
        visible={AccUpgradeVisible}
      />
      {/*//支付弹框*/}
      <PaymentQRcodeModal visible={QRcodeVisible} wxCode={payCode} onCancel={()=>setQRcodeVisible(false)}></PaymentQRcodeModal>
      {/* <Modal
        title={visibleText}
        visible={deleteVisible}
        onOk={deleteProduct}
        onCancel={() => setDeleteVisible(false)}
        okText="确定"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">确定要继续{visibleText=='取消订单'?'取消订单':'删除订单'}？</div>
      </Modal> */}
     {/* <AddressModal
          visible={addressVisible}
          // onCreate={onAddProject}
          onCancel={() => {
            setAddressVisible(false)
          }}
          // preset={accountData.list}
          // projectCategorys={projectCategorys}
          // onAdd={onAddProjectCategory}
        />*/}
      {/*<ShoppingCartModal
        visible={shoppingCartVisible}
        onCancel={this.hideAddDescriptionModalVisible}
        data={{}}
        onCreate={this.doAddDescription}
      />*/}
    </Spin>
  )
}

export default forwardRef(OrdersPage)
