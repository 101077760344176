/*
 * @Author: melon
 * @Date: 2020-07-02 16:20:31
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-14 15:14:36
 */

import { Spin } from 'antd'
import commonComponent from 'components/index'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import {
  getSubscribeDetail as _getDetail,
  getSubscribeInfo as _getInfo,
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import SubscribeForm from '../Components/SubscribeForm'

const { MyMessage } = commonComponent
const Subscribe = (props, ref) => {
  const formRef = useRef()
  const {changeName,webData,infoDetails} = props
  const [formData, setFormData] = useState({})
  const [formDataD, setFormDataD] = useState({})
  const [requesting, setRequesting] = useState(false)
  const getInfo = async () => {
    try {
      setRequesting(true)
      const res = await _getInfo()
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setFormData(formattedData)
      }
      // const ress = await _editEnterpriseLevel()
      //   console.log(ress,'+++')
      // if ([0].includes(ress.code)) {
      //   setFormDataD(ress.data)
      // }
      return res
    } catch (error) {
      MyMessage.error('系统繁忙')
    } finally {
      setRequesting(false)
    }
  }
  const getDetail = async ({ editionType }) => {
    return await _getDetail({ editionType })
  }
  const details = async(data) => {
    // console.log(changeName)
    // changeName(data)
  }
  useEffect(() => {
    getInfo()
  }, [])
  useEffect(() => {
    // console.log(localStorage.getItem('isDetails'),'++++')
  }, [localStorage.getItem('isDetails')])
  return (
    <Spin tip="数据加载中" spinning={requesting}>
      <SubscribeForm
        formRef={formRef}
        formData={formData}
        // formDataD={formDataD}
        getDetail={getDetail}
        getInfo={getInfo}
        pfn={details}
        infoDetails={infoDetails}
      />
    </Spin>
  )
}

export default forwardRef(Subscribe)
