/*
 * @Author: melon
 * @Date: 2020-07-04 23:17:14
 * @Last Modified by:   melon
 * @Last Modified time: 2020-07-04 23:17:14
 */
// Servers
import {
  _login,
  _checkPhone,
  _register,
  _forgetPsw,
  _getVerifyCode,
  _addRecord,
  _getRecord,
} from 'servers/member'

// Selectors
import { LoginSelector } from 'selectors/Login/LoginSelectors'
import { ForgetPswSelector } from 'selectors/ForgetPsw/ForgetPswSelectors'
import {
  RegisterSelector,
  VerifyCodeSelector,
} from 'selectors/Register/RegisterSelectors'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const login = (data) => _login(LoginSelector(data))

export const checkPhone = (data) => _checkPhone(data)

export const getVerifyCode = (data) => _getVerifyCode(VerifyCodeSelector(data))

export const register = (data) => _register(RegisterSelector(data))

export const forgetPsw = (data) => _forgetPsw(ForgetPswSelector(data))

export const addRecord = (data) => _addRecord(data)
export const getRecord = (data) => _getRecord(data)
