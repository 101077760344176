import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import SEOForm from '../Components/SEOForm'

import { saveWeb as _saveWeb } from 'reduxModules/settings'
import message from 'components/Message'
import BrandForm from "../Components/BrandForm";
const SEO = (props, ref) => {
  const formRef = useRef()

  const onSave = async (values) => {
    const res = await _saveWeb(values)
    if (res.data.code === 0) {
      message.success('保存成功')
    } else {
      message.error(res.data.msg)
    }
  }
  useImperativeHandle(ref, () => ({
    // onSaveForm 就是暴露给父组件的方法
    onSaveForm: () => {
      formRef.current
        .validateFields()
        .then((values) => {
          // formRef.current.resetFields()
          onSave({ ...props.webData, ...values })
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    },
  }))

  return <SEOForm formRef={formRef} webData={props.webData} infoDetails={props.infoDetails} />
}

export default forwardRef(SEO)
