// Servers
// Selectors
import {
  ShowcasePhotoAddDescriptionSelector,
  ShowcasePhotoAddSelector,
  ShowcasePhotoChangeSortSelector,
  ShowcasePhotoCopyToIndexSelector,
  ShowcasePhotoDownloadUrlSelector,
  ShowcasePhotoListSelector,
  ShowcasePhotoPreviewUrlSelector,
} from 'selectors/showcasePhoto/ShowcasePhotoSelectors'
import {
  _addDescription,
  _addShowcasePhoto,
  _changeSort,
  _checkImage,
  _copyToIndex,
  _getShowcasePhotoDownloadUrl,
  _getShowcasePhotoListForPage,
  _getShowcasePhotoPreviewUrl,
  _moveOrCopy,
  _remove,
  _getPhotoNum,
} from 'servers/showcasePhoto'
// Actions

// Action Creators

// init data

const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
export const getShowcasePhotoListForPage = async (data) => {
  const res = await _getShowcasePhotoListForPage(data)
  return new Promise(function (resolve, reject) {
    resolve(ShowcasePhotoListSelector(res))
    reject({})
  })
}

export const getShowcasePhotoPreviewUrl = async (data) => {
  const res = await _getShowcasePhotoPreviewUrl(data)
  return new Promise(function (resolve, reject) {
    resolve(ShowcasePhotoPreviewUrlSelector(res))
    reject({})
  })
}

export const getPhotoNum = async (data) => {
  const res = await _getPhotoNum(data)
  return new Promise(function (resolve, reject) {
    resolve(res?.data)
    reject({})
  })
}

export const addDescription = (data) =>
  _addDescription(ShowcasePhotoAddDescriptionSelector(data))

export const addShowcasePhoto = (data) =>
  _addShowcasePhoto(ShowcasePhotoAddSelector(data))

export const changeSort = (data) =>
  _changeSort(ShowcasePhotoChangeSortSelector(data))

export const copyToIndex = (data) =>
  _copyToIndex(ShowcasePhotoCopyToIndexSelector(data))

export const moveOrCopy = (data) =>
  _moveOrCopy(ShowcasePhotoCopyToIndexSelector(data))

export const remove = (data) => _remove(data)

export const getShowcasePhotoDownloadUrl = async (data) => {
  const res = await _getShowcasePhotoDownloadUrl(data)
  return new Promise(function (resolve, reject) {
    resolve(ShowcasePhotoDownloadUrlSelector(res))
    reject({})
  })
}

// 检查照片
export const checkImage = (data) => _checkImage(data)
