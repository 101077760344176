import { Button, Layout, Menu, PageHeader } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import CommonComponents from 'components'
import { first, flow, isEmpty, map, prop, reject } from 'lodash/fp'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import {
  batchEdit as _savePhotoCategory,
  getShowcaseCategory as _getCategories,
} from 'reduxModules/showcaseCategory'
import { getShowcasePhotoPreviewUrl as _getPreviewUrl } from 'reduxModules/showcasePhoto'
import {
  getAboutUrl as _getAboutUrl,
  getContactUrl as _getContactUrl,
} from 'reduxModules/showcaseSetting'
import {
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import styles from './index.module.less'
import Img from 'imgs'

const { MyMessage, CategoryModal, UploadModal } = CommonComponents

const { Header, Sider, Content } = Layout

const WebPage = ({ routes, name }) => {
  document.title = `${name} - Infotos`
  let location = useLocation()
  const isWorks = [PAGE_PATH.web.children.works].includes(location.pathname)
  const childRef = useRef()
  // 分类弹窗显示与否
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  //  分类loading状态
  const [loadingCategory, setLoadingCategory] = useState(false)
  // 被选中的 CategoryId
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  // 被选中的 Category
  const [selectedCategory, setSelectedCategory] = useState({})
  // 照片分类数组
  const [photoCategories, setPhotoCategories] = useState([])
  const [infoDetails, setInfoDetails] = useState({seo:null})
  useEffect(() => {
    getWebDetails()
  }, [])
  const getWebDetails = async () => {
    const res = await _editEnterpriseLevel()
    if ([0].includes(res.code)) {
      console.log(res.data.type)
      setInfoDetails(res.data)
    }
  }
  const subSiderMenu = flow(
    reject(({ page }) => ['webSetting'].includes(page)),
    map((menu) => (
      <Menu.Item key={menu.path}>
        <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
    ))
  )(routes)

  const CategoryMenu = flow(
    map((item) => <Menu.Item key={item.id}>{item.name}</Menu.Item>)
  )(photoCategories)
  const onSave = () => {
    childRef.current.onSaveForm()
  }

  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component
            {...props}
            routes={route.routes}
            ref={childRef}
            categoryId={selectedCategoryId}
            selectedCategory={selectedCategory}
            infoDetails={infoDetails}
          />
        )}
      />
    )
  }
  const goToPreview = async () => {
    let res = {
      code: null,
    }

    switch (location.pathname) {
      case PAGE_PATH.web.children.index:
        res = await _getPreviewUrl({
          showcaseType: 'INDEX',
        })
        break
      case PAGE_PATH.web.children.works:
        res = await _getPreviewUrl({
          showcaseType: 'WORKS',
        })
        break
      case PAGE_PATH.web.children.about:
        res = await _getAboutUrl()
        break
      case PAGE_PATH.web.children.contact:
        res = await _getContactUrl()
        break
      default:
        res = await _getPreviewUrl({
          showcaseType: 'INDEX',
        })
        break
    }

    if (res.code === 0) {
      const { formattedData } = res.data
      const w = window.open('about:blank')
      w.location.href = formattedData.url
    }
  }
  const changeMenu = async ({ item, key, keyPath, domEvent }) => {
    if (key.indexOf('/') < 0 && isWorks) {
      await setSelectedCategoryId(key)
      const targetCategory = photoCategories.find(({ id }) => id == key)
      setSelectedCategory(targetCategory)
    } else {
      setSelectedCategoryId('')
      setSelectedCategory({})
    }
  }
  const doSaveCategory = async (values) => {
    const res = await _savePhotoCategory({
      categoryList: values,
      showcaseType: isWorks ? 'WORKS' : 'INDEX',
    })
    setCategoryModalVisible(false)
    if (res.data.code === 0) {
      MyMessage.success('提交成功')
      getCategories()
    } else {
      MyMessage.error('请求失败')
    }
  }
  // 获取图片分类
  const getCategories = useCallback(async () => {
    try {
      const res = await _getCategories({ showcaseType: 'WORKS' })
      if ([0].includes(res.code)) {
        const {
          data: {
            formattedData: { list = [] },
          },
        } = res

        const firstItem = isEmpty(list) ? '' : flow(first)(list)

        const firstId = isEmpty(list) ? '' : flow(first, prop('id'))(list)
        await setSelectedCategoryId(firstId + '')
        await setPhotoCategories(list)
        setSelectedCategory(firstItem)
      }
      return res
    } catch (error) {
    } finally {
    }
  })

  useEffect(() => {
    console.log(isWorks)
    if (isWorks) {
      getCategories()
    }
  }, [isWorks])

  return (
    <Layout>
      {/*是否展示网站*/}
      {/*{infoDetails.seo=='Y'&&(<>*/}
        <Sider
          style={{
            overflowX: 'hidden',
            height: '100vh',
            position: 'fixed',
          }}
          theme="light"
          className="sub-sidebar"
        >
          <div className="sub-title">{name}</div>
          <Menu
            mode="inline"
            theme="light"
            style={{ border: 'none', maxHeight: '100%' }}
            onClick={changeMenu}
            inlineIndent={30}
            className="sub-sider-menu"
            defaultSelectedKeys={['all']}
          >
            {subSiderMenu}
            <Menu.Divider />
            <Menu.Item
              key={'/web/setting'}
              // style={{ margin: '20px 0', display: 'inline-block' }}
            >
              <Link replace to={PAGE_PATH.web.children.setting}>
                设置
              </Link>
            </Menu.Item>
            {/*如果是作品的话显示作品的分类*/}
            {isWorks && <Menu.Divider />}
            {isWorks && (
              <Menu.Item disabled key="works" className="Inf_page_text">
                作品分类
              </Menu.Item>
            )}
            {isWorks && CategoryMenu}
            {isWorks && (
              <Menu.Item disabled>
                <Button
                  type="link"
                  onClick={() => {
                    setCategoryModalVisible(true)
                  }}
                  className="link-btn"
                >
                  管理分类
                </Button>
              </Menu.Item>
            )}
            {isWorks && <Menu.Divider />}
          </Menu>
          <div style={{ height: 60 }}></div>
        </Sider>

        {/*底部操作*/}
        <div className={styles.subSiderMenuFooter}>
          <Button className="Inf_default_btn" onClick={goToPreview}>
            预览
          </Button>
        </div>
        <Layout style={{ marginLeft: 200, backgroundColor: '#ffffff' }}>
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={route.path}
                path={route.path}
                render={() => {
                  const name = route.name

                  return !['home', 'works'].includes(route.page) ? (
                    <>
                      <Header className="sub-header">
                        <PageHeader
                          ghost={false}
                          title={
                            <div style={{ fontWeight: 'bold' }}>
                              {isWorks && selectedCategory
                                ? selectedCategory.name
                                : name}
                            </div>
                          }
                          style={{
                            padding: '24px 50px 23px',
                          }}
                          extra={[
                            <Button
                              key={'onSave' + i}
                              type="primary"
                              onClick={onSave.bind(this, route.page)}
                            >
                              保存
                            </Button>,
                          ]}
                        ></PageHeader>
                      </Header>
                      <Content className="sub-content">
                        <Switch>
                          {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                          ))}
                        </Switch>
                      </Content>
                    </>
                  ) : (
                    <Switch>
                      {routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                      ))}
                    </Switch>
                  )
                }}
              />
            ))}
          </Switch>
        </Layout>
        {/*</>)}*/}
      {/*{infoDetails.seo=='N'&&(<>
          <div className={styles.Trailtext}>
            <div className={styles.restricted}><img src={Img.restricted} className={styles.restrictedImg} /></div>
            <div className={styles.dialogTitle}>您当前的账户类型不支持</div>
            <div className={styles.dialogDescription}>客户模块可以让您查看客户信息。</div>
            <Button
              key='ok'
              type="primary">
              <Link
                to={PAGE_PATH.settings.children.subscribe}
              >
                升级账号
              </Link>
            </Button>
          </div>
        </>)}*/}
        <CategoryModal
          visible={categoryModalVisible}
          onCreate={doSaveCategory}
          onCancel={() => {
            setCategoryModalVisible(false)
          }}
          key={loadingCategory}
          list={photoCategories}
          title={'作品分类管理'}
          tip={'该分类下的图片将会被移除且不可恢复，确定继续?'}
        />
      </Layout>
  )
}

export default WebPage
