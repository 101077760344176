/*
 * @Author: melon
 * @Date: 2020-07-05 22:49:20
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-13 21:26:20
 */

import request from 'utils/Request'

import Api from 'apis/Api.js'

// 获取订阅

export const _getSubscribeInfo = async () => {
  return await request({
    method: 'get',
    url: Api.subscribe.subscribe,
    params: {},
  })
}

// 获取订阅详情
/**
 *
 * @param {*} param0
 * editionType 订阅类型

FREE:免费版, BASIC:基础版
 */
export const _getSubscribeDetail = async ({ editionType = 'FREE' }) => {
  return await request({
    method: 'get',
    url: Api.subscribe.getSubscribeDetail,
    params: { editionType },
  })
}
//统一下单回调接口
export const _payCallback = async (data) => {
  return await request({
    method: 'post',
    url: Api.subscribe.payCallback,
    data
  })
}
//升级订单取消
export const _cancelLevelOrder = async (data) => {
  return await request({
    method: 'post',
    url: Api.subscribe.cancelLevelOrder,
    data
  })
}
//升级订单删除
export const _deleteLevelOrder = async (data) => {
  return await request({
    method: 'post',
    url: Api.subscribe.deleteLevelOrder,
    data
  })
}
//账号升级重新支付
export const _upgradeLevelOrder = async (data) => {
  return await request({
    method: 'post',
    url: Api.subscribe.upgradeLevelOrder,
    data
  })
}
//账号升级
export const _upgrade = async (data) => {
  return await request({
    method: 'post',
    url: Api.subscribe.upgrade,
    data
  })
}
//查询账号升级订单信息
export const _getLevelOrder = async (params) => {
  return await request({
    method: 'get',
    url: Api.subscribe.getLevelOrder,
    params: params
  })
}
