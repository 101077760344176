/*
 * @Author: melon
 * @Date: 2020-07-01 21:40:43
 * @Last Modified by: melon
 * @Last Modified time: 2020-08-17 19:38:28
 */

import {Button, Form, InputNumber, Modal, Progress, Row, Col, Layout, Image, Menu, Dropdown} from 'antd'
import { CheckOutlined, MinusOutlined,SettingOutlined} from '@ant-design/icons';
import { VersionsTypes } from 'constants/Options'
import {
  getProjectsByCategory as _getProjectsByCategory,
  removeProject as _removeProject,
} from 'reduxModules/customerPhoto'
import {
  getPhotoNum as _getPhotoNum,
} from 'reduxModules/showcasePhoto'
import {
  getSubscribeDetail as _getDetail,
  getSubscribeInfo as _getInfo,
  editEnterpriseLevel as _editEnterpriseLevel,
} from 'reduxModules/subscribe'
import {
  getEnterpriseLevel as _getEnterpriseLevel,
} from 'reduxModules/store'
import { PAGE_PATH } from 'apis/Router'
import components from 'components'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import styles from './SubscribeForm.module.less'
import {dimValueGetter} from "echarts/src/component/marker/markerHelper";
import {Link} from "react-router-dom";
import { getFullRoute } from 'utils/Tools'
import CommonComponent from 'components/index'
import RemoveProjectForm from "../../CustomerPhoto/Components/RemoveProjectForm";
import Imgs from 'imgs'
import {isEmpty} from "lodash";

const {imgWebsite} = Imgs
const { AccUpgradeModal, PaymentQRcodeModal } = CommonComponent
const layout = {
  wrapperCol: { span: 16 },
}
const { message} = components

const UpgradeModal = ({ visible, modalData, onCreate, onCancel }) => {
  const [form] = Form.useForm()
  // 保存状态
  const [saving, setSaving] = useState(false)
  // 弹窗类型
  const [modalType, setModalType] = useState('detail')
  // 购买周期
  const [purchaseCycle, setPurchaseCycle] = useState('')
  // 购买数量
  const [purchaseCount, setPurchaseCount] = useState(1)
  // 购买总价
  const [purchaseTotalMoney, setPurchaseTotalMoney] = useState(0)
  // 支付方式
  const [payWay, setPayWay] = useState('wx')
  // 保存
  const onSave = () => {
    onCreate()
  }
  // 充值支付相关数据
  const resetPayData = () => {
    setModalType('detail')
    setPayWay('wx')
    setPurchaseCycle('')
    setPurchaseCount(1)
    setPurchaseTotalMoney(0)
  }
  // 点击取消区域
  const clickCancel = () => {
    if (['pay'].includes(modalType)) {
      resetPayData()
    } else {
      resetPayData()
      onCancel()
    }
  }
  // 修改购买数量
  const changeAmount = (value) => {
    const price = ['month'].includes(purchaseCycle) ? 68 : 680
    const amount = value < 1 ? 1 : value
    const total = Number(amount * price).toFixed(2)
    setPurchaseCount(amount)
    setPurchaseTotalMoney(total)
  }

  return (
    <Modal
      wrapClassName={styles.subscribe_form_modal}
      visible={visible}
      onCancel={clickCancel}
      centered
      title={['pay'].includes(modalType) ? '订阅支付' : '升级订阅'}
      maskClosable={false}
      width={600}
      closeIcon={
        ['pay'].includes(modalType) ? (
          <span className={styles.back}>返回</span>
        ) : (
          <span className={styles.subscribe_form_modal_close}></span>
        )
      }
      footer={
        ['pay'].includes(modalType) ? (
          <div className={classNames(styles.pay_way_wrapper)}>
            <p className="Inf_page_text">请选择支付方式</p>
            <div className={styles.pay_btn_wrapper}>
              <div>
                <Button
                  key="wx"
                  onClick={() => {
                    setPayWay('wx')
                  }}
                  className={
                    ['wx'].includes(payWay) ? styles.pay_way_active : ''
                  }
                  loading={saving}
                >
                  <span className="iconfont iconico_wepay"></span>微信支付
                </Button>
                <Button
                  key="zfb"
                  className={
                    ['zfb'].includes(payWay) ? styles.pay_way_active : ''
                  }
                  onClick={() => {
                    setPayWay('zfb')
                  }}
                  loading={saving}
                >
                  <span className="iconfont iconico_alipay"></span>支付宝支付
                </Button>
              </div>
              <Button
                className="Inf_black_btn"
                key="pay"
                // onClick={onSave}
                loading={saving}
                style={{ fontSize: 14 }}
              >
                提交
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.upgrade_modal_footer}>
            <div className={styles.upgrade_modal_footer_btn_wrapper}>
              <span className={styles.upgrade_modal_footer_span}>
                <Button
                  key="month"
                  onClick={() => {
                    setPurchaseCycle('month')
                    setModalType('pay')
                    setPurchaseTotalMoney(
                      Number(modalData.monthPrice).toFixed(2)
                    )
                  }}
                  loading={saving}
                  className={classNames(
                    styles.create_btn,
                    styles.primary_btn,
                    styles.price_btn
                  )}
                >
                  <span className={styles.price_bold}>
                    ￥{modalData.monthPrice}
                  </span>
                  / 月
                </Button>
              </span>

              <span className={styles.upgrade_modal_footer_span}>
                <Button
                  type="primary"
                  key="year"
                  onClick={() => {
                    setPurchaseCycle('year')
                    setModalType('pay')
                    setPurchaseTotalMoney(
                      Number(modalData.yearPrice).toFixed(2)
                    )
                  }}
                  className={styles.price_btn}
                  loading={saving}
                >
                  <span className={styles.price_bold}>
                    ￥{modalData.yearPrice}
                  </span>
                  / 年
                </Button>
                <p
                  className="primary"
                  style={{ fontSize: 12, margin: '5px 0' }}
                >
                  ￥{modalData.yearPrice / 12} / 月
                </p>
              </span>
            </div>
            <p className="Inf_page_text">定制或更多需求请点击帮助联系客服</p>
          </div>
        )
      }
    >
      {/* 详情弹窗 */}
      {['detail'].includes(modalType) && (
        <div className={styles.upgrade_modal_body}>
          <div className={styles.upgrade_modal_title}>
            <div className="Inf_page_title">{modalData.editionTypeDesc}</div>
            <div className={styles.upgrade_price}>
              <p className={styles.month_price}>
                ￥{modalData.editionPrice} / 月
              </p>
              <p>需按年付，￥{modalData.yearPrice} / 年</p>
            </div>
          </div>
          <div className={styles.upgrade_modal_content}>
            <div className={styles.text_item}>
              <p>储存</p>
              <p className={styles.text_important}>{modalData.totalDb}</p>
              <p>{modalData.dbDesc}</p>
            </div>
            <div className={styles.text_item}>
              <p>项目数</p>
              <p className={styles.text_important}>{modalData.projectCount}</p>
            </div>
            <div className={styles.text_item}>
              <p>主要功能</p>
              {modalData &&
                modalData.funcList &&
                modalData.funcList.map((item) => (
                  <p className={styles.text_important}>{item}</p>
                ))}
            </div>
          </div>
        </div>
      )}
      {/* 支付弹窗 */}
      {['pay'].includes(modalType) && (
        <div className={styles.upgrade_modal_body}>
          <div className={styles.upgrade_modal_title}>
            <div>
              <div className="Inf_page_title">
                Infotos Pro {modalData.editionTypeDesc}账户
              </div>
              <p className={styles.text_item}>
                {modalData.totalDb}存储/项目数{modalData.projectCount}/完整功能
              </p>
              <p className={styles.text_item}>
                {['month'].includes(purchaseCycle)
                  ? `按月支付-￥${Number(modalData.monthPrice).toFixed(2)} / 月`
                  : `按年支付-￥${Number(modalData.yearPrice).toFixed(2)} / 年`}
              </p>
            </div>
            <div className={styles.upgrade_price}>
              <p>
                {['month'].includes(purchaseCycle) ? `购买月数` : '购买年数'}
              </p>
              <div className={styles.month_input}>
                {['month'].includes(purchaseCycle) ? (
                  <InputNumber
                    value={purchaseCount}
                    onChange={changeAmount}
                    max={12}
                    min={1}
                  ></InputNumber>
                ) : (
                  <InputNumber
                    value={purchaseCount}
                    onChange={changeAmount}
                    max={10}
                    min={1}
                  ></InputNumber>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.upgrade_modal_content,
              styles.total_wrapper
            )}
          >
            <div className={classNames('Inf_page_title', styles.pay_total)}>
              总价
            </div>
            <div className="Inf_page_title">￥{purchaseTotalMoney}元</div>
          </div>
        </div>
      )}
    </Modal>
  )
}

const SubscribeForm = (props) => {
  const { formData,formDataD, getDetail, getInfo ,pfn,infoDetails} = props
  const [visible, setVisible] = useState(false)
  const [Details, setDetails] = useState(false)
  const [VisDetails, setVisDetails] = useState([])
  // 弹窗详情
  const [modalData, setModalData] = useState({})
  const [removeFormVisible, setRemoveFormVisible] = useState(false)
  const [removedProject, setRemovedProject] = useState({})
  const { DefaultCoverPNG } = Imgs
  const [AccUpgradeVisible, setAccUpgradeVisible] = useState(false) //升级弹框
  const [AccUpgrade, setAccUpgrade] = useState(null) //升级信息
  const [versions, setVersions] = useState([]) //版本信息
  const [thisVersions, setThisVersions] = useState(0) //当前版本信息
  const [QRcodeVisible,setQRcodeVisible]=useState(false)//支付二维码弹框
  const [payCode,setPayCode]=useState('')//支付二维码
  const [productObj,setProductObj]=useState({num:0,size:0})//图片数量及大小
  useEffect(() => {
    getInfos()
  }, [])
  const getInfos=async()=>{
    //获取企业账号控制集合
    const res = await _getEnterpriseLevel({})
    if (res.data.code === '000000') {
      let names=versionsOld.map(v=>v.name)
      res.data.data.map(v=>{
        let obj=versionsOld[names.indexOf(v.name)]
        for(let key in obj){//赋值参数
          v[key]=obj[key]
        }
      })
      //根据版本价格排序
      res.data.data.sort((a,b)=>a.price-b.price)
      console.log(res.data.data,props.infoDetails,formData)
      res.data.data.map((v,i)=>{
        if(v.name==props.infoDetails.name){
          setThisVersions(i)
        }
      })
      setVersions(res.data.data)
    }
  }
  const showModal = async (val) => {
    setAccUpgrade(val)
    setAccUpgradeVisible(true)
    // Modal.info({
    //   title: '订阅升级',
    //   icon: '',
    //   width: 600,
    //   centered: 'centered',
    //   content: '暂不支持线上升级，请点击帮助联系客服进行升级。',
    //   okText: '确定',
    //   footer: [],
    //   onOk() {
    //     console.log('OK');
    //   },
    // })
    return
    let editionType = 'FREE'
    // switch (formDataD.editionType) {
    switch (infoDetails.type) {
      case 'Free':
        editionType = 'Basic'
        break
      case 'Basic':
        editionType = 'Normal'
        break
      case 'NORMAL':
        editionType = 'PRO'
        break
      case 'PRO':
        editionType = 'BUSINESS'
        break
      case 'BUSINESS':
        editionType = 'PREMIUM'
        break
      default:
        break
    }
    // 获取升级后的详情
    // console.log(val)
    try {
      const res = await getDetail({ editionType })
      if ([0].includes(res.code)) {
        const {
          data: { formattedData },
        } = res
        setModalData(formattedData)
        setVisible(true)
      }
    } catch (error) {}
  }
  const hideModal = () => {
    setVisible(false)
    setModalData({})
  }
  const showDetails = async() => {
    pfn('details')
    localStorage.setItem('isDetails',true)
    // return
    let editionType = formData.editionType
    // const res = await getDetail({ editionType })
    // setLoading(true)
    const res = await _getProjectsByCategory({
      //分类id
      categoryId:'',
      //项目名称
      name:'',
      // 所属图片项目分类id
      projectCategoryId:'',
      // 排序类型 SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC
      sortEnum:'CREATE_TIME_DESC',
      //  项目状态 HIDDEN, ONLINE
      status:'',
      pageNo:1,
      pageSize:1000,
    })
    // setLoading(false)
    if (res.code === 0) {
      document.getElementById('forBack').setAttribute('style','padding-left:40px')
      let num=0,size=0
      res.data.map(v=>{
        num+=v.customerPhotoCount
        size+=v.dbSize
      })
      setProductObj({
        num:num,
        size:size,
      })
      setVisDetails(res.data)
      setDetails(true)
    } else {
      message.error(res.msg)
    }

    const re = await _getPhotoNum({ })
    if(res.code==0) {
      setProductObj({
        num:re.data,
        size:0,
      })
    }
  }
  const goBack = () => {
    document.getElementById('forBack').setAttribute('style','padding-left:0')
    setDetails(false)
  }
  const UpgradeSubscription = async () => {
    hideModal()
  }
  const versionsOld=[
    {
      0:'',
      1:'2GB,1000+照片',
      2:'2个',
      3:'T',
      4:'T',
      5:'F',
      6:'F',
      7:'F',
      8:'T',
      9:'F',
      10:'F',
      11:'1个',
      12:'F',
      13:'',
      14:'F',
      15:'T',
      16:'F',
      17:'F',
      18:'F',
      19:'',
      20:'T',
      21:'',
      22:'默认商品',
      23:'所得100%',
      24:'F',
      25:'F',
      26:'F',
      27:'F',
      28:'F',
      29:'F',
      name:'免费版',
      price:'0',
      desc:'永久免费',
      editionType:'FREE',
    },
    {
      0:'',
      1:'20GB,6000+照片',
      2:'10个',
      3:'T',
      4:'T',
      5:'T',
      6:'F',
      7:'T',
      8:'T',
      9:'T',
      10:'F',
      11:'1个',
      12:'T',
      13:'',
      14:'T',
      15:'T',
      16:'T',
      17:'T',
      18:'T',
      19:'',
      20:'T',
      21:'',
      22:'默认商品',
      23:'所得100%',
      24:'F',
      25:'F',
      26:'F',
      27:'F',
      28:'F',
      29:'F',
      name:'基础版',
      price:'28',
      desc:'需按年付',
      editionType:'BASIC',
    },
    {
      0:'',
      1:'100GB,30000+照片',
      2:'无限',
      3:'T',
      4:'T',
      5:'T',
      6:'T',
      7:'T',
      8:'T',
      9:'T',
      10:'F',
      11:'1个',
      12:'T',
      13:'',
      14:'T',
      15:'T',
      16:'T',
      17:'T',
      18:'T',
      19:'',
      20:'T',
      21:'',
      22:'T',
      23:'无佣金',
      24:'T',
      25:'T',
      26:'T',
      27:'T',
      28:'T',
      29:'T',
      name:'专业版',
      price:'78',
      desc:'需按年付',
      editionType:'PRO',
    },
    {
      0:'',
      1:'1TB,300000+照片',
      2:'无限',
      3:'T',
      4:'T',
      5:'T',
      6:'T',
      7:'T',
      8:'T',
      9:'T',
      10:'T',
      11:'最多20个',
      12:'T',
      13:'',
      14:'T',
      15:'T',
      16:'T',
      17:'T',
      18:'T',
      19:'',
      20:'T',
      21:'',
      22:'T',
      23:'无佣金',
      24:'T',
      25:'T',
      26:'T',
      27:'T',
      28:'T',
      29:'T',
      name:'团队版',
      price:'298',
      desc:'需按年付',
      editionType:'BUSINESS',
    },
  ]
  const showRemove = (project) => {
    setRemovedProject(project)

    setRemoveFormVisible(true)
  }
  // 删除项目
  const doRemoveProject = async () => {
    let that=this
    const res = await _removeProject({ id: removedProject.id })
    if (res.data.code === 0) {
      message.success('删除成功!')
      getInfo()
      showDetails()
      setRemoveFormVisible(false)
    } else {
      message.error('删除失败!' + res.data.msg)
    }
    setRemovedProject({})
  }
  //数据转换
  const num= (num)=>{
    if(num>=1000000){
      return (num/1024/1024).toFixed(2)+'TB'
    }else if(num>=1000){
      return (num/1024).toFixed(2)+'GB'
    }else{
      return (num*1).toFixed(2)+'MB'
    }
  }
  // 时间戳转换
  const formatDate= (dat)=>{
      var date = new Date(dat);
      var YY = date.getFullYear() + '-';
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      // return YY + MM + DD +" "+hh + mm + ss;
      return YY + MM + DD;
  }
  const getMenu = (project) => {
    return (
        <div className={styles.projectMenu}>
          <Menu>
            <Menu.Item>
              <Link
                  to={getFullRoute(PAGE_PATH.customerPhoto.details, {
                    projectId: project.id,
                    showKey: 'photos',
                  })}
              >
                编辑
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item>
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={showRemove.bind(this, project)}
              >
                删除
              </a>
            </Menu.Item>
          </Menu>
        </div>
    )
  }
  //显示支付弹框
  const parentShowPay=(code)=>{
    localStorage.removeItem('myOrder')
    setPayCode(code)
    setAccUpgradeVisible(false)//关闭
    setQRcodeVisible(true)
  }
  return (
    <div className={styles.subscribe_form}>
      {Details?
          <div style={{
            position: 'fixed',
            top: '-0px',
            height:'87px',
            lineHeight:'87px',
            zIndex: '9'}}>
            <Button className={styles.goBackbtn}
                icon={
                  <span
                      className="iconfont iconbtn_back"
                  ></span>
                }
                onClick={goBack}
            />
            <span style={{marginLeft:'80px',
              color: '#4A4A4A',
              fontWeight: '600',
              fontSize: '16px'}}>：使用详情</span>
          </div>
          :''}
      {/*订阅版本  */}
      <div className={styles.subscribe_title}>
        <span className={styles.version}>
          订阅版本：{infoDetails.name} / 到期时间：{infoDetails.name=='免费版'?'永久':formatDate(infoDetails.serviceDuration)}
        </span>
        {/*
        <span className={styles.due_date}>
          {' '}
          到期时间：{formData.expireTime}
        </span>
        */}
        {!Details?(
          <span style={{color:'#066DD5',float:'right',cursor:'pointer'}} onClick={showDetails}>查看使用详情</span>): ''
        }
      </div>
      {/* 进度条 */}
      <div className={styles.subscribe_use_progress}>
        <div className={styles.subscribe_use_text}>
          <div className={styles.use_info}>
            已使用{num(infoDetails.useDb)}/共{num(infoDetails.totalDb)}
          </div>
          <div className={styles.use_percent}>{(infoDetails.useDb/infoDetails.totalDb*100).toFixed(2)}%</div>
        </div>
        <div className={styles.subscribe_progress}>
          <Progress percent={infoDetails.useDb/infoDetails.totalDb*100} showInfo={false} />
        </div>
      </div>
      {Details?(
          // 订阅详情
          <div className={styles.subscribe_form}>
            <Row className={styles.detailsLi} style={{fontWeight: 'bold'}}>项目存储详情</Row>
              {/* 网站 */}
              <Row align='middle' className={styles.detailsLi}>
                <Col flex="90px">
                  <div className={styles.photo}>
                    <img
                        className={styles.image}
                        src={imgWebsite}
                        preview={false}
                    />
                  </div>
                </Col>
                <Col flex="auto">
                  <div style={{fontSize:'13px', fontWeight:700}}>网站</div>
                  <div style={{fontSize:'11px', color:'#9B9B9B'}}>已有{productObj.num}张照片</div>
                </Col>
                <Col flex="300px" style={{fontSize:'13px', textAlign:'right', paddingRight:'200px'}}>暂未计算{/*{num(productObj.size)}*/}</Col>
                <Col flex="15px"></Col>
              </Row>
              {/* 项目 */}
              {VisDetails.length>0?(
                VisDetails.map(project=>
                  <Row align='middle' className={styles.detailsLi}>
                    <Col flex="90px">
                      <div className={styles.photo}>
                        <img
                            className={styles.image}
                            src={project.coverPhotoIngo.photoUrl?project.coverPhotoIngo.photoUrl:DefaultCoverPNG}
                            preview={false}
                        />
                      </div>
                    </Col>
                    <Col flex="auto">
                      <div style={{fontSize:'13px', fontWeight:700}}>{project.name}</div>
                      <div style={{fontSize:'11px', color:'#9B9B9B'}}>
                        {`${project.customerPhotoCount}张照片`}
                        {/* {`${project.shootingDay?project.shootingDay:'未定义日期'} · ${project.customerPhotoCount}张照片`} */}
                        {/* {`${project.statusDesc}`=='隐藏'?'':` · 已${project.statusDesc}`} */}
                      </div>
                    </Col>
                    <Col flex="150px" style={{fontSize:'13px', textAlign:'right', paddingRight:'200px'}}>{num(project.dbSize)}</Col>
                    <Col flex="15px">
                      <span>
                        <Dropdown
                            overlay={getMenu.bind(this, project)}
                            placement="bottomRight"
                            trigger={['click']}
                        >
                          <SettingOutlined/>
                        </Dropdown>
                      </span>
                    </Col>
                  </Row>
                )
              ):(
                  <div className={styles.nullTips}>暂无创建的项目</div>
              )
              }
            <RemoveProjectForm
                visible={removeFormVisible}
                onCreate={doRemoveProject}
                onCancel={() => {
                  setRemoveFormVisible(false)
                  setRemovedProject({})
                }}
                project={removedProject}
            />
          </div>
      ):(
        <div className={styles.subscribe_form}>
          {/* 升级  */}
          <div className={styles.subscribe_description}>
            <div className={styles.subscribe_description_text}>
              您当前的计划为：
              <span className={styles.subscribe_price}>
                {infoDetails.name}
              </span>
              ，更多其他订阅版本，或工作室（企业）有自定义需求欢迎点击帮助与客服取得联系。
              {/* <Link style={{color:'#066DD5',float:'right',cursor:'pointer'}} to={PAGE_PATH.my.children.shoppingcart}>查看订购历史</Link> */}
            </div>
          </div>
          <div>
            <Row className={styles.subscribe_version}>
              <Col span={5}>
                <ul className={styles.subscribe_ul}>
                  <li className={styles.subscribe_li}></li>
                  {
                    VersionsTypes.map((val,key) => <li className={styles.subscribe_li} key={val.value} style={{fontWeight:key==0||key==10||key==12||key==13||key==19||key==21?'bold':'normal',display:key<30?'flex':'none'}}>{val.label}</li>)
                  }
                  <li className={styles.subscribe_li}></li>
                </ul>
              </Col>
              <Col span={19} className={styles.subscribe_child}>
              {
                versions.map((val,key) =>
                    <div>
                      <ul className={styles.subscribe_ul}>
                        <li className={[styles.subscribe_li,styles.subscribe_center].join(' ')} style={{lineHeight:'26px',paddingBottom:'20px'}}>
                          <div style={{color: '#4A4A4A',fontWeight:"bold"}}>{val.name}</div>
                          <div>¥ <span style={{color: '#4A4A4A',fontWeight:"bold",fontSize:'20px'}}>{val.price}</span> /月</div>
                          <div style={{color: '#9B9B9B'}}>{val.desc}</div>
                          {/*<div className={styles.subscribe_btn} onClick={()=>showModal(val)} style={{display:infoDetails.name=='免费版'&&key==0?'none':infoDetails.name=='专业版'&&key<=1?'none':infoDetails.name=='团队版'&&key<=2?'none':'inline-block'}}>{val.name===formData.editionTypeDesc?'分享获利':infoDetails.name==val.name?'续费':'升级'}</div>*/}
                          <div className={styles.subscribe_btn} onClick={()=>showModal(val)} style={{display:key<thisVersions||(infoDetails.name=='免费版'&&key==0)?'none':'inline-block'}}>{infoDetails.name==val.name?'续费':'升级'}</div>
                        </li>
                        {
                          VersionsTypes.map((v,k) => <li className={[styles.subscribe_li,styles.subscribe_center].join(' ')} style={{display:k<30?'flex':'none', justifyContent:'center', alignItems:'center'}}>
                            {val[k].indexOf(',')!=-1?(
                                <div>
                                  <span style={{display:'block',fontSize:'14px',fontWeight:700}}>{val[k].split(',')[0]}</span>
                                  <span style={{color: '#9B9B9B'}}>{val[k].split(',')[1]}</span>
                                </div>
                            ):val[k]=='T'?(
                                <CheckOutlined  style={{color:'#1AA77D',fontSize:'17px',fontWeight:600}}/>
                            ):val[k]=='F'?(
                                <MinusOutlined style={{color: '#4A4A4A',fontSize:'12px'}}/>
                            ):val[k]}
                          </li>)
                        }
                        <li className={[styles.subscribe_li,styles.subscribe_center].join(' ')}>
                          {/*<div className={styles.subscribe_btn} onClick={()=>showModal(val)} style={{display:infoDetails.name=='免费版'&&key==0?'none':infoDetails.name=='专业版'&&key<=1?'none':infoDetails.name=='团队版'&&key<=2?'none':'inline-block'}}>{val.name===formData.editionTypeDesc?'分享获利':infoDetails.name==val.name?'续费':'升级'}</div>*/}
                          <div className={styles.subscribe_btn} onClick={()=>showModal(val)} style={{display:key<thisVersions||(infoDetails.name=='免费版'&&key==0)?'none':'inline-block'}}>{infoDetails.name==val.name?'续费':'升级'}</div>
                        </li>
                      </ul>
                    </div>
                )
              }
              </Col>
            </Row>
          </div>
          <div style={{textAlign:'center',fontSize:'14px', padding:'30px 0 5px',fontWeight:700}}>更多订阅版本与自定义需求</div>
          <div style={{textAlign:'center', color: '#4A4A4A', fontSize: '13px', padding:'0 0 20px 0'}}>更多其他订阅版本，或工作室（企业）有自定义需求欢迎点击帮助与客服取得联系。</div>
        </div>
        )}
      {/*升级弹框*/}
      <AccUpgradeModal
        onCancel={()=>setAccUpgradeVisible(false)}
        visible={AccUpgradeVisible}
        accUpgrade={AccUpgrade}
        parentShowPay={parentShowPay}
      />
      <UpgradeModal
        visible={visible}
        modalData={modalData}
        onCreate={UpgradeSubscription}
        onCancel={hideModal}
      />
      {/*//支付弹框*/}
      <PaymentQRcodeModal visible={QRcodeVisible} wxCode={payCode} onCancel={()=>setQRcodeVisible(false)} />
    </div>
  )
}
export default SubscribeForm
