import {
    Button,
    Checkbox,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    List,
    Menu,
    message,
    PageHeader,
    Row,
    Select,
    Switch,
    Table,
    Tooltip,
    Typography,
    Radio, Spin, Card, Modal,
} from 'antd'
import components from 'components'
import Imgs from 'imgs'
import {isEmpty} from 'lodash'
import Mock from 'mockjs'
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import myMessage from 'components/Message'
import {getName} from 'utils/Help'
import {uploadImage} from 'utils/Uploader'
import styles from './shopPage.module.less'
import CommonComponent from 'components/index'
import { getAccessToken } from 'utils/Request'

import {
    addShoppingCar as _addShoppingCar,
    addInformation as _addInformation,
    getShoppingCar as _getShoppingCar,
} from 'reduxModules/order'
import {
    addNewExport as _addNewExport,
} from 'reduxModules/store'
import Api from 'apis/Api.js'

const {ProfotoDefault} = Imgs
const {MyMessage, InfoEmptyData} = CommonComponent
const {Random} = Mock
const {Header, Footer, Sider, Content} = Layout
const {Text} = Typography
const {AddSelect, ProvinceSelectCascader} = components
const {TextArea} = Input
const keys = ['view', 'share', 'collect', 'download', 'order']
const activeStyle = {fontWeight: 'bolder', color: '#000'}

const {DefaultCoverPNG} = Imgs
const codes = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export const ShopPage = (props, ref)=> {
    const [checkEnterprise, setCheckEnterprise] = useState({})
    const [projectInfo, setProjectInfo] = useState({imageUrls:[],typeId:0,productOptionVos:[]})
    const [bigImg, setBigImg] = useState({})
    const [showPrice, setShowPrice] = useState(0)
    const [showQrCode, setShowQrCode] = useState(false)
    const [infoDetails, setInfoDetails] = useState({})
    const [opener, setOpener] = useState(null)
    const dispatch = useDispatch()
    const shoppingCart = useSelector(
      (state) => state.order.shoppingCart || 0
    )
    useEffect(() => {
        if(!!props.checkEnterprise.id){
            // console.log(!!props.checkEnterprise.id,props)
            setCheckEnterprise(props.checkEnterprise)
            setProjectInfo(props.checkEnterprise.productBaseInfoList[0])
            setInfoDetails(props.infoDetails)
            setPro(props.checkEnterprise.productBaseInfoList[0])
            setPrice(props.checkEnterprise.productBaseInfoList[0])
        }
        gets()
    },[props.checkEnterprise])
    const gets=async ()=>{
        // const res = await _getShoppingCar({})
    }
    // useEffect(() => {
    //     setPro(projectInfo)
    // }, [projectInfo])
    const setPro=(item)=>{
        setBigImg(item.imageUrls[0])
    }
    const changeAcitive = (item) => {
        setProjectInfo(item)
        setPro(item)
        setPrice(item)
    }
    // 选择产品规格
    const checkOpt=(val,optId,ind)=> {
        let item=JSON.parse(JSON.stringify(projectInfo))
        if(item.typeId!=5) {
            item.productOptionVos.map(v => {
                if (v.id == optId) {
                    v.text = val.value
                    v.value = ind
                }
            })
        }else{

        }
        setProjectInfo(item)
        setPrice(item)
    }
    //设置选项的价格
    const setPrice=(item)=>{
        let optionName='',chekOpt=[]
        if(item.productOptionVos&&item.productOptionVos.length>0) {
            item.productOptionVos.map(v => {
                chekOpt.push(v.text)
            })
            optionName = chekOpt.join(',')
            let price=item.productSpecificationsVos.find(v => v.optionName.replace('页', '') == optionName)
            //免费版是销售定价否则为成本价
            setShowPrice(item.source=="self"||infoDetails.name=='免费版'?price.salesPrice:price.costPrice)
        }else{
            setShowPrice(item.source=="self"?item.salesPrice:item.costPrice)
        }
    }
    //关闭弹框
    const showqr=async ()=>{
        let data={
            productId:projectInfo.id,//商品ID
            name:projectInfo.name,//导出名称
            described:projectInfo.described,//描述信息
            coverImage:'',//导出封面图片
        }
        // const re = await _addNewExport(data)
        let exportId,serialNumber
        const re = await _addInformation(data)
        if(re.status==200&&re.data.code=='000000'){
            exportId=re.data.data.exportId
            serialNumber=re.data.data.serialNumber
        }
        // console.log(re)
        // return
        let optionName='',chekOpt=[],upOptionName=[]
        projectInfo.productOptionVos.map(v => {
            chekOpt.push(v.text)
            upOptionName.push(v.optionName == "albumPages" ? v.text + '页' : v.optionName == 'pages' ? v.text + 'pages' : v.text)
        })
        optionName = chekOpt.join(',')
        let specification=projectInfo.productSpecificationsVos.find(v => v.optionName.replace('页', '') == optionName)
        // console.log(optionName,specification,projectInfo)
        // console.log(props)
        // return;
        data={
            photoId:projectInfo.imageId.split(',')[0],//购买图片ID
            projectId:props.projectDetail.id,//项目ID
            // customerPhone:props.projectDetail.customerResultVO.customerPhone,//客户电话
            customerPhone:sessionStorage.getItem('user').user,//客户电话
            priceId:props.projectDetail.priceId,//价格表ID
            // customerEmail:props.projectDetail.customerResultVO.customerEmail,//客户邮箱
            productId:projectInfo.id,//商品ID
            specificationId:specification.id,//商品规格ID
            exportId:exportId,//导出信息ID
            serialNumber:serialNumber,//导出信息ID
            name:projectInfo.name,//商品名称
            number:1,//商品数量
            described:projectInfo.describe,//商品描述信息
            optionName:upOptionName.join(', '),//订单规格信息
            price:projectInfo.source=="self"||infoDetails.name=='免费版'?specification.salesPrice:specification.costPrice,//单价
            freightPrice:projectInfo.typeId == 4 ? 0 : !projectInfo.productOptionVos ? projectInfo.extraPostage : specification.extraFreight,//运输价格
            pageSalesPrice:specification.pageSalesPrice,//加页价格
            addPageNum:0,//加页数量
            imageId:!!specification.imageId ? specification.imageId : projectInfo.imageId.split(',')[0],//图片ID
            // imageUrl:project.typeId == 4 ? project.imageId.split(',')[0] : !!specification.imageId ? specification.imageUrl : specification.imageUrl[0],//图片地址
            imageUrl:projectInfo.imageUrls[0],//图片地址
            orderType:projectInfo.typeId == '4' ? 'dwnOrder' : 'printOrder',//订单类型
            storeId:projectInfo.storeId,//商家ID
            supplierId:projectInfo.supplierEnterpriseId,//供应商ID
            productTypeId:projectInfo.typeId,//产品类型ID
            pageControlType:projectInfo.pageControlType,//页数控制方式, freeIncrease:自由增页，fixedPage：固定页数
        }
        /*let opener = window.open(`${Api.toBC}/project/page`,'_blank');
        setOpener(opener)
        // 监听 message 的变化
        window.addEventListener('message', (e) => {
            if (e.origin === `${Api.toBC}`) { // 接收来自 http://127.0.0.1:5173 网页的信息
                if(e.data?.link) {
                    let message = JSON.stringify({projectInfo, data,token:getAccessToken()})
                    console.log(message)
                    setInterval(()=>{opener.postMessage(message, `${Api.toBC}`);},2000)
                    // this.state.opener.postMessage(message, `${Api.toBC}`);  // 此时才给 B 传输信息
                }

                // document.getElementById('span1').innerHTML = e.data // 作为第一个参数传递给postMessage的数据
                // document.getElementById('span2').innerHTML = e.origin // 发送消息的来源，可以根据origin来确保期望的发送者
                // document.getElementById('span3').innerHTML = e.source // 发送消息的window代理对象
            }
        })
        return*/
        // console.log(projectInfo,data)
        const res = await _addShoppingCar(data)
        if (re.status==200&&re.data.code=='000000') {
            dispatch({type:'',shoppingCart:shoppingCart})
            myMessage.success('已添加到购物袋')
        } else {
            myMessage.error(res?.msg)
        }
        return
        setShowQrCode(true)
    }
    const hideqr=()=>{
        setShowQrCode(false)
    }
    return (
        <div>
            <Header className="sub-header" style={{height: 120}}>
                <PageHeader
                    ghost={false}
                    title={<div style={{fontWeight: 'bold'}}>购买{checkEnterprise?.productTypeName}</div>}
                    style={{
                        padding: '24px 50px 23px',
                    }}
                    // extra={[
                    //   <Button type="primary" key="saveSettings" onClick={this.toCreate}>
                    //     保存
                    //   </Button>,
                    // ]}
                >
                    <div>
                        {checkEnterprise?.productBaseInfoList&&checkEnterprise.productBaseInfoList.map((v,i)=>{
                            return(
                              <span>
                                  {i!=0&&<Divider type="vertical"/>}
                                <Button
                                  type="link"
                                  className="tab-btn"
                                  style={projectInfo.id === v.id ? activeStyle : null}
                                  onClick={()=>changeAcitive(v)}
                                >
                                    {v.name}
                                </Button>
                               </span>
                            )
                        })}

                    </div>
                </PageHeader>
            </Header>
            <Content
                className={`${styles.projectInfo} sub-content`}
                style={{minHeight: 'calc(100vh - 120px)', paddingTop: '145px'}}
            >
                <div className={styles.project}>
                    <div
                      className={styles.imgs}>
                        <div className={styles.bigImg}>
                            <img src={bigImg} alt=""/>
                        </div>
                        <ul>
                            {projectInfo?.imageUrls.map((item, i) => {
                                return (
                                  <li onClick={()=>setBigImg(item)}>
                                      <img src={item} alt=""/>
                                  </li>
                                )}
                            )}
                        </ul>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.name}>{projectInfo?.name}</div>
                        <div className={styles.price}>￥{showPrice}</div>
                        <div className={styles.information}>1个设计编辑中</div>
                        <div className={styles.productOverview}>{projectInfo?.productOverview}</div>
                        {projectInfo.typeId!=5&&projectInfo.typeId!=7&&projectInfo?.productOptionVos.length>0&&projectInfo?.productOptionVos.map((item, i) => {
                            return (
                              <div className={styles.options}>
                                  {(item.optionName=='albumPages')?(<div className={styles.opt}>页数</div>):(<div className={styles.opt}>{item.optionName}</div>)}
                                  <ul>
                                      {item?.options.length>0&&item?.options.map((val,ind)=>{
                                          return(
                                            <li className={item.value==ind?styles.active:''} onClick={()=>checkOpt(val,item.id,ind)}>{val.value}</li>
                                          )
                                      })}
                                  </ul>
                              </div>
                            )
                        })}
                        {projectInfo.typeId==5&&(
                          <div className={styles.options}>
                              <div className={styles.opt}>产品</div>
                              <ul>
                                  {projectInfo?.productSetVos.length>0&&projectInfo?.productSetVos.map((item, i) => {
                                      return(
                                        <li className={item.value==i?styles.active:''} onClick={()=>checkOpt(item,item.id,i)}>{item.childProductName}</li>
                                      )
                                  })}
                              </ul>
                          </div>
                        )}
                        <div className={styles.customization} onClick={()=>showqr()}>开始定制</div>
                        {projectInfo?.describe&&(<div className={styles.textTitle}>商品描述</div>)}
                        <div className={styles.description}>{projectInfo?.describe}</div>
                        {projectInfo?.paperBinding&&(<div className={styles.textTitle}>纸张与装订</div>)}
                        <div className={styles.description}>{projectInfo?.paperBinding}</div>
                        {projectInfo?.processDays&&(<div className={styles.textTitle}>制作与发货</div>)}
                        <div className={styles.description}>{projectInfo?.processDays}</div>
                    </div>
                </div>
            </Content>
            <Modal title="暂不支持直接定制"
                   visible={showQrCode}
                   centered
                   footer={<div></div>}
                   width={600}
                   closable={false}
            >
                <div className={styles.qr}>
                    <img src={Imgs.QRcode} alt=""/>
                    <div className={styles.qrtext}>请微信扫描二维码添加客服咨询处理</div>
                    <div className={styles.qrok} onClick={()=>hideqr()}>知道了</div>
                </div>
            </Modal>
        </div>
    )
}
