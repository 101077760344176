import Api from 'apis/Api.js'
import request from 'utils/Request'

// 分页查询我的暂存
/**
 *

pageNo
integer($int32)
(query)
当前页面

pageSize
integer($int32)
(query)
分页大小

 * @param {*} param0
 */
export const _getMyStorage = async ({ pageNo = 1, pageSize = 20 }) => {
  return await request({
    method: 'get',
    url: Api.order.getMyStorage,
    params: { pageNo, pageSize },
  })
}

// 加入购物车
export const _addCart = async ({ id }) => {
  return await request({
    method: 'post',
    url: Api.order.addCart,
    data: {
      id,
    },
  })
}

// 删除暂存
export const _removeMyStorage = async ({ id }) => {
  return await request({
    method: 'post',
    url: Api.order.removeMyStorage,
    data: {
      id,
    },
  })
}

// 查询购物车
export const _getMyCart = async () => {
  return await request({
    method: 'get',
    url: Api.order.myCart,
    params: {},
  })
}

// 删除购物车
export const _removeCart = async ({ id }) => {
  return await request({
    method: 'post',
    url: Api.order.removeCart,
    data: {
      id,
    },
  })
}
export const _addShoppingCar = async (data) => {
  return await request({
    method: 'post',
    url: Api.order.addShoppingCar,
    data: data,
  })
}
export const _cancelShoppingCar = async (data) => {
  return await request({
    method: 'post',
    url: Api.order.cancelShoppingCar,
    data: data,
  })
}
export const _editShoppingCar = async (data) => {
  return await request({
    method: 'put',
    url: Api.order.editShoppingCar,
    data: data,
  })
}
export const _getShoppingCar = async (params) => {
  return await request({
    method: 'get',
    url: Api.order.getShoppingCar,
    params: params,
  })
}
export const _addInformation = async (data) => {
  return await request({
    method: 'post',
    url: Api.order.addInformation,
    data: data,
  })
}
export const _invalidInformation = async (data) => {
  return await request({
    method: 'post',
    url: Api.order.invalidInformation,
    data: data,
  })
}
