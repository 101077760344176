//价格表详情（产品列表页）
import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Button,
  Menu,
  Dropdown,
  Divider,
  Layout,
  PageHeader,
  message
} from "antd";
import styles from './products.module.less'
import AssignToProject from "./AssignToProject";
import moment from "moment";
import {PAGE_PATH} from 'apis/Router'
import {getFullRoute} from 'utils/Tools'
import {useParams, useHistory, Link} from 'react-router-dom'
import NewPricesheet from "./NewPricesheet";
import myMessage from 'components/Message'
import {
  pricesheetInfo as _pricesheetInfo,
  productEditStatus as _productEditStatus,
  editPrice as _editPrice,
  deleteProduct as _deleteProduct,
  setDefault as _setDefault,
} from 'reduxModules/store'
import {
  editProjectsPriceId as _editProjectsPriceId,
} from 'reduxModules/customerPhoto'
import classNames from "classnames";
import Imgs from 'imgs'
import CommonComponent from 'components/index'
import { PriceValidateRule,pricePattern } from 'utils/ValidateRulesAndMsg'

const {InfoEmptyData} = CommonComponent
const {downloadCover} = Imgs
const {Option} = Select
const {Content} = Layout

const Products = ({name}) => {
  let history = useHistory()
  const [form] = Form.useForm()
  let {pricesheetId} = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState({
    // sourceEnum: '', //来源
    sourceName: '全部', //来源
    // grouping: '全部', //分组
    // searchValue:'',//邮件/名称/电话
    pageNo: 1,
    pageSize: 20,
    current: 10,
    totalCount: 20
  })
  const [editObj, setEditObj] = useState({})
  const [productList, setProductList] = useState([])
  const [visible, setVisible] = useState(false)
  const [enter, setEnter] = useState({})

  const [preset, setPreset] = useState([])
  const [assignToProjectVisible, setAssignToProjectVisible] = useState(false)
  const [checkAssign, setCheckAssign] = useState({pricesheetName: ''});
  const [enterpriseId, setEnterpriseId] = useState('');
  const [onlineVisible, setOnlineVisible] = useState(false);
  const [saveImg, setSaveImg] = useState({});
  const [pricesheet, setPricesheet] = useState({});
  const [deleteVisible, setDeleteVisible] = useState(false);
  const div = useRef(null) //商品描述的高度
  const [productDesc, setProductDesc] = useState(false);
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setPricesheet(JSON.parse(sessionStorage.getItem('pricesheet')))
    sessionStorage.removeItem('product')
    setEnterpriseId(sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
      : null)
    getInfo()
  }, [])
  // const showVisible=()=>{
  //     setVisible(true)
  // }
  const setDefault = async () => {
    const res = await _setDefault({
      id: pricesheet.id,
      storeId: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).enterpriseId : null
    })
    if (res.status === 200 && res.data.code === '000000') {
      let obj = JSON.parse(JSON.stringify(pricesheet))
      obj.isDefault = 'yes'
      setPricesheet(obj)
      myMessage.success('已设为默认价格表')
    } else {
      myMessage.error(res.data.msg)
    }
  }
  const getInfo = async () => {
    setLoading(true)
    const res = await _pricesheetInfo({
      id: pricesheetId,
      storeId: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user')).enterpriseId
        : null
    })
    if (res.code === '000000') {
      // let obj=JSON.parse(JSON.stringify(productList))
      // obj[0].list=res.data.data
      setProductList(res.data)
      // console.log(obj)
      setLoading(false)
    } else {
      myMessage.error(res.mesg)
      setLoading(false)
    }
  }
  const addDeliver = async (values) => {
    //判定价格与加页价格是否为0
    let priceZreo=false,pageSalesZreo=false
    editObj.productSpecificationsVos.map(v=>{
      if(!v.salesPrice||v.salesPrice<=0){
        priceZreo=true
      }
      if(editObj.pageControlType != 'fixedPage'&&(!v.pageSalesPrice||v.pageSalesPrice<=0)){
        pageSalesZreo=true
      }
    })
    if(priceZreo){
      myMessage.warning('销售定价不能为空且不能为0')
      return
    }
    if(pageSalesZreo){
      myMessage.warning('加页定价不能为空且不能为0')
      return
    }
    let data = {
      id: editObj.id,
      specificationsPriceEditForms: editObj.productSpecificationsVos,
      price: editObj.price
    }
    const res = await _editPrice(data)
    if (res.status == '200' && res.data.code === '000000') {
      getInfo()
      setVisible(false)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
  const handleItem = async (type, item) => {
    console.log(type)
    //跳转页面清空缓存
    sessionStorage.removeItem('product')
    if (type === '编辑') {

    } else if (type === '删除') {

    }
  }

  const onClick = ({key}) => {
    // switch (key) {
    //     case "1":
    setSelectedClient({
      pageNo: selectedClient.pageNo,
      pageSize: selectedClient.pageSize,
    })

  };
  const menu = (
    <div className={styles.sortMenu}>
      <Menu onClick={onClick}>
        {/*SHOOTING_DAY_DESC, CREATE_TIME_DESC, PROJECT_NAME_DESC*/}
        <Menu.Item key="0" disabled className={styles.titleName}>商品分类</Menu.Item>
        <Menu.Item key="1">全部</Menu.Item>
        <Menu.Item key="2">框画</Menu.Item>
        <Menu.Item key="3">相册</Menu.Item>
      </Menu>
    </div>
  );
  // 返回
  const goBack = () => {
    history.goBack()
  }
  const clickImg = async (item, type) => {
    console.log(item)
    setSaveImg(item)
    if (type === 'status') {
      if (item.status != "online") {
        changeStatus(item)
      } else {
        setOnlineVisible(true)
      }
    } else if (type === 'delete') {
      setDeleteVisible(true)
    } else if (type === 'edit') {
      // if(item.source=='supplier'){
      //     console.log(item)
      //     form.setFieldsValue({
      //         ...item
      //     })
      //     setVisible(true)
      // }else {
      edit(item)
      // }
    }
  }
  //上下线
  const changeStatus = async (item) => {
    const res = await _productEditStatus({
      productId: !saveImg.id ? item.id : saveImg.id,
      status: saveImg.status == 'online' ? 'offline' : 'online'
    })
    if (res.status == '200' && res.data.code === '000000') {
      getInfo()
      setOnlineVisible(false)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
  //修改
  const edit = (val) => {
    console.log(val)
    val.imageUrls = !val.imageUrls ? [] : val.imageUrls
    val.productSpecificationsVos = !val.productSpecificationsVos ? [
      // {
      //     costPrice: 120,
      //     extraFreight: 0,
      //     id: 2987,
      //     imageId: null,
      //     imageUrl: null,
      //     isDisplay: "yes",
      //     optionName: "S8",
      //     pageCostPrice: 4,
      //     pageProfit: 1,
      //     pageSalesPrice: 5,
      //     productId: 582,
      //     profit: 40,
      //     salesPrice: 160,
      //     totalPageNum: 0,
      // }
    ] : val.productSpecificationsVos
    sessionStorage.setItem('product', JSON.stringify(val))
    setEditObj(val)
    if (val.source == 'self') { //自有产品
      // setVisible(true)

      if (val.typeId == 4) {
        history.push(`/store/newProductDownload/${pricesheetId}`)
      } else if (val.typeId == 5) {
        history.push(`/store/newProductPackage/${pricesheetId}`)
      } else {
        history.push(`/store/newProduct/${pricesheetId}`)
        // history.push(`/store/newProduct/${enterpriseId}`)
      }
    } else { //公用产品
      setVisible(true)
      setTimeout(()=>{
        if(div.current.offsetHeight>36){
          setProductDesc(true)
          setShowButton(true)
        }
      })
    }

  }
  //删除
  const deleteProduct = async () => {
    const res = await _deleteProduct({id: saveImg.id})
    if (res.status == '200' && res.data.code === '000000') {
      setDeleteVisible(false)
      getInfo()
      myMessage.success('删除成功')
    }
  }
  // 销售价格设置
  const setInputValue = (val, e, i, t, c, p) => {
    let arr = JSON.parse(JSON.stringify(editObj))
    if(/^[0-9]+\.?[0-9]{0,2}$/.test(e)) {
      arr.productSpecificationsVos[i][t] = e
      arr.productSpecificationsVos[i][p] = (e - arr.productSpecificationsVos[i][c]).toFixed(2)
    }else{
      arr.productSpecificationsVos[i][t] = ''
      arr.productSpecificationsVos[i][p] = 0
    }
    setEditObj(arr)
  }
  const priceBlur = (val, e, i, t, c, p) => {
    let arr = JSON.parse(JSON.stringify(editObj))
    let reg = pricePattern
    if (reg.test(e)) {
      arr.productSpecificationsVos[i][t] = e
      arr.productSpecificationsVos[i][p] = (e - arr.productSpecificationsVos[i][c]).toFixed(2)
    } else {
      arr.productSpecificationsVos[i][t] = ''
      arr.productSpecificationsVos[i][p] = 0
      myMessage.warning('请输入正确的金额，如10.00或10')
    }
    setEditObj(arr)
  }
  const setInputValuePrice = (e, i, t, c, p) => {
    let arr = JSON.parse(JSON.stringify(editObj))
    arr.price = e
    setEditObj(arr)
  }
  //添加
  const addForproduct = async (values) => {
    let arr = []
    values.map(v => {
      arr.push({
        projectId: v,
        priceId: pricesheet.id
      })
    })
    const res = await _editProjectsPriceId(arr)
    if (res.status === 200 && res.data.code === 0) {
      myMessage.success('应用成功')
      setAssignToProjectVisible(false)
    } else {
      myMessage.error(res.data.mesg)
    }
  }
  return (
    <Layout>
      <PageHeader
        ghost={false}
        className={styles.titleBar}
        title={
          <div id="productList">
            <Button
              icon={
                <span
                  className={`iconfont iconbtn_back`}
                ></span>
              }
              onClick={goBack}
              className={styles.backBtn}
            />
            {pricesheet.name}
          </div>
        }
        extra={[
          <div className={styles.newProductBtn}>
            <Dropdown
              overlay={
                <div className={styles.newProductMenu}>
                  <Menu>
                    <Menu.Item>
                      <Link
                        to={getFullRoute(PAGE_PATH.store.newProduct, {
                          pricesheetId: pricesheetId
                        })}
                      >
                        <a key='1' onClick={() => handleItem('自定义商品')}>自定义商品</a>
                      </Link>
                    </Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item>
                      <Link
                        to={getFullRoute(PAGE_PATH.store.newProductDownload, {
                          pricesheetId: pricesheetId
                        })}
                      >
                        <a key='2' onClick={() => handleItem('数字下载')}>数字下载</a>
                      </Link>
                    </Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item>
                      <Link
                        to={getFullRoute(PAGE_PATH.store.newProductPackage, {
                          pricesheetId: pricesheetId
                        })}
                      >
                        <a key='3' onClick={() => handleItem('组合套系')}>商品组合</a>
                      </Link>
                    </Menu.Item>
                  </Menu>
                </div>
              }
              placement="bottomRight"
              trigger={['click']}>
              <Button type="primary">添加商品</Button>
            </Dropdown>
          </div>
        ]}
      ></PageHeader>
      <Content className={styles.productsPage}>
        <Spin tip="数据加载中" spinning={loading}>
          <Row>
            {productList.length > 0 && <Col span={24} className={styles.controlBar}>
              <span className={styles.ptCount}>{pricesheet.productNum}个商品</span>
              <Divider type="vertical"/>
              {/* <Dropdown overlay={menu} trigger={['click']}>
                                <Button type="link" className="link-btn">
                                    显示{selectedClient.sourceName}
                                    <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
                                </Button>
                            </Dropdown>
                            <Divider type="vertical"/> */}
              <Button type="link" className="link-btn" onClick={() => {
                setAssignToProjectVisible(true)
              }}>
                应用到项目
              </Button>
              <Divider type="vertical"/>
              {pricesheet.isDefault == 'no' ? (
                <Button type="link" className="link-btn" onClick={setDefault}>
                  设为默认
                </Button>
              ) : (
                <span style={{color: "#9B9B9B"}}>已为默认价格表</span>
              )}
            </Col>
            }
            <Col span={24}>
              {productList.length > 0 && productList.map((product) => (
                <div className={styles.products}>
                  <div className={styles.productCategory}>{product.productTypeName}</div>
                  <div className={styles.list}>
                    {product.productBaseInfoList.map((img, i) => (
                      <div className={styles.item} key={i}>
                        <div className={styles.img} onMouseEnter={() => setEnter(img)}
                             onMouseLeave={() => setEnter({})}>
                          {img.typeId == 4 ? <img className={styles.imgF} src={downloadCover}/> :
                            img.imageUrls && img.imageUrls.length > 0 ? (
                              <img className={styles.imgF} src={img.imageUrls[0]}/>
                            ) : (
                              <span className={styles.imgBg}>
                                                          {/*{img.typeId!=4?(*/}
                                <span className={styles.imgF}></span>
                                {/*):(*/}
                                {/*  <span className="iconfont iconicon_pd_downloadt"></span>*/}
                                {/*)}*/}
                                                     </span>
                            )}
                          {img.source == 'self' && <span className={styles.selfFulfillment}>自有商品</span>}
                          {img.status != 'online' && <span className={styles.hidden}>已下线</span>}
                          {enter == img && <div className={styles.operation}>
                            <span onClick={() => clickImg(img, 'status')}>{img.status == 'online' ? '下架' : '上线'}</span>
                            {img.pdHidden == 'Y' && <Divider type="vertical"/>}<Divider type="vertical"/>
                            <span onClick={() => clickImg(img, 'edit')}>编辑</span>
                            {img.source != 'supplier' && <Divider type="vertical"/>}
                            {img.source != 'supplier' && <span onClick={() => clickImg(img, 'delete')}>删除</span>}
                          </div>}

                        </div>
                        <div className={styles.itemName}>{img.name}</div>
                        <div
                          className={styles.itemPrice}>￥{img.price}{img.typeId == 4 || img.typeId == 5 || !img.productOptionVos ? '' : '起'}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {productList.length == 0 && !loading && (
                <div>
                  <InfoEmptyData description="暂未创建商品"/>
                </div>
              )}
            </Col>
          </Row>
        </Spin>
      </Content>
      <Modal
        visible={visible}
        title={editObj.name}
        okText="确定"
        cancelText="取消"
        centered
        cancelButtonProps={{className: 'footerCanceltext'}}
        onCancel={() => {
          setVisible(false)
        }}
        maskClosable={false}
        width={800}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()
              addDeliver(values)
            })
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="add_project_form"
          requiredMark={false}
          className={styles.createProjectDialog}
          initialValues={{
            // presetId:'',
          }}
        >
          <div className={styles.overflow}>
            <div className={styles.picture}>
              <div>
                {editObj.imageUrls && editObj.imageUrls.map((val, i) => (
                  <img src={val} alt=""/>
                ))}
              </div>
              <div className={`${styles.productDesc} ${
                  productDesc ? styles.hide : ''
                }`} ref={div}>{editObj.describe}</div>
              {showButton&&
              <div className={styles.upDown} onClick={() => setProductDesc(!productDesc)}>{productDesc?'展开':'关闭'}</div>}
            </div>
            <div className={styles.tableContent}>
              <div className={styles.Ptitle}>
                <div className={styles.opt}>商品规格</div>
                <div className={styles.price}>成本</div>

                <div className={styles.addPagePrice}>销售定价</div>
                <div className={styles.shipping}>* 利润</div>
              </div>
              <div className={styles.tableContentBody}>
                {editObj.productSpecificationsVos && editObj.productSpecificationsVos.map((val, i) => (
                  <div className={styles.content}>
                    <div className={styles.opt}>
                      {val.optionName}
                    </div>
                    <div className={styles.price}>
                      ￥{val.costPrice}
                    </div>
                    <div
                      className={`${val.salesPrice==''?styles.inputred:''} ${styles.addPagePrice}`}
                      v-if="product.type=='album'">
                      <Input
                        type="text"
                        value={val.salesPrice}
                        onChange={e => {
                          setInputValue(val, e.target.value, i, 'salesPrice', 'costPrice', 'profit');
                        }}
                        onBlur={e => {
                        priceBlur(val, e.target.value, i, 'salesPrice', 'costPrice', 'profit');
                      }}
                      />
                    </div>
                    <div className={`${val.profit<0?styles.inputred:''} ${styles.shipping}`}>
                      {val.profit<0?'-':''}￥{val.profit<0?val.profit*-1:val.profit}
                    </div>
                    {editObj.typeId == 1 && editObj.pageControlType != 'fixedPage' && (
                      <div className={styles.contents}>
                        <div className={styles.addPages}>
                          每增加2页的价格
                        </div>
                        <div className={styles.price}>
                          ￥{val.pageCostPrice}
                        </div>
                        <div
                          className={`${val.pageSalesPrice==''?styles.inputred:''} ${styles.addPagePrice}`}
                          v-if="product.type=='album'">
                          <Input
                            type="text"
                            value={val.pageSalesPrice}
                            onChange={e => {
                              setInputValue(val, e.target.value, i, 'pageSalesPrice', 'pageCostPrice', 'pageProfit');
                            }}
                            onBlur={e => {
                              priceBlur(val, e.target.value, i, 'pageSalesPrice', 'pageCostPrice', 'pageProfit');
                            }}
                          />
                        </div>
                        <div className={`${val.pageProfit<0?styles.inputred:''} ${styles.shipping}`}>
                          {val.pageProfit<0?'-':''}￥{val.pageProfit<0?val.pageProfit*-1:val.pageProfit}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {editObj.productSpecificationsVos && editObj.productSpecificationsVos.length == 0 && (
                  <div className={styles.content}>
                    <div className={styles.opt}>
                      {editObj.name}
                    </div>
                    <div className={styles.price}>
                      ￥ {editObj.costPrice}
                    </div>
                    <div className={styles.addPagePrice} v-if="product.type=='album'">
                      <Input
                        type="text"
                        value={editObj.price}
                        onChange={e => {
                          setInputValuePrice(e.target.value, 'salesPrice', 'costPrice', 'profit');
                        }}
                      />
                    </div>
                    <div className={styles.shipping}>
                      ￥ {editObj.price - editObj.costPrice}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.tips}>* 利润=销售定价-成本-运费。此处利润值未去除运费，仅供参考，实际以结算结果为准。</div>
          </div>
        </Form>
      </Modal>
      <AssignToProject
        visible={assignToProjectVisible}
        onCreate={addForproduct}
        onCancel={() => {
          setAssignToProjectVisible(false)
        }}
        checkAssign={pricesheet}
      />
      <Modal
        title={`${saveImg.status == 'online' ? '下架商品' : '上线商品'}`}
        visible={onlineVisible}
        onOk={changeStatus}
        onCancel={() => setOnlineVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">确定下架并更新该商品状态？</div>
      </Modal>
      <Modal
        title="删除商品"
        visible={deleteVisible}
        onOk={deleteProduct}
        onCancel={() => setDeleteVisible(false)}
        okText="确认"
        cancelText="取消"
        width={600}
        closable={false}
        centered
        maskClosable={false}
        cancelButtonProps={{className: 'footerCanceltext'}}
      >
        <div className="Inf_page_title">删除商品将不可恢复，可能会影响已有关联数据，确定删除？</div>
      </Modal>
    </Layout>
  )
}
export default Products
