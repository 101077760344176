import Api from 'apis/Api.js'
import request from 'utils/Request'

// 添加订单
export const _addOrderAdd = async ( data ) => {
  return await request({
    method: 'post',
    url: Api.store.addOrder,
    data: data,
  })
}
export const _cancelOrder = async ( params ) => {
  return await request({
    method: 'get',
    url: Api.store.cancelOrder,
    params: params,
  })
}
export const _deleteOrder = async ( params ) => {
  return await request({
    method: 'get',
    url: Api.store.deleteOrder,
    params: params,
  })
}
export const _payOrder = async ( data ) => {
  return await request({
    method: 'post',
    url: Api.store.payOrder,
    data: data,
  })
}

// 新增发货
export const _deliverGoods = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.deliverGoods,
    data: data,
  })
}// 修改发货
export const _eiditDeliverGoods = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.eiditDeliverGoods,
    data: data,
  })
}

// 获取所有订单信息
export const _getOrderAll = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getOrderAll,
    data: data,
  })
}
export const _getOrderSelf = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getOrderSelf,
    data: data,
  })
}

// 通过id获取订单信息
export const _getOrderDetails = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.getOrderDetails,
    params: params,
  })
}
// 查询快递公司下拉枚举
export const _getLogistEnu = async (params) => {
  return await request({
    method: 'post',
    url: Api.store.logistEnu,
    params: params,
  })
}
// 通过id获取订单信息
export const _getOrderAddress = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.getOrderAddress+'/'+params.orderId,
    params: params,
  })
}
// 新增价格表
export const _addPricesheet = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addPricesheet,
    data: data,
  })
}
// 复制价格表
export const _copyPricesheet = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.copyPricesheet,
    data: data,
  })
}
// 删除价格表
export const _deletePricesheet = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.deletePricesheet,
    data: data,
  })
}
// 编辑价格表
export const _editPricesheet = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editPricesheet,
    data: data,
  })
}
// 获取价格表
export const _getPricesheet = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getPricesheet,
    data: data,
  })
}
// 获取价格表
export const _getPricesheetId = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.getPricesheetId+'/'+params.storeId,
    params: params,
  })
}
// 获取供应商表
export const _getEnterprise = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getEnterprise,
    data: data,
  })
}
// 获取价格表详情
export const _pricesheetInfo = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.pricesheetInfo,
    data: data,
  })
}
export const _productEditStatus = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.productEditStatus,
    params: params,
  })
}
export const _addProduct = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addProduct,
    data: data,
  })
}
export const _editProduct = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editProduct,
    data: data,
  })
}
export const _editPrice = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editPrice,
    data: data,
  })
}
export const _addDigital = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addDigital,
    data: data,
  })
}
export const _editDigital = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editDigital,
    data: data,
  })
}
export const _addPackage = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addPackage,
    data: data,
  })
}
export const _editPackage = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editPackage,
    data: data,
  })
}
export const _getSingle = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getSingle,
    data: data,
  })
}
export const _getPricename = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getPricename,
    data: data,
  })
}
export const _setDefault = async (data) => {
  return await request({
    method: 'post',
    url: `${Api.store.setDefault}/${data.id}/${data.storeId}`,
    data: data,
  })
}
export const _deleteProduct = async (data) => {
  return await request({
    method: 'delete',
    url: Api.store.deleteProduct+'?id='+data.id,
    data: data,
  })
}
// 新增优惠券
export const _addCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addCoupon,
    data: data,
  })
}
// 删除优惠券
export const _deleteCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.deleteCoupon+'/'+data.id,
    data: data,
  })
}
export const _endCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.endCoupon+'/'+data.id,
    data: data,
  })
}
// 编辑优惠券
export const _editCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editCoupon,
    data: data,
  })
}
// 获取优惠券
export const _getCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getCoupon,
    data: data,
  })
}
// 优惠券标记为推广
export const _pushCoupon = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.pushCoupon,
    data: data,
  })
}
// 获取产品类型
export const _productType = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.productType+'?storeId='+data.storeId,
    data: data,
  })
}
// 文件上传
export const _uploadImg = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.uploadImg,
    data: data,
  })
}
export const _addPolicy = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addPolicy,
    data: data,
  })
}
export const _editPolicy = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editPolicy,
    data: data,
  })
}
export const _getPolicy = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getPolicy,
    data: data,
  })
}
export const _getPolicyDetail = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.getPolicyDetail,
    params: params,
  })
}
export const _deletePolicy = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.deletePolicy+'/'+params.id,
    params: params,
  })
}
export const _addFreight = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addFreight,
    data: data,
  })
}
export const _deleteFreight = async (params) => {
  return await request({
    method: 'delete',
    url: Api.store.deleteFreight,
    params: params,
  })
}
export const _editFreight = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editFreight,
    data: data,
  })
}
export const _getFreight = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getFreight,
    data: data,
  })
}

export const _addProductType = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addProductType,
    data: data,
  })
}

export const _deleteProductType = async (params) => {
  return await request({
    method: 'delete',
    url: Api.store.deleteProductType,
    params: params,
  })
}

export const _editProductType = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editProductType,
    data: data,
  })
}

export const _getProductType = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getProductType,
    data: data,
  })
}
export const _getEnterpriseLevel = async (data) => {
  return await request({
    method: 'get',
    url: Api.store.getEnterpriseLevel,
    data: data,
  })
}
export const _getAdsList = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.getAdsList,
    data: data,
  })
}
export const _addNewExport = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addNewExport,
    data: data,
  })
}

export const _addAddress = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.addAddress,
    data: data,
  })
}
export const _delelteAddress = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.delelteAddress+'/'+params.id,
    params: params,
  })
}
export const _editAddress = async (data) => {
  return await request({
    method: 'post',
    url: Api.store.editAddress,
    data: data,
  })
}
export const _getAddress = async (params) => {
  return await request({
    method: 'get',
    url: Api.store.getAddress,
    params: params,
  })
}
